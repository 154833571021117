import { call, put, takeLatest } from 'redux-saga/effects'

import { NOTIFICATION_TYPE } from '~/common/constants'
import {
  createRouteManagementFailed,
  createRouteManagementSuccess,
  CREATE_ROUTE_MANAGEMENT_START,
} from '~/modules/wmsx/redux/actions/route-management'
import { api } from '~/services/api'
import addNotification from '~/utils/toast'

const createRouteManagementApi = (params) => {
  const uri = `/lines`
  return api.post(uri, params)
}

function* doCreateRouteManagement(action) {
  try {
    const response = yield call(createRouteManagementApi, action?.payload)

    if (response?.statusCode === 200) {
      yield put(createRouteManagementSuccess(response.data))

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess()
      }

      addNotification(
        response?.message || response?.payload?.message,
        NOTIFICATION_TYPE.SUCCESS,
      )
    } else {
      addNotification(
        response?.message || response?.payload?.message,
        NOTIFICATION_TYPE.ERROR,
      )
      throw new Error(response?.message || response?.payload?.message)
    }
  } catch (error) {
    yield put(createRouteManagementFailed())
    // Call callback action if provided
    if (action.onError) {
      yield action.onError()
    }
  }
}

export default function* watchCreateRouteManagement() {
  yield takeLatest(CREATE_ROUTE_MANAGEMENT_START, doCreateRouteManagement)
}
