const style = (theme) => ({
  paper: {
    padding: theme.spacing(2.5, 4, 4),
  },

  extraBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: theme.spacing(1, 0),
  },

  linkForgotPassword: {
    textDecoration: 'none',
  },

  logoLogin: {
    width: '100px',
    maxWidth: '100px',
  },
})

export default style
