export const LICENSE_ITEM = {
  WMS: 0,
}

export const LICENSE_ITEM_MAP = {
  [LICENSE_ITEM.WMS]: 'licenseActivation.wms',
}

export const LICENSE_ITEM_OPTIONS = [
  {
    id: 0,
    text: 'licenseActivation.wms',
  },
]
