import React, { useState, useEffect } from 'react'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { startOfDay, endOfDay } from 'date-fns'

import { useQueryState } from '~/common/hooks'
import Button from '~/components/Button'
import DataTable from '~/components/DataTable'
import Dialog from '~/components/Dialog'
import Icon from '~/components/Icon'
import IconButton from '~/components/IconButton'
import NumberFormatText from '~/components/NumberFormat'
import Page from '~/components/Page'
import Status from '~/components/Status'
import {
  ROLE,
  WAREHOUSE_IMPORT_RECEIPT_OPTIONS,
  WAREHOUSE_IMPORT_RECEIPT_STATUS,
} from '~/modules/wmsx/constants'
import useWarehouseImportReceipt from '~/modules/wmsx/redux/hooks/useWarehouseImportReceipt'
import { ROUTE } from '~/modules/wmsx/routes/config'
import {
  convertSortParams,
  convertUtcDateToLocalTz,
  getLocalItem,
} from '~/utils'

import FilterForm from './filter-form'
import { useWindowDimensions } from '../../get-size-sreen'
import { exportMonitorOrders } from '~/modules/wmsx/redux/sagas/warehouse-import-receipt/search'

const breadcrumbs = [
  {
    route: ROUTE.WAREHOUSE_IMPORT_RECEIPT.LIST.PATH,
    title: ROUTE.WAREHOUSE_IMPORT_RECEIPT.LIST.TITLE,
  },
]

function WarehouseImportReceipt() {
  const { t } = useTranslation('wmsx')
  const history = useHistory()
  const DEFAULT_FILTERS = {
    code: '',
    name: '',
  }
  const userInfo = getLocalItem('userInfo')
  const {
    page,
    pageSize,
    sort,
    filters,
    keyword,
    setPage,
    setPageSize,
    setSort,
    setFilters,
    setKeyword,
  } = useQueryState({
    filters: DEFAULT_FILTERS,
  })
  const {
    data: { warehouseImportReceiptList, total, isLoading },
    actions,
  } = useWarehouseImportReceipt()

  const [modal, setModal] = useState({
    tempItem: null,
    isOpenDeleteModal: false,
    isOpenConfirmModal: false,
    isOpenSeenModal: false,
    isOpenRetryModal: false,
  })
  const sizeScreen = useWindowDimensions()
  // const [selectedRows, setSelectedRows] = useState([])
  const [loadMore, setLoadMore] = useState(false)
  const [loadingExport, setLoadingExport] = useState(false)
  const [dataOderMobile, setDataOderMobile] = useState([])
  const columns = [
    {
      field: 'code',
      headerName: 'Mã phiếu',
      width: 50,
      sortable: false,
      visible: 'always',
      renderCell: (params) => {
        return params?.row?.id
      },
    },
    {
      field: 'createdAt',
      headerName: 'Ngày tháng',
      width: 100,
      filterFormat: 'date',
      sortable: false,
      renderCell: (params) => {
        return convertUtcDateToLocalTz(params?.row?.date)
      },
    },
    {
      field: 'contractId',
      headerName: 'Hợp đồng',
      width: 150,
      sortable: false,
      renderCell: (params) => {
        return params?.row?.contract?.name
      },
    },
    {
      field: 'createdByUser',
      headerName: 'Người tạo',
      width: 150,
      sortable: false,
      renderCell: (params) => {
        return params?.row?.createdByUser?.fullName
      },
    },

    {
      field: 'vehicleNumber',
      headerName: 'Số xe',
      width: 150,
      sortable: false,
      renderCell: (params) => {
        return params?.row?.vehicleNumber
      },
    },
    // {
    //   field: 'vehicleType',
    //   headerName: 'Loại xe',
    //   width: 150,
    //   sortable: false,
    //   renderCell: (params) => {
    //     return params?.row?.vehicleType
    //   },
    // },
    {
      field: 'status',
      headerName: 'Trạng thái',
      sortable: false,
      width: 100,
      renderCell: (params) => {
        const status = Number(params?.row.status)
        return (
          <Status
            options={WAREHOUSE_IMPORT_RECEIPT_OPTIONS}
            value={status}
            variant="text"
          />
        )
      },
    },
    {
      field: 'action',
      headerName: t('general:common.action'),
      width: 200,
      align: 'center',
      visible: 'always',
      sticky: 'right',
      renderCell: (params) => {
        const { id, status } = params?.row
        const isEdit =
          status === WAREHOUSE_IMPORT_RECEIPT_STATUS.PENDING &&
          (userInfo?.role === ROLE.ADMIN || userInfo?.role === ROLE.USER)

        const isDelete =
          status === WAREHOUSE_IMPORT_RECEIPT_STATUS.PENDING &&
          (userInfo?.role === ROLE.ADMIN || userInfo?.role === ROLE.USER)
        const isConfirmed =
          status === WAREHOUSE_IMPORT_RECEIPT_STATUS.PENDING &&
          (userInfo?.role === ROLE.ADMIN || userInfo?.role === ROLE.USER)
        const isSeenDriver =
          status === WAREHOUSE_IMPORT_RECEIPT_STATUS.CONFIRMED &&
          (userInfo?.role === ROLE.ADMIN || userInfo?.role === ROLE.USER)
        const isRetry =
          status === WAREHOUSE_IMPORT_RECEIPT_STATUS.SIGNED &&
          (userInfo?.role === ROLE.ADMIN || userInfo?.role === ROLE.USER)
        return (
          <div>
            <IconButton
              title={t('iconButtonHover.view')}
              onClick={() =>
                history.push(
                  ROUTE.WAREHOUSE_IMPORT_RECEIPT.DETAIL.PATH.replace(
                    ':id',
                    `${id}`,
                  ),
                )
              }
            >
              <Icon name={'show'} />
            </IconButton>
            {isEdit && (
              <IconButton
                title={t('iconButtonHover.update')}
                onClick={() =>
                  history.push(
                    ROUTE.WAREHOUSE_IMPORT_RECEIPT.EDIT.PATH.replace(
                      ':id',
                      `${id}`,
                    ),
                  )
                }
              >
                <Icon name="edit" />
              </IconButton>
            )}
            {isDelete && (
              <IconButton
                title={t('iconButtonHover.delete')}
                onClick={() => onClickDelete(params.row)}
              >
                <Icon name="delete" />
              </IconButton>
            )}
            {isConfirmed && (
              <IconButton
                title={t('iconButtonHover.confirm')}
                onClick={() => onClickConfirm(params.row)}
              >
                <Icon name="tick" />
              </IconButton>
            )}
            {isSeenDriver && (
              <IconButton
                title="Gửi cho lái xe"
                onClick={() => onClickSeenDriver(params.row)}
              >
                <Icon name="assign" />
              </IconButton>
            )}
            {isRetry && (
              <IconButton
                title="Yêu cầu ký lại"
                onClick={() => onClickRetry(params.row)}
              >
                <Icon name="retrySyncReceipt" />
              </IconButton>
            )}
          </div>
        )
      },
    },
  ]
  const refreshData = () => {
    const params = {
      keyword: keyword.trim(),
      page,
      limit: sizeScreen?.width <= 500 ? 10 : pageSize,
      createdByUserId: filters?.createdByUser?.id || null,
      status: filters?.status || null,
      employeeId: filters?.employeeId?.id || null,
      dateFrom: !isEmpty(filters?.date) ? startOfDay(filters?.date?.[0]) : null,
      dateTo: !isEmpty(filters?.date) ? endOfDay(filters?.date?.[1]) : null,
      sort: convertSortParams(sort),
      contractId: filters?.contractId?.id,
    }
    actions.searchWarehouseImportReceipt(params, (data) => {
      if (sizeScreen?.width <= 500 && !isEmpty(data)) {
        setDataOderMobile([...dataOderMobile, ...data?.list])
      }
      setLoadMore(false)
    })
  }

  useEffect(() => {
    refreshData()
  }, [page, pageSize, filters, sort, keyword])

  // useEffect(() => {
  //   setSelectedRows([])
  // }, [keyword, sort, filters])

  const onClickDelete = (tempItem) => {
    setModal({ tempItem, isOpenDeleteModal: true })
  }
  const onClickConfirm = (tempItem) => {
    setModal({ tempItem, isOpenConfirmModal: true })
  }
  const onClickSeenDriver = (tempItem) => {
    setModal({ tempItem, isOpenSeenModal: true })
  }
  const onClickRetry = (tempItem) => {
    setModal({ tempItem, isOpenRetryModal: true })
  }
  const onSubmitDelete = () => {
    actions.deleteWarehouseImportReceipt(modal.tempItem?.id, () => {
      refreshData()
    })
    setModal({ isOpenDeleteModal: false, tempItem: null })
  }
  const onSubmitConfirm = () => {
    actions.confirmWarehouseImportReceiptById(modal.tempItem?.id, () => {
      refreshData()
    })
    setModal({ isOpenConfirmModal: false, tempItem: null })
  }
  const onSubmitSeen = () => {
    actions.seenToDriver(modal.tempItem?.id, () => {
      refreshData()
    })
    setModal({ isOpenSeenModal: false, tempItem: null })
  }
  const onSubmitRetry = () => {
    actions.retry(modal.tempItem?.id, () => {
      refreshData()
    })
    setModal({ isOpenConfirmModal: false, tempItem: null })
  }
  const onCloseDeleteModal = () => {
    setModal({
      isOpenDeleteModal: false,
      tempItem: null,
      isOpenConfirmModal: false,
      isOpenSeenModal: false,
      isOpenRetryModal: false,
    })
  }

  const renderHeaderRight = () => {
    return (
      <>
        {(userInfo?.role === ROLE.ADMIN || userInfo?.role === ROLE.USER) && (
          <>
            <Button
              onClick={async () => {
                const params = {
                  keyword: keyword.trim(),
                  page,
                  createdByUserId: filters?.createdByUser?.id || null,
                  status: filters?.status || null,
                  employeeId: filters?.employeeId?.id || null,
                  dateFrom: !isEmpty(filters?.date)
                    ? startOfDay(filters?.date?.[0])
                    : null,
                  dateTo: !isEmpty(filters?.date)
                    ? endOfDay(filters?.date?.[1])
                    : null,
                  sort: convertSortParams(sort),
                  contractId: filters?.contractId?.id,
                }
                setLoadingExport(true)
                await exportMonitorOrders(params)
                setLoadingExport(false)
              }}
              sx={{ ml: 4 / 3 }}
              icon="export"
            >
              {'Xuất dữ liệu'}
            </Button>
            <Button
              onClick={() =>
                history.push(ROUTE.WAREHOUSE_IMPORT_RECEIPT.CREATE.PATH)
              }
              sx={{ ml: 4 / 3 }}
              icon="add"
            >
              {t('general:common.create')}
            </Button>
          </>
        )}
      </>
    )
  }
  const handleClick = () => {
    setPage(page + 1)
    setLoadMore(true)
  }
  return (
    <Page
      breadcrumbs={
        (userInfo?.role === ROLE.ADMIN || userInfo?.role === ROLE.USER) &&
        breadcrumbs
      }
      title={
        // (userInfo?.role === ROLE.ADMIN || userInfo?.role === ROLE.USER) &&
        'Phiếu giám sát xe'
      }
      onSearch={setKeyword}
      placeholder={t('warehouseImportReceipt.searchPlaceholder')}
      renderHeaderRight={renderHeaderRight}
      loading={isLoading || loadMore || loadingExport}
    >
      {userInfo?.role === ROLE.DRIVER && sizeScreen?.width <= 500 ? (
        <>
          {dataOderMobile?.map((item) => (
            <Card
              sx={{
                maxWidth: 500,
                border: 1,
                mt: 1,
                borderRadius: 5,
                boxShadow: ' 6px 6px #d3d7db',
              }}
              onClick={() =>
                history.push(
                  ROUTE.WAREHOUSE_IMPORT_RECEIPT.DETAIL.PATH.replace(
                    ':id',
                    `${item?.id}`,
                  ),
                )
              }
            >
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Mã phiếu: {item?.id}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Lái xe: {item?.employee?.fullName}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Người tạo:
                  {item?.createdByUser?.fullName}, Ngày tháng:{' '}
                </Typography>{' '}
                <Typography variant="body2" color="text.secondary">
                  Ngày tháng: {convertUtcDateToLocalTz(item?.date)}
                </Typography>{' '}
                <Typography variant="body2" color="text.secondary">
                  Số xe: {item?.vehicleNumber}
                </Typography>{' '}
                <Typography variant="body2" color="text.secondary">
                  Loại Xe: {item?.vehicleType}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Số Km: {<NumberFormatText value={item?.km} />}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Số tiền: {<NumberFormatText value={item?.money} />} VNĐ
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Tuyến đường khác: {item?.otherRoute}
                </Typography>
              </CardContent>
              {/* <CardActions>
              <Button size="small">xem</Button>
              <Button size="small">Learn More</Button>
            </CardActions> */}
            </Card>
          ))}
          <Button onClick={() => handleClick()} sx={{ mt: 1, borderRadius: 3 }}>
            Xem thêm
          </Button>
        </>
      ) : (
        <>
          <DataTable
            title="Danh sách phiếu giám sát xe"
            rows={warehouseImportReceiptList}
            pageSize={pageSize}
            page={page}
            columns={columns}
            onPageChange={setPage}
            onPageSizeChange={setPageSize}
            onSortChange={setSort}
            enableResizable={false}
            // onSelectionChange={setSelectedRows}
            // selected={selectedRows}
            total={total}
            sort={sort}
            filters={{
              form: <FilterForm />,
              values: filters,
              defaultValue: DEFAULT_FILTERS,
              onApply: setFilters,
            }}
          />
          <Dialog
            open={modal.isOpenDeleteModal}
            title={t('warehouseImportReceipt.deleteTitlePopup')}
            onCancel={onCloseDeleteModal}
            cancelLabel={t('general:common.no')}
            onSubmit={onSubmitDelete}
            submitLabel={t('general:common.yes')}
            submitProps={{
              color: 'error',
            }}
            noBorderBottom
          >
            {t('warehouseImportReceipt.deleteConfirm')}
          </Dialog>
          <Dialog
            open={modal.isOpenConfirmModal}
            title={t('warehouseImportReceipt.confirmTitlePopup')}
            onCancel={onCloseDeleteModal}
            cancelLabel={t('general:common.no')}
            onSubmit={onSubmitConfirm}
            submitLabel={t('general:common.yes')}
            noBorderBottom
          >
            {t('warehouseImportReceipt.Confirm')}
          </Dialog>
          <Dialog
            open={modal.isOpenSeenModal}
            title="Gửi phiếu giám sát sử dụng cho lái xe"
            onCancel={onCloseDeleteModal}
            cancelLabel={t('general:common.no')}
            onSubmit={onSubmitSeen}
            submitLabel={t('general:common.yes')}
            noBorderBottom
          >
            Bạn có chắc chắn muốn gửi không?
          </Dialog>
          <Dialog
            open={modal.isOpenRetryModal}
            title="Gửi yêu cầu ký tên lại cho lái xe"
            onCancel={onCloseDeleteModal}
            cancelLabel={t('general:common.no')}
            onSubmit={onSubmitRetry}
            submitLabel={t('general:common.yes')}
            noBorderBottom
          >
            Bạn có chắc chắn muốn gửi yêu cầu không?
          </Dialog>
        </>
      )}
    </Page>
  )
}

export default WarehouseImportReceipt
