import { useEffect, useMemo } from 'react'

import { Grid, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'

import { MODAL_MODE, TEXTFIELD_REQUIRED_LENGTH } from '~/common/constants'
import ActionBar from '~/components/ActionBar'
import { Field } from '~/components/Formik'
import LV from '~/components/LabelValue'
import Page from '~/components/Page'
import Status from '~/components/Status'
import { ROUTE_MANAGEMENT_OPTIONS } from '~/modules/wmsx/constants'
import useRouteManagement from '~/modules/wmsx/redux/hooks/useRouteManagement'
import { ROUTE } from '~/modules/wmsx/routes/config'

import { validationSchema } from './schema'
function RouteManagementForm() {
  const { t } = useTranslation(['wmsx'])
  const history = useHistory()
  const { id } = useParams()
  const routeMatch = useRouteMatch()
  const MODE_MAP = {
    [ROUTE.ROUTE_MANAGEMENT.CREATE.PATH]: MODAL_MODE.CREATE,
    [ROUTE.ROUTE_MANAGEMENT.EDIT.PATH]: MODAL_MODE.UPDATE,
  }
  const {
    data: { routeManagementDetails, isLoading },
    actions,
  } = useRouteManagement()
  useEffect(() => {
    if (isUpdate) {
      actions.getRouteManagementDetailsById(id)
    }
    return () => {
      actions.resetStateRouteManagement()
    }
  }, [id])
  const mode = MODE_MAP[routeMatch.path]
  const isUpdate = mode === MODAL_MODE.UPDATE
  const getBreadcrumb = () => {
    const breadcrumb = [
      {
        route: ROUTE.ROUTE_MANAGEMENT.LIST.PATH,
        title: ROUTE.ROUTE_MANAGEMENT.LIST.TITLE,
      },
    ]
    switch (mode) {
      case MODAL_MODE.CREATE:
        breadcrumb.push({
          route: ROUTE.ROUTE_MANAGEMENT.CREATE.PATH,
          title: ROUTE.ROUTE_MANAGEMENT.CREATE.TITLE,
        })
        break
      case MODAL_MODE.UPDATE:
        breadcrumb.push({
          route: ROUTE.ROUTE_MANAGEMENT.EDIT.PATH,
          title: ROUTE.ROUTE_MANAGEMENT.EDIT.TITLE,
        })
        break
      default:
        break
    }
    return breadcrumb
  }

  const renderActionBar = (handleReset) => {
    switch (mode) {
      case MODAL_MODE.CREATE:
        return (
          <ActionBar
            onBack={backToList}
            onCancel={handleReset}
            mode={MODAL_MODE.CREATE}
          />
        )
      case MODAL_MODE.UPDATE:
        return (
          <ActionBar
            onBack={backToList}
            onCancel={handleReset}
            mode={MODAL_MODE.UPDATE}
          />
        )
      default:
        break
    }
  }
  const initialValues = useMemo(
    () => ({
      fromVI: routeManagementDetails?.fromVI || '',
      toVI: routeManagementDetails?.toVI || '',
      fromCN: routeManagementDetails?.fromCN || '',
      toCN: routeManagementDetails?.toCN || '',
    }),
    [routeManagementDetails],
  )
  const onSubmit = (values) => {
    const params = {
      fromVI: values?.fromVI || null,
      toVI: values?.toVI || null,
      fromCN: values?.fromCN || null,
      toCN: values?.toCN || null,
    }
    if (mode === MODAL_MODE.CREATE) {
      actions.createRouteManagement(params, backToList)
    } else {
      actions.updateRouteManagement({ ...params, id: id }, backToList)
    }
  }
  const getTitle = () => {
    switch (mode) {
      case MODAL_MODE.CREATE:
        return ROUTE.ROUTE_MANAGEMENT.CREATE.TITLE
      case MODAL_MODE.UPDATE:
        return ROUTE.ROUTE_MANAGEMENT.EDIT.TITLE
      default:
        break
    }
  }

  const backToList = () => {
    history.push(ROUTE.ROUTE_MANAGEMENT.LIST.PATH)
  }
  return (
    <Page
      breadcrumbs={getBreadcrumb()}
      title={getTitle()}
      onBack={backToList}
      loading={isLoading}
    >
      <Grid container justifyContent="center">
        <Grid item xl={11} xs={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema(t, mode)}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ handleReset }) => {
              return (
                <Form>
                  <Grid
                    container
                    rowSpacing={4 / 3}
                    columnSpacing={{ xl: 8, xs: 4 }}
                  >
                    {isUpdate && (
                      <Grid item xs={12}>
                        <LV
                          label={<Typography>Trạng thái</Typography>}
                          value={
                            <Status
                              options={ROUTE_MANAGEMENT_OPTIONS}
                              value={routeManagementDetails?.status}
                            />
                          }
                        />
                      </Grid>
                    )}
                    <Grid item lg={6} xs={12}>
                      <Field.TextField
                        label="Từ"
                        name="fromVI"
                        placeholder="Từ"
                        inputProps={{
                          maxLength: TEXTFIELD_REQUIRED_LENGTH.CODE_50.MAX,
                        }}
                        required
                      />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <Field.TextField
                        label="Đến"
                        name="toVI"
                        placeholder="Đến"
                        inputProps={{
                          maxLength: TEXTFIELD_REQUIRED_LENGTH.CODE_50.MAX,
                        }}
                        required
                      />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <Field.TextField
                        label="Từ (從)"
                        name="fromCN"
                        placeholder="Từ (從)"
                        inputProps={{
                          maxLength: TEXTFIELD_REQUIRED_LENGTH.CODE_50.MAX,
                        }}
                        required
                      />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <Field.TextField
                        label="Đến (到達)"
                        name="toCN"
                        placeholder="Đến (到達)"
                        inputProps={{
                          maxLength: TEXTFIELD_REQUIRED_LENGTH.CODE_50.MAX,
                        }}
                        required
                      />
                    </Grid>
                  </Grid>
                  {renderActionBar(handleReset)}
                </Form>
              )
            }}
          </Formik>
        </Grid>
      </Grid>
    </Page>
  )
}
export default RouteManagementForm
