export const SEARCH_GROUP_MONITOR_ORDER_START =
  'WMSX_SEARCH_GROUP_MONITOR_ORDER_START'
export const SEARCH_GROUP_MONITOR_ORDER_SUCCESS =
  'WMSX_SEARCH_GROUP_MONITOR_ORDER_SUCCESS'
export const SEARCH_GROUP_MONITOR_ORDER_FAILED =
  'WMSX_SEARCH_GROUP_MONITOR_ORDER_FAILED'

export const CREATE_GROUP_MONITOR_ORDER_START =
  'WMSX_CREATE_GROUP_MONITOR_ORDER_START'
export const CREATE_GROUP_MONITOR_ORDER_SUCCESS =
  'WMSX_CREATE_GROUP_MONITOR_ORDER_SUCCESS'
export const CREATE_GROUP_MONITOR_ORDER_FAILED =
  'WMSX_CREATE_GROUP_MONITOR_ORDER_FAILED'

export const UPDATE_GROUP_MONITOR_ORDER_START =
  'WMSX_UPDATE_GROUP_MONITOR_ORDER_START'
export const UPDATE_GROUP_MONITOR_ORDER_SUCCESS =
  'WMSX_UPDATE_GROUP_MONITOR_ORDER_SUCCESS'
export const UPDATE_GROUP_MONITOR_ORDER_FAILED =
  'WMSX_UPDATE_GROUP_MONITOR_ORDER_FAILED'

export const DELETE_GROUP_MONITOR_ORDER_START =
  'WMSX_DELETE_GROUP_MONITOR_ORDER_START'
export const DELETE_GROUP_MONITOR_ORDER_SUCCESS =
  'WMSX_DELETE_GROUP_MONITOR_ORDER_SUCCESS'
export const DELETE_GROUP_MONITOR_ORDER_FAILED =
  'WMSX_DELETE_GROUP_MONITOR_ORDER_FAILED'

export const GET_GROUP_MONITOR_ORDER_DETAILS_START =
  'WMSX_GET_GROUP_MONITOR_ORDER_DETAILS_START'
export const GET_GROUP_MONITOR_ORDER_DETAILS_SUCCESS =
  'WMSX_GET_GROUP_MONITOR_ORDER_DETAILS_SUCCESS'
export const GET_GROUP_MONITOR_ORDER_DETAILS_FAILED =
  'WMSX_GET_GROUP_MONITOR_ORDER_DETAILS_FAILED'

export const CONFIRM_GROUP_MONITOR_ORDER_START =
  'WMSX_CONFIRM_GROUP_MONITOR_ORDER_START'
export const CONFIRM_GROUP_MONITOR_ORDER_SUCCESS =
  'WMSX_CONFIRM_GROUP_MONITOR_ORDER_SUCCESS'
export const CONFIRM_GROUP_MONITOR_ORDER_FAILED =
  'WMSX_CONFIRM_GROUP_MONITOR_ORDER_FAILED'

export const REJECT_GROUP_MONITOR_ORDER_START =
  'WMSX_REJECT_GROUP_MONITOR_ORDER_START'
export const REJECT_GROUP_MONITOR_ORDER_SUCCESS =
  'WMSX_REJECT_GROUP_MONITOR_ORDER_SUCCESS'
export const REJECT_GROUP_MONITOR_ORDER_FAILED =
  'WMSX_REJECT_GROUP_MONITOR_ORDER_FAILED'

export const RESET_GROUP_MONITOR_ORDER_DETAILS_STATE =
  'WMSX_RESET_GROUP_MONITOR_ORDER_DETAILS_STATE'

export const TO_SIGN_GROUP_MONITOR_ORDER_START =
  'WMSX_TO_SIGN_GROUP_MONITOR_ORDER_START'
export const TO_SIGN_GROUP_MONITOR_ORDER_SUCCESS =
  'WMSX_TO_SIGN_GROUP_MONITOR_ORDER_SUCCESS'
export const TO_SIGN_GROUP_MONITOR_ORDER_FAILED =
  'WMSX_TO_SIGN_GROUP_MONITOR_ORDER_FAILED'

export function searchGroupMonitorOrder(payload, onSuccess, onError) {
  return {
    type: SEARCH_GROUP_MONITOR_ORDER_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function searchGroupMonitorOrderSuccess(payload) {
  return {
    type: SEARCH_GROUP_MONITOR_ORDER_SUCCESS,
    payload: payload,
  }
}

export function searchGroupMonitorOrderFailed() {
  return {
    type: SEARCH_GROUP_MONITOR_ORDER_FAILED,
  }
}

export function createGroupMonitorOrder(payload, onSuccess, onError) {
  return {
    type: CREATE_GROUP_MONITOR_ORDER_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function createGroupMonitorOrderSuccess(payload) {
  return {
    type: CREATE_GROUP_MONITOR_ORDER_SUCCESS,
    payload: payload,
  }
}

export function createGroupMonitorOrderFailed() {
  return {
    type: CREATE_GROUP_MONITOR_ORDER_FAILED,
  }
}

export function updateGroupMonitorOrder(payload, onSuccess, onError) {
  return {
    type: UPDATE_GROUP_MONITOR_ORDER_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function updateGroupMonitorOrderSuccess(payload) {
  return {
    type: UPDATE_GROUP_MONITOR_ORDER_SUCCESS,
    payload: payload,
  }
}

export function updateGroupMonitorOrderFailed() {
  return {
    type: UPDATE_GROUP_MONITOR_ORDER_FAILED,
  }
}

export function deleteGroupMonitorOrder(packageId, onSuccess, onError) {
  return {
    type: DELETE_GROUP_MONITOR_ORDER_START,
    payload: packageId,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function deleteGroupMonitorOrderSuccess(payload) {
  return {
    type: DELETE_GROUP_MONITOR_ORDER_SUCCESS,
    payload: payload,
  }
}

export function deleteGroupMonitorOrderFailed() {
  return {
    type: DELETE_GROUP_MONITOR_ORDER_FAILED,
  }
}

export function getGroupMonitorOrderDetailsById(packageId, onSuccess, onError) {
  return {
    type: GET_GROUP_MONITOR_ORDER_DETAILS_START,
    payload: packageId,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function getGroupMonitorOrderDetailsByIdSuccess(payload) {
  return {
    type: GET_GROUP_MONITOR_ORDER_DETAILS_SUCCESS,
    payload: payload,
  }
}

export function getGroupMonitorOrderDetailsByIdFailed() {
  return {
    type: GET_GROUP_MONITOR_ORDER_DETAILS_FAILED,
  }
}

export function confirmGroupMonitorOrderById(Id, onSuccess, onError) {
  return {
    type: CONFIRM_GROUP_MONITOR_ORDER_START,
    payload: Id,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function confirmGroupMonitorOrderByIdSuccess(payload) {
  return {
    type: CONFIRM_GROUP_MONITOR_ORDER_SUCCESS,
    payload: payload,
  }
}

export function confirmGroupMonitorOrderByIdFailed() {
  return {
    type: CONFIRM_GROUP_MONITOR_ORDER_FAILED,
  }
}

export function toSignGroupMonitorOrderById(Id, onSuccess, onError) {
  return {
    type: TO_SIGN_GROUP_MONITOR_ORDER_START,
    payload: Id,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function toSignGroupMonitorOrderByIdSuccess(payload) {
  return {
    type: TO_SIGN_GROUP_MONITOR_ORDER_SUCCESS,
    payload: payload,
  }
}

export function toSignGroupMonitorOrderByIdFailed() {
  return {
    type: TO_SIGN_GROUP_MONITOR_ORDER_FAILED,
  }
}
export function rejectGroupMonitorOrderById(Id, onSuccess, onError) {
  return {
    type: REJECT_GROUP_MONITOR_ORDER_START,
    payload: Id,
    onSuccess: onSuccess,
    onError: onError,
  }
}
export function rejectWarehouseImportEBSById(Id, onSuccess, onError) {
  return {
    type: REJECT_GROUP_MONITOR_ORDER_START,
    payload: Id,
    onSuccess: onSuccess,
    onError: onError,
  }
}
export function rejectGroupMonitorOrderByIdSuccess(payload) {
  return {
    type: REJECT_GROUP_MONITOR_ORDER_SUCCESS,
    payload: payload,
  }
}

export function rejectGroupMonitorOrderByIdFailed() {
  return {
    type: REJECT_GROUP_MONITOR_ORDER_FAILED,
  }
}

export function resetGroupMonitorOrderState() {
  return {
    type: RESET_GROUP_MONITOR_ORDER_DETAILS_STATE,
  }
}

export default {
  searchGroupMonitorOrder,
  searchGroupMonitorOrderSuccess,
  searchGroupMonitorOrderFailed,
  createGroupMonitorOrder,
  createGroupMonitorOrderSuccess,
  createGroupMonitorOrderFailed,
  updateGroupMonitorOrder,
  updateGroupMonitorOrderSuccess,
  updateGroupMonitorOrderFailed,
  deleteGroupMonitorOrder,
  deleteGroupMonitorOrderSuccess,
  deleteGroupMonitorOrderFailed,
  getGroupMonitorOrderDetailsById,
  getGroupMonitorOrderDetailsByIdSuccess,
  getGroupMonitorOrderDetailsByIdFailed,
  confirmGroupMonitorOrderById,
  confirmGroupMonitorOrderByIdSuccess,
  confirmGroupMonitorOrderByIdFailed,
  resetGroupMonitorOrderState,
  toSignGroupMonitorOrderById,
  toSignGroupMonitorOrderByIdSuccess,
  toSignGroupMonitorOrderByIdFailed,
}
