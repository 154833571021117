import { combineReducers } from 'redux'

import groupMonitorOrder from './group-monitor-order'
import routeManagement from './route-management'
import warehouseImportReceipt from './warehouse-import-receipt'
import monitorSheet from './monitor-sheet'

export default combineReducers({
  warehouseImportReceipt,
  routeManagement,
  groupMonitorOrder,
  monitorSheet,
})
