import {
  CREATE_WAREHOUSE_IMPORT_RECEIPT_FAILED,
  CREATE_WAREHOUSE_IMPORT_RECEIPT_START,
  CREATE_WAREHOUSE_IMPORT_RECEIPT_SUCCESS,
  DELETE_WAREHOUSE_IMPORT_RECEIPT_FAILED,
  DELETE_WAREHOUSE_IMPORT_RECEIPT_START,
  DELETE_WAREHOUSE_IMPORT_RECEIPT_SUCCESS,
  GET_WAREHOUSE_IMPORT_RECEIPT_DETAILS_FAILED,
  GET_WAREHOUSE_IMPORT_RECEIPT_DETAILS_START,
  GET_WAREHOUSE_IMPORT_RECEIPT_DETAILS_SUCCESS,
  SEARCH_WAREHOUSE_IMPORT_RECEIPT_FAILED,
  SEARCH_WAREHOUSE_IMPORT_RECEIPT_START,
  SEARCH_WAREHOUSE_IMPORT_RECEIPT_SUCCESS,
  UPDATE_WAREHOUSE_IMPORT_RECEIPT_FAILED,
  UPDATE_WAREHOUSE_IMPORT_RECEIPT_START,
  UPDATE_WAREHOUSE_IMPORT_RECEIPT_SUCCESS,
  UPDATE_HEADER_WAREHOUSE_IMPORT_RECEIPT_FAILED,
  UPDATE_HEADER_WAREHOUSE_IMPORT_RECEIPT_START,
  UPDATE_HEADER_WAREHOUSE_IMPORT_RECEIPT_SUCCESS,
  CONFIRM_WAREHOUSE_IMPORT_RECEIPT_FAILED,
  CONFIRM_WAREHOUSE_IMPORT_RECEIPT_START,
  CONFIRM_WAREHOUSE_IMPORT_RECEIPT_SUCCESS,
  REJECT_WAREHOUSE_IMPORT_RECEIPT_FAILED,
  REJECT_WAREHOUSE_IMPORT_RECEIPT_START,
  REJECT_WAREHOUSE_IMPORT_RECEIPT_SUCCESS,
  RESET_WAREHOUSE_IMPORT_RECEIPT_DETAILS_STATE,
  GET_ATTRIBUITE_BUSINESS_TYPE_DETAILS_FAILED,
  GET_ATTRIBUITE_BUSINESS_TYPE_DETAILS_START,
  GET_ATTRIBUITE_BUSINESS_TYPE_DETAILS_SUCCESS,
  IMPORT_WAREHOUSE_START,
  IMPORT_WAREHOUSE_SUCCESS,
  IMPORT_WAREHOUSE_FAILED,
  CONFIRM_WAREHOUSE_IMPORT_EBS_FAILED,
  CONFIRM_WAREHOUSE_IMPORT_EBS_START,
  CONFIRM_WAREHOUSE_IMPORT_EBS_SUCCESS,
  CANCEL_WAREHOUSE_IMPORT_EBS_FAILED,
  CANCEL_WAREHOUSE_IMPORT_EBS_START,
  CANCEL_WAREHOUSE_IMPORT_EBS_SUCCESS,
  RETURN_WAREHOUSE_IMPORT_RECEIPT_FAILED,
  RETURN_WAREHOUSE_IMPORT_RECEIPT_START,
  RETURN_WAREHOUSE_IMPORT_RECEIPT_SUCCESS,
  RECEIVE_START,
  RECEIVE_SUCCESS,
  RECEIVE_FAILED,
  STORED_START,
  STORED_SUCCESS,
  STORED_FAILED,
  SUGGEST_LOCATORS_START,
  SUGGEST_LOCATORS_SUCCESS,
  SUGGEST_LOCATORS_FAILED,
  SEEN_TO_DRIVER_FAILED,
  SEEN_TO_DRIVER_START,
  SEEN_TO_DRIVER_SUCCESS,
  RETRY_FAILED,
  RETRY_START,
  RETRY_SUCCESS,
  SIGN_CUSTOMER_FAILED,
  SIGN_CUSTOMER_START,
  SIGN_CUSTOMER_SUCCESS,
  SIGN_DRIVER_FAILED,
  SIGN_DRIVER_START,
  SIGN_DRIVER_SUCCESS,
  COMPLETED_MONITOR_ORDER_FAILED,
  COMPLETED_MONITOR_ORDER_START,
  COMPLETED_MONITOR_ORDER_SUCCESS,
  RESET_PASSWORD_USER_FAILED,
  RESET_PASSWORD_USER_START,
  RESET_PASSWORD_USER_SUCCESS,
  SEARCH_ROUTE_FAILED,
  SEARCH_ROUTE_START,
  SEARCH_ROUTE_SUCCESS,
} from '~/modules/wmsx/redux/actions/warehouse-import-receipt'

const initialState = {
  isLoading: false,
  warehouseImportReceiptList: [],
  warehouseImportReceiptDetails: {},
  attributesBusinessTypeDetails: [],
  suggestLocatorsList: [],
  total: null,
  routeList: [],
}

/**
 * Sample reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function warehouseImportReceipt(state = initialState, action) {
  switch (action.type) {
    case SEARCH_WAREHOUSE_IMPORT_RECEIPT_START:
    case CREATE_WAREHOUSE_IMPORT_RECEIPT_START:
    case UPDATE_WAREHOUSE_IMPORT_RECEIPT_START:
    case UPDATE_HEADER_WAREHOUSE_IMPORT_RECEIPT_START:
    case DELETE_WAREHOUSE_IMPORT_RECEIPT_START:
    case CONFIRM_WAREHOUSE_IMPORT_RECEIPT_START:
    case REJECT_WAREHOUSE_IMPORT_RECEIPT_START:
    case GET_WAREHOUSE_IMPORT_RECEIPT_DETAILS_START:
    case IMPORT_WAREHOUSE_START:
    case RECEIVE_START:
    case STORED_START:
    case CONFIRM_WAREHOUSE_IMPORT_EBS_START:
    case CANCEL_WAREHOUSE_IMPORT_EBS_START:
    case RETURN_WAREHOUSE_IMPORT_RECEIPT_START:
    case SUGGEST_LOCATORS_START:
    case SEEN_TO_DRIVER_START:
    case RETRY_START:
    case SIGN_CUSTOMER_START:
    case SIGN_DRIVER_START:
    case COMPLETED_MONITOR_ORDER_START:
    case RESET_PASSWORD_USER_START:
    case SEARCH_ROUTE_START:
      return {
        ...state,
        isLoading: true,
      }
    case SEARCH_WAREHOUSE_IMPORT_RECEIPT_SUCCESS:
      return {
        ...state,
        warehouseImportReceiptList: action.payload.list,
        isLoading: false,
        total: action.payload.total,
      }

    case SEARCH_WAREHOUSE_IMPORT_RECEIPT_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case SEARCH_ROUTE_SUCCESS:
      return {
        ...state,
        routeList: action.payload.list,
        isLoading: false,
      }
    case CONFIRM_WAREHOUSE_IMPORT_RECEIPT_FAILED:
    case CONFIRM_WAREHOUSE_IMPORT_RECEIPT_SUCCESS:
    case CONFIRM_WAREHOUSE_IMPORT_EBS_FAILED:
    case CONFIRM_WAREHOUSE_IMPORT_EBS_SUCCESS:
    case REJECT_WAREHOUSE_IMPORT_RECEIPT_FAILED:
    case REJECT_WAREHOUSE_IMPORT_RECEIPT_SUCCESS:
    case CREATE_WAREHOUSE_IMPORT_RECEIPT_SUCCESS:
    case CREATE_WAREHOUSE_IMPORT_RECEIPT_FAILED:
    case UPDATE_WAREHOUSE_IMPORT_RECEIPT_SUCCESS:
    case UPDATE_HEADER_WAREHOUSE_IMPORT_RECEIPT_SUCCESS:
    case UPDATE_WAREHOUSE_IMPORT_RECEIPT_FAILED:
    case UPDATE_HEADER_WAREHOUSE_IMPORT_RECEIPT_FAILED:
    case DELETE_WAREHOUSE_IMPORT_RECEIPT_SUCCESS:
    case DELETE_WAREHOUSE_IMPORT_RECEIPT_FAILED:
    case IMPORT_WAREHOUSE_SUCCESS:
    case IMPORT_WAREHOUSE_FAILED:
    case RECEIVE_SUCCESS:
    case RECEIVE_FAILED:
    case STORED_SUCCESS:
    case STORED_FAILED:
    case CANCEL_WAREHOUSE_IMPORT_EBS_SUCCESS:
    case CANCEL_WAREHOUSE_IMPORT_EBS_FAILED:
    case RETURN_WAREHOUSE_IMPORT_RECEIPT_SUCCESS:
    case RETURN_WAREHOUSE_IMPORT_RECEIPT_FAILED:
    case SEEN_TO_DRIVER_SUCCESS:
    case SEEN_TO_DRIVER_FAILED:
    case RETRY_SUCCESS:
    case RETRY_FAILED:
    case SIGN_CUSTOMER_SUCCESS:
    case SIGN_CUSTOMER_FAILED:
    case SIGN_DRIVER_SUCCESS:
    case SIGN_DRIVER_FAILED:
    case COMPLETED_MONITOR_ORDER_SUCCESS:
    case COMPLETED_MONITOR_ORDER_FAILED:
    case RESET_PASSWORD_USER_SUCCESS:
    case RESET_PASSWORD_USER_FAILED:
    case SEARCH_ROUTE_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case GET_WAREHOUSE_IMPORT_RECEIPT_DETAILS_SUCCESS:
      return {
        ...state,
        warehouseImportReceiptDetails: action.payload,
        isLoading: false,
      }
    case GET_WAREHOUSE_IMPORT_RECEIPT_DETAILS_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case GET_ATTRIBUITE_BUSINESS_TYPE_DETAILS_FAILED:
    case GET_ATTRIBUITE_BUSINESS_TYPE_DETAILS_START:
      return {
        ...state,
      }
    case GET_ATTRIBUITE_BUSINESS_TYPE_DETAILS_SUCCESS:
      return {
        ...state,
        attributesBusinessTypeDetails: action.payload,
      }
    case RESET_WAREHOUSE_IMPORT_RECEIPT_DETAILS_STATE:
      return {
        ...state,
        warehouseImportReceiptDetails: {},
      }
    case SUGGEST_LOCATORS_SUCCESS:
      return {
        ...state,
        suggestLocatorsList: action.payload,
        isLoading: false,
      }
    case SUGGEST_LOCATORS_FAILED:
      return {
        ...state,
        suggestLocatorsList: [],
        isLoading: false,
      }
    default:
      return state
  }
}
