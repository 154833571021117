import ChangePassword from '~/modules/configuration/feature/user-info/change-password'
import UserInfoDetail from '~/modules/configuration/feature/user-info/user-detail'
import UserManagement from '~/modules/mesx/features/user-management'
import UserManagementDetail from '~/modules/mesx/features/user-management/user-detail'
import UserManagementForm from '~/modules/mesx/features/user-management/user-form'
import CompletedPage from '~/modules/public/features/homePage'

import { ROUTE } from './config'
import { ROLE } from '../constants'
import GroupMonitorOrderDetail from '../features/group-monitor-order/detail'
import GroupMonitorOrderForm from '../features/group-monitor-order/form'
import GroupMonitorOrder from '../features/group-monitor-order/list'
import RouteManagementDetail from '../features/route-management/detail'
import RouteManagementForm from '../features/route-management/form'
import RouteManagement from '../features/route-management/list'
import WarehouseImportReceiptDetail from '../features/warehouse-import-receipt/detail'
import WarehouseImportReceiptForm from '../features/warehouse-import-receipt/form'
import WarehouseImportReceipt from '../features/warehouse-import-receipt/list'
import MonitorSheetDetail from '../features/monitor-sheet/detail'
import GroupMonitorOrderPrint from '../features/group-monitor-order/print'

const routes = [
  {
    name: ROUTE.HOME.TITLE,
    path: ROUTE.HOME.PATH,
    component: WarehouseImportReceipt,
    role: [ROLE.ADMIN, ROLE.USER],
    subPathname: 'monitor-order',
  },
  {
    name: ROUTE.WAREHOUSE_IMPORT_RECEIPT.LIST.TITLE,
    path: ROUTE.WAREHOUSE_IMPORT_RECEIPT.LIST.PATH,
    component: WarehouseImportReceipt,
    role: [ROLE.ADMIN, ROLE.USER],
    isInSidebar: true,
    subPathname: 'monitor-order',
  },
  {
    name: ROUTE.WAREHOUSE_IMPORT_RECEIPT.CREATE.TITLE,
    path: ROUTE.WAREHOUSE_IMPORT_RECEIPT.CREATE.PATH,
    component: WarehouseImportReceiptForm,
    role: [ROLE.ADMIN, ROLE.USER],
    pathParent: ROUTE.WAREHOUSE_IMPORT_RECEIPT.LIST.PATH,
    isInSidebar: false,
  },
  {
    name: ROUTE.WAREHOUSE_IMPORT_RECEIPT.DETAIL.TITLE,
    path: ROUTE.WAREHOUSE_IMPORT_RECEIPT.DETAIL.PATH,
    component: WarehouseImportReceiptDetail,
    role: [ROLE.ADMIN, ROLE.USER],
    pathParent: ROUTE.WAREHOUSE_IMPORT_RECEIPT.LIST.PATH,
    isInSidebar: false,
  },
  {
    name: ROUTE.WAREHOUSE_IMPORT_RECEIPT.EDIT.TITLE,
    path: ROUTE.WAREHOUSE_IMPORT_RECEIPT.EDIT.PATH,
    component: WarehouseImportReceiptForm,
    role: [ROLE.ADMIN, ROLE.USER],
    pathParent: ROUTE.WAREHOUSE_IMPORT_RECEIPT.LIST.PATH,
    isInSidebar: false,
  },
  {
    name: ROUTE.WAREHOUSE_IMPORT_RECEIPT.COMPLETED.TITLE,
    path: ROUTE.WAREHOUSE_IMPORT_RECEIPT.COMPLETED.PATH,
    component: CompletedPage,
    role: [ROLE.ADMIN, ROLE.USER],
    pathParent: ROUTE.WAREHOUSE_IMPORT_RECEIPT.LIST.PATH,
    isInSidebar: false,
  },

  ///
  {
    name: ROUTE.GROUP_MONITOR_ORDER.LIST.TITLE,
    path: ROUTE.GROUP_MONITOR_ORDER.LIST.PATH,
    component: GroupMonitorOrder,
    role: [ROLE.ADMIN, ROLE.USER],
    isInSidebar: true,
    subPathname: 'group-monitor-order',
  },
  {
    name: ROUTE.GROUP_MONITOR_ORDER.CREATE.TITLE,
    path: ROUTE.GROUP_MONITOR_ORDER.CREATE.PATH,
    component: GroupMonitorOrderForm,
    role: [ROLE.ADMIN, ROLE.USER],
    pathParent: ROUTE.GROUP_MONITOR_ORDER.LIST.PATH,
    isInSidebar: false,
  },
  {
    name: ROUTE.GROUP_MONITOR_ORDER.DETAIL.TITLE,
    path: ROUTE.GROUP_MONITOR_ORDER.DETAIL.PATH,
    component: GroupMonitorOrderDetail,
    role: [ROLE.ADMIN, ROLE.USER],
    pathParent: ROUTE.GROUP_MONITOR_ORDER.LIST.PATH,
    isInSidebar: false,
  },
  {
    name: ROUTE.GROUP_MONITOR_ORDER.EDIT.TITLE,
    path: ROUTE.GROUP_MONITOR_ORDER.EDIT.PATH,
    component: GroupMonitorOrderForm,
    role: [ROLE.ADMIN, ROLE.USER],
    pathParent: ROUTE.GROUP_MONITOR_ORDER.LIST.PATH,
    isInSidebar: false,
  },
  {
    name: ROUTE.GROUP_MONITOR_ORDER.PRINT.TITLE,
    path: ROUTE.GROUP_MONITOR_ORDER.PRINT.PATH,
    component: GroupMonitorOrderPrint,
    role: [ROLE.ADMIN, ROLE.USER],
    pathParent: ROUTE.GROUP_MONITOR_ORDER.LIST.PATH,
    isInSidebar: false,
  },

  {
    name: ROUTE.MONITOR_SHEET.DETAIL.TITLE,
    path: ROUTE.MONITOR_SHEET.DETAIL.PATH,
    component: MonitorSheetDetail,
    role: [ROLE.ADMIN, ROLE.USER],
    pathParent: '',
    isInSidebar: false,
  },

  {
    name: ROUTE.ROUTE_MANAGEMENT.LIST.TITLE,
    path: ROUTE.ROUTE_MANAGEMENT.LIST.PATH,
    component: RouteManagement,
    role: [ROLE.ADMIN],
    isInSidebar: true,
    subPathname: 'route-management',
  },

  {
    name: ROUTE.ROUTE_MANAGEMENT.CREATE.TITLE,
    path: ROUTE.ROUTE_MANAGEMENT.CREATE.PATH,
    component: RouteManagementForm,
    role: [ROLE.ADMIN],
    pathParent: ROUTE.ROUTE_MANAGEMENT.LIST.PATH,
    isInSidebar: false,
  },
  {
    name: ROUTE.ROUTE_MANAGEMENT.DETAIL.TITLE,
    path: ROUTE.ROUTE_MANAGEMENT.DETAIL.PATH,
    component: RouteManagementDetail,
    role: [ROLE.ADMIN, ROLE.USER],
    pathParent: ROUTE.ROUTE_MANAGEMENT.LIST.PATH,
    isInSidebar: false,
  },
  {
    name: ROUTE.ROUTE_MANAGEMENT.EDIT.TITLE,
    path: ROUTE.ROUTE_MANAGEMENT.EDIT.PATH,
    component: RouteManagementForm,
    pathParent: ROUTE.ROUTE_MANAGEMENT.LIST.PATH,
    role: [ROLE.ADMIN],
    isInSidebar: false,
  },

  {
    name: ROUTE.USER_MANAGEMENT.LIST.TITLE,
    path: ROUTE.USER_MANAGEMENT.LIST.PATH,
    component: UserManagement,
    role: [ROLE.ADMIN],
    isInSidebar: true,
    subPathname: 'user-management',
  },

  {
    name: ROUTE.USER_MANAGEMENT.CREATE.TITLE,
    path: ROUTE.USER_MANAGEMENT.CREATE.PATH,
    component: UserManagementForm,
    role: [ROLE.ADMIN],
    pathParent: ROUTE.USER_MANAGEMENT.LIST.PATH,
    isInSidebar: false,
  },
  {
    name: ROUTE.USER_MANAGEMENT.DETAIL.TITLE,
    path: ROUTE.USER_MANAGEMENT.DETAIL.PATH,
    component: UserManagementDetail,
    role: [ROLE.ADMIN, ROLE.USER],
    pathParent: ROUTE.USER_MANAGEMENT.LIST.PATH,
    isInSidebar: false,
  },
  {
    name: ROUTE.USER_MANAGEMENT.EDIT.TITLE,
    path: ROUTE.USER_MANAGEMENT.EDIT.PATH,
    component: UserManagementForm,
    pathParent: ROUTE.USER_MANAGEMENT.LIST.PATH,
    role: [ROLE.ADMIN],
    isInSidebar: false,
  },
  {
    name: ROUTE.ACCOUNT.DETAIL.TITLE,
    path: ROUTE.ACCOUNT.DETAIL.PATH,
    component: UserInfoDetail,
    role: [ROLE.ADMIN, ROLE.USER],
  },
  {
    name: ROUTE.ACCOUNT.CHANGE_PASSWORD.TITLE,
    path: ROUTE.ACCOUNT.CHANGE_PASSWORD.PATH,
    component: ChangePassword,
    role: [ROLE.ADMIN, ROLE.USER],
  },
]
export default routes
