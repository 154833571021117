import {
  GET_MONITOR_SHEET_DETAILS_FAILED,
  GET_MONITOR_SHEET_DETAILS_START,
  GET_MONITOR_SHEET_DETAILS_SUCCESS,
  RESET_MONITOR_SHEET_DETAILS_STATE,
  SUBMIT_START_TIME_FAILED,
  SUBMIT_START_TIME_START,
  SUBMIT_SIGN_MONITOR_SHEET_START,
  SUBMIT_START_TIME_SUCCESS,
  SUBMIT_SIGN_MONITOR_SHEET_FAILED,
  SUBMIT_SIGN_MONITOR_SHEET_SUCCESS,
  COMPLETE_MONITOR_SHEET_START,
  COMPLETE_MONITOR_SHEET_FAILED,
  COMPLETE_MONITOR_SHEET_SUCCESS,
} from '~/modules/wmsx/redux/actions/monitor-sheet'

const initialState = {
  isLoading: false,
  monitorSheetDetails: {},
  total: null,
}

/**
 * Sample reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function monitorSheet(state = initialState, action) {
  switch (action.type) {
    case GET_MONITOR_SHEET_DETAILS_START:
    case SUBMIT_START_TIME_START:
    case SUBMIT_SIGN_MONITOR_SHEET_START:
    case COMPLETE_MONITOR_SHEET_START:
      return {
        ...state,
        isLoading: true,
      }
    case GET_MONITOR_SHEET_DETAILS_SUCCESS:
      return {
        ...state,
        monitorSheetDetails: action.payload,
        isLoading: false,
      }
    case GET_MONITOR_SHEET_DETAILS_FAILED:
    case SUBMIT_START_TIME_FAILED:
    case SUBMIT_START_TIME_SUCCESS:
    case SUBMIT_SIGN_MONITOR_SHEET_FAILED:
    case SUBMIT_SIGN_MONITOR_SHEET_SUCCESS:
    case COMPLETE_MONITOR_SHEET_FAILED:
    case COMPLETE_MONITOR_SHEET_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case RESET_MONITOR_SHEET_DETAILS_STATE:
      return {
        ...state,
        monitorSheetDetails: {},
      }
    default:
      return state
  }
}
