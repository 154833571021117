import { useEffect, useRef } from 'react'

import { Box, Grid, Typography } from '@mui/material'
import { useHistory, useParams } from 'react-router-dom'
import '~/css/custom-print-a4.css'

import Button from '~/components/Button'
import Page from '~/components/Page'
import useGroupMonitorOrder from '~/modules/wmsx/redux/hooks/useGroupMonitorOrder'
import { ROUTE } from '~/modules/wmsx/routes/config'

import ItemSettingTable from './item-seting-table'
import { useReactToPrint } from 'react-to-print'
const breadcrumbs = [
  {
    route: ROUTE.GROUP_MONITOR_ORDER.LIST.PATH,
    title: ROUTE.GROUP_MONITOR_ORDER.LIST.TITLE,
  },
  {
    route: ROUTE.GROUP_MONITOR_ORDER.PRINT.PATH,
    title: ROUTE.GROUP_MONITOR_ORDER.PRINT.TITLE,
  },
]
function GroupMonitorOrderPrint() {
  const history = useHistory()
  const { id } = useParams()
  const componentRef = useRef()
  const {
    data: { groupMonitorOrderDetails, isLoading },
    actions,
  } = useGroupMonitorOrder()

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  useEffect(() => {
    actions.getGroupMonitorOrderDetailsById(id)
    return () => {
      actions.resetGroupMonitorOrderState()
    }
  }, [id])
  const backToList = () => {
    history.push(ROUTE.GROUP_MONITOR_ORDER.DETAIL.PATH.replace(':id', `${id}`))
  }

  const renderHeaderRight = () => {
    return (
      <>
        <Button onClick={() => handlePrint()} sx={{ ml: 4 / 3 }} icon="print">
          In phiếu
        </Button>
      </>
    )
  }
  const items = groupMonitorOrderDetails?.monitorSheets?.map((item, index) => ({
    ...item,
    date: new Date(item?.date),
    index: index,
    time: item?.start,
    lineId: item?.line || '周日',
    type: item?.type,
    vehicleType: item?.vehicleType,
    vehicleNumber: item?.vehicleNumber,
    note: item?.note,
  })) || [{}]
  return (
    <Page
      breadcrumbs={breadcrumbs}
      title={`In nhóm giám sát xe`}
      onBack={backToList}
      renderHeaderRight={renderHeaderRight}
      loading={isLoading}
    >
      <Grid
        container
        justifyContent="center"
        ref={componentRef}
        sx={{ zoom: '80%' }}
      >
        <Grid item xl={11} xs={12}>
          <Grid container rowSpacing={4 / 3} columnSpacing={{ xl: 8, xs: 4 }}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: 24,
                  fontWeight: 'bold',
                }}
              >
                {groupMonitorOrderDetails?.companyVI}
              </Typography>
              <Typography
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: 24,
                  fontWeight: 'bold',
                }}
              >
                {groupMonitorOrderDetails?.companyCN}
              </Typography>
            </Grid>
          </Grid>
          <Box>
            <ItemSettingTable items={items || []} id={id} />
          </Box>
        </Grid>
      </Grid>
    </Page>
  )
}
export default GroupMonitorOrderPrint
