import { call, put, takeLatest } from 'redux-saga/effects'

import { NOTIFICATION_TYPE } from '~/common/constants'
import { api } from '~/services/api'
import addNotification from '~/utils/toast'
import {
  SUBMIT_SIGN_MONITOR_SHEET_START,
  submitSignMonitorSheetByIdFailed,
  submitSignMonitorSheetByIdSuccess,
} from '../../actions/monitor-sheet'

const submitSignature = (params) => {
  const uri = `/monitor-sheets/${params?.id}/submit-signature`
  const data = { ...params }
  delete data['id']
  return api.put(uri, data)
}

function* doSubmitSignature(action) {
  try {
    const response = yield call(submitSignature, action?.payload)

    if (response?.statusCode === 200) {
      yield put(submitSignMonitorSheetByIdSuccess(response.payload))

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess()
      }

      addNotification(response?.message, NOTIFICATION_TYPE.SUCCESS)
    } else {
      addNotification(
        response?.message || response?.statusText,
        NOTIFICATION_TYPE.ERROR,
      )

      throw new Error(response?.message)
    }
  } catch (error) {
    yield put(submitSignMonitorSheetByIdFailed())
    // Call callback action if provided
    if (action.onError) {
      yield action.onError()
    }
  }
}

export default function* watchSubmitSignatureMonitorSheet() {
  yield takeLatest(SUBMIT_SIGN_MONITOR_SHEET_START, doSubmitSignature)
}
