import { useEffect, useState } from 'react'

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'

import { useQueryState } from '~/common/hooks'
import Button from '~/components/Button'
import DataTable from '~/components/DataTable'
import Dialog from '~/components/Dialog'
import Icon from '~/components/Icon'
import IconButton from '~/components/IconButton'
import Page from '~/components/Page'
import Status from '~/components/Status'
import {
  ROUTE_MANAGEMENT_OPTIONS,
  ROUTE_MANAGEMENT_STATUS,
} from '~/modules/wmsx/constants'
import useGroupMonitorOrder from '~/modules/wmsx/redux/hooks/useGroupMonitorOrder'
import { ROUTE } from '~/modules/wmsx/routes/config'
import { convertSortParams } from '~/utils'

import FilterForm from './filter-form'

function GroupMonitorOrder() {
  const { t } = useTranslation('wmsx')
  const history = useHistory()
  const DEFAULT_FILTERS = {
    code: '',
    name: '',
    createTime: [],
  }
  const breadcrumbs = [
    {
      route: ROUTE.GROUP_MONITOR_ORDER.LIST.PATH,
      title: ROUTE.GROUP_MONITOR_ORDER.LIST.TITLE,
    },
  ]
  const {
    data: { groupMonitorOrderList, total, isLoading },
    actions,
  } = useGroupMonitorOrder()
  const {
    page,
    pageSize,
    sort,
    filters,
    keyword,
    setPage,
    setPageSize,
    setSort,
    setFilters,
    setKeyword,
  } = useQueryState({
    filters: DEFAULT_FILTERS,
  })

  const [modal, setModal] = useState({
    tempItem: null,
    isOpenDeleteModal: false,
    isOpenConfirmModal: false,
  })

  const columns = [
    {
      field: 'id',
      headerName: 'Mã nhóm',
      width: 50,
      sortable: false,
      align: 'center',
      renderCell: (params) => {
        return params?.row?.id
      },
    },
    {
      field: 'company',
      headerName: 'Tên công ty',
      width: 200,
      sortable: false,
      align: 'center',
      renderCell: (params) => {
        return (
          <>
            <Typography>{`${params?.row?.companyCN}`}</Typography>
            <Typography>{`${params?.row?.companyVI} `}</Typography>
          </>
        )
      },
    },
    {
      field: 'route',
      headerName: 'Tuyến đường',
      width: 200,
      sortable: false,
      align: 'center',
      renderCell: (params) => {
        return (
          <>
            <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
              <Box>{params?.row?.line?.fromCN}</Box>
              <ArrowRightAltIcon fontSize="medium" />
              <Box>{params?.row?.line?.toCN}</Box>
            </Typography>
            <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
              <Box>{params?.row?.line?.fromVI}</Box>
              <ArrowRightAltIcon fontSize="medium" />
              <Box>{params?.row?.line?.toVI}</Box>
            </Typography>
          </>
        )
      },
    },
    {
      field: 'monthYear',
      headerName: 'Ngày tháng',
      width: 150,
      sortable: false,
      align: 'center',
      renderCell: (params) => {
        return (
          <Typography>{`Tháng ${params?.row?.month} năm ${params?.row?.year}`}</Typography>
        )
      },
    },
    {
      field: 'status',
      headerName: 'Trạng thái',
      sortable: false,
      width: 100,
      renderCell: (params) => {
        const status = Number(params?.row.status)
        return (
          <Status
            options={ROUTE_MANAGEMENT_OPTIONS}
            value={status}
            variant="text"
          />
        )
      },
    },
    {
      field: 'action',
      headerName: t('general:common.action'),
      width: 200,
      align: 'center',
      visible: 'always',
      sticky: 'right',
      renderCell: (params) => {
        const { id, status } = params?.row
        const isDelete = status === ROUTE_MANAGEMENT_STATUS.PENDING
        const isConfirmed = status === ROUTE_MANAGEMENT_STATUS.PENDING

        return (
          <div>
            <IconButton
              title={t('iconButtonHover.view')}
              onClick={() =>
                history.push(
                  ROUTE.GROUP_MONITOR_ORDER.DETAIL.PATH.replace(':id', `${id}`),
                )
              }
            >
              <Icon name={'show'} />
            </IconButton>

            <IconButton
              title={t('iconButtonHover.update')}
              onClick={() =>
                history.push(
                  ROUTE.GROUP_MONITOR_ORDER.EDIT.PATH.replace(':id', `${id}`),
                )
              }
            >
              <Icon name="edit" />
            </IconButton>

            {isDelete && (
              <IconButton
                title={t('iconButtonHover.delete')}
                onClick={() => onClickDelete(params.row)}
              >
                <Icon name="delete" />
              </IconButton>
            )}
            {isConfirmed && (
              <IconButton
                title={t('iconButtonHover.confirm')}
                onClick={() => onClickConfirm(params.row)}
              >
                <Icon name="tick" />
              </IconButton>
            )}
          </div>
        )
      },
    },
  ]
  const onClickDelete = (tempItem) => {
    setModal({ tempItem, isOpenDeleteModal: true })
  }
  const onClickConfirm = (tempItem) => {
    setModal({ tempItem, isOpenConfirmModal: true })
  }
  const onSubmitDelete = () => {
    actions.deleteGroupMonitorOrder(modal.tempItem?.id, () => {
      refreshData()
    })
    setModal({ isOpenDeleteModal: false, tempItem: null })
  }
  const onSubmitConfirm = () => {
    actions.confirmGroupMonitorOrderById(modal.tempItem?.id, () => {
      refreshData()
    })
    setModal({ isOpenConfirmModal: false, tempItem: null })
  }
  const refreshData = () => {
    const params = {
      keyword: keyword.trim(),
      page,
      limit: pageSize,
      createdByUserId: filters?.createdByUser?.id || null,
      status: filters?.status || null,
      sort: convertSortParams(sort),
    }
    actions.searchGroupMonitorOrder(params)
  }
  const onCloseDeleteModal = () => {
    setModal({
      isOpenDeleteModal: false,
      tempItem: null,
      isOpenConfirmModal: false,
    })
  }
  useEffect(() => {
    refreshData()
  }, [page, pageSize, filters, sort, keyword])

  const renderHeaderRight = () => {
    return (
      <>
        <Button
          onClick={() => history.push(ROUTE.GROUP_MONITOR_ORDER.CREATE.PATH)}
          sx={{ ml: 4 / 3 }}
          icon="add"
        >
          {t('general:common.create')}
        </Button>
      </>
    )
  }
  return (
    <Page
      breadcrumbs={breadcrumbs}
      title="Quản lý nhóm giám sát xe"
      onSearch={setKeyword}
      placeholder=""
      renderHeaderRight={renderHeaderRight}
      loading={isLoading}
    >
      <DataTable
        title="Danh sách nhóm giám sát xe"
        rows={groupMonitorOrderList}
        pageSize={pageSize}
        page={page}
        columns={columns}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        onSortChange={setSort}
        // selected={selectedRows}
        total={total}
        sort={sort}
        enableResizable={false}
        filters={{
          form: <FilterForm />,
          values: filters,
          defaultValue: DEFAULT_FILTERS,
          onApply: setFilters,
        }}
      />
      <Dialog
        open={modal.isOpenDeleteModal}
        title="Xoá nhóm phiếu giám sát xe"
        onCancel={onCloseDeleteModal}
        cancelLabel={t('general:common.no')}
        onSubmit={onSubmitDelete}
        submitLabel={t('general:common.yes')}
        submitProps={{
          color: 'error',
        }}
        noBorderBottom
      >
        Bạn có chắc chắn muốn xoá nhóm phiếu giám sát xe này không?
      </Dialog>
      <Dialog
        open={modal.isOpenConfirmModal}
        title="Xác nhận nhóm phiếu giám sát xe"
        onCancel={onCloseDeleteModal}
        cancelLabel={t('general:common.no')}
        onSubmit={onSubmitConfirm}
        submitLabel={t('general:common.yes')}
        noBorderBottom
      >
        Bạn có chắc chắn muốn xác nhận nhóm phiếu giám sát xe này không?
      </Dialog>
    </Page>
  )
}
export default GroupMonitorOrder
