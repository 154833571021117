import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api'
import {
  GET_MONITOR_SHEET_DETAILS_START,
  getMonitorSheetDetailsByIdFailed,
  getMonitorSheetDetailsByIdSuccess,
} from '~/modules/wmsx/redux/actions/monitor-sheet'

export const getMonitorSheetDetailsApi = (params) => {
  const uri = `/monitor-sheets/${params}`
  return api.get(uri)
}

function* doGetMonitorSheetDetails(action) {
  try {
    const response = yield call(getMonitorSheetDetailsApi, action?.payload)

    if (response?.statusCode === 200) {
      yield put(getMonitorSheetDetailsByIdSuccess(response.data))

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess(response.data)
      }
    } else {
      throw new Error(response?.message)
    }
  } catch (error) {
    yield put(getMonitorSheetDetailsByIdFailed())
    // Call callback action if provided
    if (action.onError) {
      yield action.onError()
    }
  }
}

export default function* watchGetMonitorSheetDetails() {
  yield takeLatest(GET_MONITOR_SHEET_DETAILS_START, doGetMonitorSheetDetails)
}
