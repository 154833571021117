import { useEffect, useState } from 'react'

import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'

import ActionBar from '~/components/ActionBar'
import Button from '~/components/Button'
import Dialog from '~/components/Dialog'
import LV from '~/components/LabelValue'
import Page from '~/components/Page'
import Status from '~/components/Status'
import {
  ROUTE_MANAGEMENT_OPTIONS,
  ROUTE_MANAGEMENT_STATUS,
} from '~/modules/wmsx/constants'
import useRouteManagement from '~/modules/wmsx/redux/hooks/useRouteManagement'
import { ROUTE } from '~/modules/wmsx/routes/config'
const breadcrumbs = [
  {
    route: ROUTE.ROUTE_MANAGEMENT.LIST.PATH,
    title: ROUTE.ROUTE_MANAGEMENT.LIST.TITLE,
  },
  {
    route: ROUTE.ROUTE_MANAGEMENT.DETAIL.PATH,
    title: ROUTE.ROUTE_MANAGEMENT.DETAIL.TITLE,
  },
]
function RouteManagementDetail() {
  const history = useHistory()
  const { id } = useParams()
  const { t } = useTranslation('wmsx')
  const {
    data: { routeManagementDetails, isLoading },
    actions,
  } = useRouteManagement()

  const [modal, setModal] = useState({
    tempItem: null,
    isOpenDeleteModal: false,
    isOpenConfirmModal: false,
  })
  useEffect(() => {
    actions.getRouteManagementDetailsById(id)
    return () => {
      actions.resetStateRouteManagement()
    }
  }, [id])
  const backToList = () => {
    history.push(ROUTE.ROUTE_MANAGEMENT.LIST.PATH)
  }
  const onClickDelete = () => {
    setModal({ isOpenDeleteModal: true })
  }
  const onClickConfirm = () => {
    setModal({ isOpenConfirmModal: true })
  }
  const onSubmitDelete = () => {
    actions.deleteRouteManagement(id, () => {
      onCloseDeleteModal()
      actions.getRouteManagementDetailsById(id)
    })
  }
  const onSubmitConfirm = () => {
    actions.confirmRouteManagementById(id, () => {
      onCloseDeleteModal()
      actions.getRouteManagementDetailsById(id)
    })
  }
  const onCloseDeleteModal = () => {
    setModal({
      isOpenDeleteModal: false,
      tempItem: null,
      isOpenConfirmModal: false,
    })
  }
  const renderHeaderRight = () => {
    const isDelete =
      routeManagementDetails.status === ROUTE_MANAGEMENT_STATUS.PENDING
    return (
      <>
        {isDelete && (
          <Button
            onClick={() => onClickDelete()}
            sx={{
              ml: 4 / 3,
            }}
            color="error"
          >
            Xoá
          </Button>
        )}
        {isDelete && (
          <Button onClick={() => onClickConfirm()} sx={{ ml: 4 / 3 }}>
            Xác nhận
          </Button>
        )}
        {isDelete && (
          <Button
            sx={{ ml: 4 / 3 }}
            onClick={() =>
              history.push(
                ROUTE.ROUTE_MANAGEMENT.EDIT.PATH.replace(':id', `${id}`),
              )
            }
          >
            Sửa tuyến đường
          </Button>
        )}
      </>
    )
  }
  return (
    <Page
      breadcrumbs={breadcrumbs}
      title={`Chi tiết tuyến đường`}
      onBack={backToList}
      renderHeaderRight={renderHeaderRight}
      loading={isLoading}
    >
      <Grid container justifyContent="center">
        <Grid item xl={11} xs={12}>
          <Grid container rowSpacing={4 / 3} columnSpacing={{ xl: 8, xs: 4 }}>
            <Grid item xs={12}>
              <LV
                label="Trạng thái"
                value={
                  <Status
                    options={ROUTE_MANAGEMENT_OPTIONS}
                    value={routeManagementDetails?.status}
                  />
                }
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <LV label="Từ" value={routeManagementDetails?.fromVI} />
            </Grid>
            <Grid item lg={6} xs={12}>
              <LV label="Đến" value={routeManagementDetails?.toVI} />
            </Grid>
            <Grid item lg={6} xs={12}>
              <LV label="Từ (從)" value={routeManagementDetails?.fromCN} />
            </Grid>
            <Grid item lg={6} xs={12}>
              <LV label="Đến (到達)" value={routeManagementDetails?.toCN} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ActionBar onBack={backToList} />
      <Dialog
        open={modal.isOpenDeleteModal}
        title="Xoá tuyến đường"
        onCancel={onCloseDeleteModal}
        cancelLabel={t('general:common.no')}
        onSubmit={onSubmitDelete}
        submitLabel={t('general:common.yes')}
        submitProps={{
          color: 'error',
        }}
        noBorderBottom
      >
        Bạn có chắc chắn muốn xoá tuyến đường này không?
      </Dialog>
      <Dialog
        open={modal.isOpenConfirmModal}
        title="Xác nhận tuyến đường"
        onCancel={onCloseDeleteModal}
        cancelLabel={t('general:common.no')}
        onSubmit={onSubmitConfirm}
        submitLabel={t('general:common.yes')}
        noBorderBottom
      >
        Bạn có chắc chắn muốn xác nhận tuyến đường này không?
      </Dialog>
    </Page>
  )
}
export default RouteManagementDetail
