export const GET_MONITOR_SHEET_DETAILS_START =
  'WMSX_GET_MONITOR_SHEET_DETAILS_START'
export const GET_MONITOR_SHEET_DETAILS_SUCCESS =
  'WMSX_GET_MONITOR_SHEET_DETAILS_SUCCESS'
export const GET_MONITOR_SHEET_DETAILS_FAILED =
  'WMSX_GET_MONITOR_SHEET_DETAILS_FAILED'

export const SUBMIT_START_TIME_START = 'WMSX_SUBMIT_START_TIME_START'
export const SUBMIT_START_TIME_SUCCESS = 'WMSX_SUBMIT_START_TIME_SUCCESS'
export const SUBMIT_START_TIME_FAILED = 'WMSX_SUBMIT_START_TIME_FAILED'

export const SUBMIT_SIGN_MONITOR_SHEET_START =
  'WMSX_SUBMIT_SIGN_MONITOR_SHEET_START'
export const SUBMIT_SIGN_MONITOR_SHEET_SUCCESS =
  'WMSX_SUBMIT_SIGN_MONITOR_SHEET_SUCCESS'
export const SUBMIT_SIGN_MONITOR_SHEET_FAILED =
  'WMSX_SUBMIT_SIGN_MONITOR_SHEET_FAILED'

export const COMPLETE_MONITOR_SHEET_START = 'WMSX_COMPLETE_MONITOR_SHEET_START'
export const COMPLETE_MONITOR_SHEET_SUCCESS =
  'WMSX_COMPLETE_MONITOR_SHEET_SUCCESS'
export const COMPLETE_MONITOR_SHEET_FAILED =
  'WMSX_COMPLETE_MONITOR_SHEET_FAILED'

export const RESET_MONITOR_SHEET_DETAILS_STATE =
  'WMSX_RESET_MONITOR_SHEET_DETAILS_STATE'

export function getMonitorSheetDetailsById(packageId, onSuccess, onError) {
  return {
    type: GET_MONITOR_SHEET_DETAILS_START,
    payload: packageId,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function getMonitorSheetDetailsByIdSuccess(payload) {
  return {
    type: GET_MONITOR_SHEET_DETAILS_SUCCESS,
    payload: payload,
  }
}

export function getMonitorSheetDetailsByIdFailed() {
  return {
    type: GET_MONITOR_SHEET_DETAILS_FAILED,
  }
}

export function submitStartTimeMonitorSheetById(Id, onSuccess, onError) {
  return {
    type: SUBMIT_START_TIME_START,
    payload: Id,
    onSuccess: onSuccess,
    onError: onError,
  }
}
export function submitStartTimeMonitorSheetByIdSuccess(payload) {
  return {
    type: SUBMIT_START_TIME_SUCCESS,
    payload: payload,
  }
}

export function submitStartTimeMonitorSheetByIdFailed() {
  return {
    type: SUBMIT_START_TIME_FAILED,
  }
}

export function submitSignMonitorSheetById(Id, onSuccess, onError) {
  return {
    type: SUBMIT_SIGN_MONITOR_SHEET_START,
    payload: Id,
    onSuccess: onSuccess,
    onError: onError,
  }
}
export function submitSignMonitorSheetByIdSuccess(payload) {
  return {
    type: SUBMIT_SIGN_MONITOR_SHEET_SUCCESS,
    payload: payload,
  }
}

export function submitSignMonitorSheetByIdFailed() {
  return {
    type: SUBMIT_SIGN_MONITOR_SHEET_FAILED,
  }
}

export function completeMonitorSheetById(Id, onSuccess, onError) {
  return {
    type: COMPLETE_MONITOR_SHEET_START,
    payload: Id,
    onSuccess: onSuccess,
    onError: onError,
  }
}
export function completeMonitorSheetByIdSuccess(payload) {
  return {
    type: COMPLETE_MONITOR_SHEET_SUCCESS,
    payload: payload,
  }
}

export function completeMonitorSheetByIdFailed() {
  return {
    type: COMPLETE_MONITOR_SHEET_FAILED,
  }
}

export function resetMonitorSheetState() {
  return {
    type: RESET_MONITOR_SHEET_DETAILS_STATE,
  }
}

export default {
  getMonitorSheetDetailsById,
  getMonitorSheetDetailsByIdSuccess,
  getMonitorSheetDetailsByIdFailed,
  resetMonitorSheetState,
  submitStartTimeMonitorSheetById,
  submitStartTimeMonitorSheetByIdSuccess,
  submitStartTimeMonitorSheetByIdFailed,
  submitSignMonitorSheetById,
  submitSignMonitorSheetByIdSuccess,
  submitSignMonitorSheetByIdFailed,
  completeMonitorSheetById,
  completeMonitorSheetByIdSuccess,
  completeMonitorSheetByIdFailed,
}
