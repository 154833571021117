import { call, put, takeLatest } from 'redux-saga/effects'

import { NOTIFICATION_TYPE } from '~/common/constants'
import {
  signCustomerFailed,
  signCustomerSuccess,
  SIGN_CUSTOMER_START,
} from '~/modules/wmsx/redux/actions/warehouse-import-receipt'
import { api } from '~/services/api'
import addNotification from '~/utils/toast'

const signCustomerApi = (params) => {
  const uri = `/monitor-orders/${params?.id}/submit-client-signature`
  const data = { ...params }
  delete data['id']
  return api.put(uri, data)
}

function* doSignCustomer(action) {
  try {
    const response = yield call(signCustomerApi, action?.payload)

    if (response?.statusCode === 200) {
      yield put(signCustomerSuccess(response.payload))

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess()
      }

      addNotification(response?.message, NOTIFICATION_TYPE.SUCCESS)
    } else {
      addNotification(
        response?.message || response?.statusText,
        NOTIFICATION_TYPE.ERROR,
      )

      throw new Error(response?.message)
    }
  } catch (error) {
    yield put(signCustomerFailed())
    // Call callback action if provided
    if (action.onError) {
      yield action.onError()
    }
  }
}

export default function* watchSignCustomer() {
  yield takeLatest(SIGN_CUSTOMER_START, doSignCustomer)
}
