import { call, put, takeLatest } from 'redux-saga/effects'

import {
  searchRouteFailed,
  searchRouteSuccess,
  SEARCH_ROUTE_START,
} from '~/modules/wmsx/redux/actions/warehouse-import-receipt'
import { api } from '~/services/api'

export const searchRouteApi = (params) => {
  const uri = `/routes`
  return api.get(uri, params)
}
function* doSearchRoute(action) {
  try {
    const response = yield call(searchRouteApi, action?.payload)

    if (response?.statusCode === 200) {
      const payload = {
        list: response.data.items,
        total: response.data.meta.total,
      }
      yield put(searchRouteSuccess(payload))

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess(payload)
      }
    } else {
      throw new Error(response?.message)
    }
  } catch (error) {
    yield put(searchRouteFailed())
    // Call callback action if provided
    if (action.onError) {
      yield action.onError()
    }
  }
}

export default function* watchSearchRoute() {
  yield takeLatest(SEARCH_ROUTE_START, doSearchRoute)
}
