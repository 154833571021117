import React, { useEffect, useState } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import { Grid, Typography, IconButton } from '@mui/material'
import DialogMui from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import { useParams } from 'react-router-dom'

import { NOTIFICATION_TYPE } from '~/common/constants'
import Button from '~/components/Button'
import Icon from '~/components/Icon'
import LV from '~/components/LabelValue'
import Page from '~/components/Page'
import useMonitorSheet from '~/modules/wmsx/redux/hooks/useMonitorSheet'
import { ROUTE } from '~/modules/wmsx/routes/config'
import { convertUtcDateTimeToLocalTz } from '~/utils'
import addNotification from '~/utils/toast'

import { useWindowDimensions } from '../../get-size-sreen'
import Sign from '../../warehouse-import-receipt/detail/sign'

const breadcrumbs = [
  {
    route: ROUTE.MONITOR_SHEET.DETAIL.PATH,
    title: ROUTE.MONITOR_SHEET.DETAIL.TITLE,
  },
]
function MonitorSheetDetail() {
  const { id } = useParams()
  const canvasRef = React.createRef(null)
  const [saveData, setSaveData] = React.useState('')
  const sizeScreen = useWindowDimensions()
  const {
    data: { monitorSheetDetails, isLoading },
    actions,
  } = useMonitorSheet()

  const [modal, setModal] = useState({
    tempItem: null,
    isClientSignModal: false,
    isDriver1SignModal: false,
    isDriver2SignModal: false,
  })
  useEffect(() => {
    actions.getMonitorSheetDetailsById(id)
    return () => {
      actions.resetMonitorSheetState()
    }
  }, [id])
  const onClickClientSign = () => {
    setModal({ isClientSignModal: true })
  }
  const onClickDriver1Sign = () => {
    setModal({ isDriver1SignModal: true })
  }
  const onClickDriver2Sign = () => {
    setModal({ isDriver2SignModal: true })
  }
  const onCloseModalModal = () => {
    setModal({
      tempItem: null,
      isClientSignModal: false,
      isDriver1SignModal: false,
      isDriver2SignModal: false,
    })
  }

  const onSubmitStartTime = () => {
    actions.submitStartTimeMonitorSheetById(id, () => {
      actions.getMonitorSheetDetailsById(id)
    })
  }

  const onCompleteMonitorSheet = () => {
    actions.completeMonitorSheetById(id, () => {
      let winOpen = window.open('', '_top')
      winOpen.location = '/monitor-order/completed'
    })
  }

  const handleClickSign = (type) => {
    if (!saveData) {
      addNotification('Vui lòng ký tên', NOTIFICATION_TYPE.ERROR)
    } else {
      actions.submitSignMonitorSheetById(
        { id: +id, signature: saveData, type },
        () => {
          actions.getMonitorSheetDetailsById(id)
          setModal({ isClientSignModal: false })
        },
      )
    }
  }

  const renderHeaderRight = () => {
    return (
      <>
        {monitorSheetDetails.status === 1 && monitorSheetDetails.start && (
          <Button
            onClick={() => onClickClientSign()}
            sx={{
              ml: 4 / 3,
              mt: 1,
            }}
          >
            KH ký tên (用车人签字)
          </Button>
        )}
        {monitorSheetDetails.status === 1 && monitorSheetDetails.start && (
          <Button
            onClick={() => onClickDriver1Sign()}
            sx={{ ml: 4 / 3, mt: 1 }}
          >
            L.Xe 1 ký tên
          </Button>
        )}
        {monitorSheetDetails.status === 1 && monitorSheetDetails.start && (
          <Button
            sx={{ ml: 4 / 3, mt: 1 }}
            onClick={() => onClickDriver2Sign()}
          >
            L.Xe 2 ký tên
          </Button>
        )}

        {monitorSheetDetails.clientSignature && monitorSheetDetails.start && (
          <Button
            onClick={() => onCompleteMonitorSheet()}
            sx={{ ml: 4 / 3, mt: 1 }}
          >
            Hoàn thành
          </Button>
        )}
      </>
    )
  }
  return (
    <Page
      breadcrumbs={breadcrumbs}
      title={`Chi tiết phiếu giám sát xe`}
      renderHeaderRight={renderHeaderRight}
      loading={isLoading}
    >
      <Grid container justifyContent="center">
        <Grid item xl={11} xs={12}>
          <Grid container rowSpacing={4 / 3} columnSpacing={{ xl: 8, xs: 4 }}>
            <Grid item xs={12}>
              <Typography align="center" fontSize={24}>
                {monitorSheetDetails?.companyVI}
              </Typography>
              <Typography align="center" fontSize={24}>
                {monitorSheetDetails?.companyCN}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <LV
                mobile={sizeScreen?.width <= 600 ? true : false}
                label="Giờ khởi hành"
                value={
                  convertUtcDateTimeToLocalTz(monitorSheetDetails?.start) || (
                    <Button onClick={() => onSubmitStartTime()}>Bắt đầu</Button>
                  )
                }
              />
            </Grid>
            <Grid item xs={12}>
              <LV
                mobile={sizeScreen?.width <= 600 ? true : false}
                label="Loại xe"
                value={monitorSheetDetails?.vehicleType}
              />
            </Grid>
            <Grid item xs={12}>
              <LV
                mobile={sizeScreen?.width <= 600 ? true : false}
                label="Biển xe"
                value={monitorSheetDetails?.vehicleNumber}
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <LV
                mobile={sizeScreen?.width <= 600 ? true : false}
                label="Từ"
                value={
                  monitorSheetDetails?.type === 0
                    ? monitorSheetDetails?.line?.fromVI
                    : monitorSheetDetails?.line?.toVI
                }
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <LV
                mobile={sizeScreen?.width <= 600 ? true : false}
                label="Đến"
                value={
                  monitorSheetDetails?.type === 0
                    ? monitorSheetDetails?.line?.toVI
                    : monitorSheetDetails?.line?.fromVI
                }
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <LV
                mobile={sizeScreen?.width <= 600 ? true : false}
                label="從"
                value={
                  monitorSheetDetails?.type === 1
                    ? monitorSheetDetails?.line?.fromCN
                    : monitorSheetDetails?.line?.toCN
                }
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <LV
                mobile={sizeScreen?.width <= 600 ? true : false}
                label="到達"
                value={
                  monitorSheetDetails?.type === 1
                    ? monitorSheetDetails?.line?.toCN
                    : monitorSheetDetails?.line?.fromCN
                }
              />
            </Grid>

            <Grid item xs={12}>
              <LV
                mobile={sizeScreen?.width <= 600 ? true : false}
                label="Chữ ký KH"
                value={
                  monitorSheetDetails?.clientSignature ? (
                    <img
                      width={120}
                      height={80}
                      src={monitorSheetDetails?.clientSignature}
                      alt="Chữ ký KH"
                    />
                  ) : (
                    ''
                  )
                }
              />
            </Grid>
            <Grid item xs={12}>
              <LV
                mobile={sizeScreen?.width <= 600 ? true : false}
                label="Chữ ký tài xế 1"
                value={
                  monitorSheetDetails?.signatureDriver1 ? (
                    <img
                      width={120}
                      height={80}
                      src={monitorSheetDetails?.signatureDriver1}
                      alt="Chữ ký tài xế 1"
                    />
                  ) : (
                    ''
                  )
                }
              />
            </Grid>
            <Grid item xs={12}>
              <LV
                mobile={sizeScreen?.width <= 600 ? true : false}
                label="Chữ ký tài xế 2"
                value={
                  monitorSheetDetails?.signatureDriver2 ? (
                    <img
                      width={120}
                      height={80}
                      src={monitorSheetDetails?.signatureDriver2}
                      alt="Chữ ký tài xế 2"
                    />
                  ) : (
                    ''
                  )
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <DialogMui
        open={modal.isClientSignModal}
        title="Khách hàng vui lòng ký tên"
        onClose={onCloseModalModal}
        fullScreen
      >
        <DialogTitle id="alert-dialog-title">
          {'Khách hàng vui lòng ký tên'}
          {onCloseModalModal ? (
            <IconButton
              aria-label="close"
              onClick={onCloseModalModal}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>

        <Sign
          canvasRef={canvasRef}
          saveData={saveData}
          setSaveData={setSaveData}
        />

        <DialogActions sx={{ ml: 2 }}>
          <Button onClick={() => canvasRef.current.undo()} variant="outlined">
            {' '}
            <Icon name={'back'} />
          </Button>
          <Button
            onClick={() => {
              canvasRef.current.clear()
              setSaveData('')
            }}
            variant="outlined"
          >
            {' '}
            <Icon name="delete" />
          </Button>
          <Button
            autoFocus
            onClick={() => handleClickSign(0)}
            variant="outlined"
          >
            {' '}
            <Icon name="tick" />
          </Button>
        </DialogActions>
      </DialogMui>

      <DialogMui
        open={modal.isDriver1SignModal}
        title="Lái xe 1 vui lòng ký tên"
        onClose={onCloseModalModal}
        fullScreen
      >
        <DialogTitle id="alert-dialog-title">
          {'Lái xe 1 vui lòng ký tên'}
          {onCloseModalModal ? (
            <IconButton
              aria-label="close"
              onClick={onCloseModalModal}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>

        <Sign
          canvasRef={canvasRef}
          saveData={saveData}
          setSaveData={setSaveData}
        />

        <DialogActions sx={{ ml: 2 }}>
          <Button onClick={() => canvasRef.current.undo()} variant="outlined">
            {' '}
            <Icon name={'back'} />
          </Button>
          <Button
            onClick={() => {
              canvasRef.current.clear()
              setSaveData('')
            }}
            variant="outlined"
          >
            {' '}
            <Icon name="delete" />
          </Button>
          <Button
            autoFocus
            onClick={() => handleClickSign(1)}
            variant="outlined"
          >
            {' '}
            <Icon name="tick" />
          </Button>
        </DialogActions>
      </DialogMui>

      <DialogMui
        open={modal.isDriver2SignModal}
        title="Lái xe 2 vui lòng ký tên"
        onClose={onCloseModalModal}
        fullScreen
      >
        <DialogTitle id="alert-dialog-title">
          {'Lái xe 2 vui lòng ký tên'}
          {onCloseModalModal ? (
            <IconButton
              aria-label="close"
              onClick={onCloseModalModal}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>

        <Sign
          canvasRef={canvasRef}
          saveData={saveData}
          setSaveData={setSaveData}
        />

        <DialogActions sx={{ ml: 2 }}>
          <Button onClick={() => canvasRef.current.undo()} variant="outlined">
            {' '}
            <Icon name={'back'} />
          </Button>
          <Button
            onClick={() => {
              canvasRef.current.clear()
              setSaveData('')
            }}
            variant="outlined"
          >
            {' '}
            <Icon name="delete" />
          </Button>
          <Button
            autoFocus
            onClick={() => handleClickSign(2)}
            variant="outlined"
          >
            {' '}
            <Icon name="tick" />
          </Button>
        </DialogActions>
      </DialogMui>
    </Page>
  )
}
export default MonitorSheetDetail
