import { useEffect, useState } from 'react'

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { Box, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'

import ActionBar from '~/components/ActionBar'
import Button from '~/components/Button'
import Dialog from '~/components/Dialog'
import LV from '~/components/LabelValue'
import Page from '~/components/Page'
import Status from '~/components/Status'
import {
  ROUTE_MANAGEMENT_OPTIONS,
  ROUTE_MANAGEMENT_STATUS,
} from '~/modules/wmsx/constants'
import useGroupMonitorOrder from '~/modules/wmsx/redux/hooks/useGroupMonitorOrder'
import { ROUTE } from '~/modules/wmsx/routes/config'

import ItemSettingTable from './item-seting-table'
const breadcrumbs = [
  {
    route: ROUTE.GROUP_MONITOR_ORDER.LIST.PATH,
    title: ROUTE.GROUP_MONITOR_ORDER.LIST.TITLE,
  },
  {
    route: ROUTE.GROUP_MONITOR_ORDER.DETAIL.PATH,
    title: ROUTE.GROUP_MONITOR_ORDER.DETAIL.TITLE,
  },
]
function GroupMonitorOrderDetail() {
  const history = useHistory()
  const { id } = useParams()
  const { t } = useTranslation('wmsx')
  const {
    data: { groupMonitorOrderDetails, isLoading },
    actions,
  } = useGroupMonitorOrder()

  const [modal, setModal] = useState({
    tempItem: null,
    isOpenDeleteModal: false,
    isOpenConfirmModal: false,
  })
  useEffect(() => {
    actions.getGroupMonitorOrderDetailsById(id)
    return () => {
      actions.resetGroupMonitorOrderState()
    }
  }, [id])
  const backToList = () => {
    history.push(ROUTE.GROUP_MONITOR_ORDER.LIST.PATH)
  }
  const onClickDelete = () => {
    setModal({ isOpenDeleteModal: true })
  }
  const onClickConfirm = () => {
    setModal({ isOpenConfirmModal: true })
  }
  const onSubmitDelete = () => {
    actions.deleteGroupMonitorOrder(id, () => {
      onCloseDeleteModal()
      backToList()
    })
  }
  const onSubmitConfirm = () => {
    actions.confirmGroupMonitorOrderById(id, () => {
      onCloseDeleteModal()
      actions.getGroupMonitorOrderDetailsById(id)
    })
  }
  const onCloseDeleteModal = () => {
    setModal({
      isOpenDeleteModal: false,
      tempItem: null,
      isOpenConfirmModal: false,
    })
  }
  const renderHeaderRight = () => {
    const isDelete =
      groupMonitorOrderDetails.status === ROUTE_MANAGEMENT_STATUS.PENDING
    return (
      <>
        {isDelete && (
          <Button
            onClick={() => onClickDelete()}
            sx={{
              ml: 4 / 3,
            }}
            color="error"
          >
            Xoá
          </Button>
        )}
        {isDelete && (
          <Button onClick={() => onClickConfirm()} sx={{ ml: 4 / 3 }}>
            Xác nhận
          </Button>
        )}

        <Button
          sx={{ ml: 4 / 3 }}
          onClick={() =>
            history.push(
              ROUTE.GROUP_MONITOR_ORDER.EDIT.PATH.replace(':id', `${id}`),
            )
          }
        >
          Sửa nhóm giám sát xe
        </Button>
        <Button
          sx={{ ml: 4 / 3 }}
          onClick={() =>
            history.push(
              ROUTE.GROUP_MONITOR_ORDER.PRINT.PATH.replace(':id', `${id}`),
            )
          }
          icon="print"
        >
          In phiếu
        </Button>
      </>
    )
  }
  const items = groupMonitorOrderDetails?.monitorSheets?.map((item, index) => ({
    ...item,
    date: new Date(item?.date),
    index: index,
    time: item?.start,
    lineId: item?.line || '周日',
    type: item?.type,
    vehicleType: item?.vehicleType,
    vehicleNumber: item?.vehicleNumber,
    note: item?.note,
  })) || [{}]
  return (
    <Page
      breadcrumbs={breadcrumbs}
      title={`Chi tiết nhóm giám sát xe`}
      onBack={backToList}
      renderHeaderRight={renderHeaderRight}
      loading={isLoading}
    >
      <Grid container justifyContent="center">
        <Grid item xl={11} xs={12}>
          <Grid container rowSpacing={4 / 3} columnSpacing={{ xl: 8, xs: 4 }}>
            <Grid item xs={12}>
              <LV
                label="Trạng thái"
                value={
                  <Status
                    options={ROUTE_MANAGEMENT_OPTIONS}
                    value={groupMonitorOrderDetails?.status}
                  />
                }
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <LV label="Công ty" value={groupMonitorOrderDetails?.companyVI} />
            </Grid>
            <Grid item lg={6} xs={12}>
              <LV label="公司 " value={groupMonitorOrderDetails?.companyCN} />
            </Grid>
            <Grid item lg={6} xs={12}>
              <LV
                label="Tuyến đường"
                value={
                  <Box sx={{ mt: -1 }}>
                    <Typography sx={{ display: 'flex' }}>
                      <Box>{groupMonitorOrderDetails?.line?.fromCN}</Box>
                      <ArrowRightAltIcon
                        fontSize="medium"
                        sx={{ ml: 1, mr: 1 }}
                      />
                      <Box>{groupMonitorOrderDetails?.line?.toCN}</Box>
                    </Typography>
                    <Typography sx={{ display: 'flex' }}>
                      <Box>{groupMonitorOrderDetails?.line?.fromVI}</Box>
                      <ArrowRightAltIcon
                        fontSize="medium"
                        sx={{ ml: 1, mr: 1 }}
                      />
                      <Box>{groupMonitorOrderDetails?.line?.toVI}</Box>
                    </Typography>
                  </Box>
                }
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <LV
                label="Ngày Tháng"
                value={
                  groupMonitorOrderDetails?.month
                    ? `Tháng ${groupMonitorOrderDetails?.month} Năm ${groupMonitorOrderDetails?.year}`
                    : ''
                }
              />
            </Grid>
          </Grid>
          <Box sx={{ mt: 2 }}>
            <ItemSettingTable items={items || []} id={id} />
          </Box>
        </Grid>
      </Grid>
      <ActionBar onBack={backToList} />
      <Dialog
        open={modal.isOpenDeleteModal}
        title="Xoá nhóm phiếu giám sát xe"
        onCancel={onCloseDeleteModal}
        cancelLabel={t('general:common.no')}
        onSubmit={onSubmitDelete}
        submitLabel={t('general:common.yes')}
        submitProps={{
          color: 'error',
        }}
        noBorderBottom
      >
        Bạn có chắc chắn muốn xoá nhóm phiếu giám sát xe này không?
      </Dialog>
      <Dialog
        open={modal.isOpenConfirmModal}
        title="Xác nhận nhóm phiếu giám sát xe"
        onCancel={onCloseDeleteModal}
        cancelLabel={t('general:common.no')}
        onSubmit={onSubmitConfirm}
        submitLabel={t('general:common.yes')}
        noBorderBottom
      >
        Bạn có chắc chắn muốn xác nhận nhóm phiếu giám sát xe này không?
      </Dialog>
    </Page>
  )
}
export default GroupMonitorOrderDetail
