import React, { useMemo } from 'react'

import { Box, Typography } from '@mui/material'

import DataTable from '~/components/DataTable'
import { Field } from '~/components/Formik'

const ItemSettingTable = (props) => {
  const { items } = props
  const getColumns = useMemo(
    () => [
      {
        field: 'id',
        headerName: '#',
        width: 10,
        align: 'center',
        renderCell: (_, index) => {
          return index + 1
        },
      },
      {
        field: 'route',
        headerName: 'Tuyến đường cố định(固定路线)',
        width: 50,
        align: 'left',
        renderCell: (params) => {
          return (
            <Box>
              <Typography>{params?.row?.route?.nameCN}</Typography>
              <Typography>{params?.row?.route?.nameVI}</Typography>
            </Box>
          )
        },
      },
      {
        field: 'oneWay',
        headerName: 'Một chiều(单程)',
        width: 50,
        align: 'center',
        renderCell: (params, index) => {
          return <Field.Checkbox name={`items[${index}].oneWay`} />
        },
      },
      {
        field: 'roundTrip',
        headerName: 'Khứ hồi(往返)',
        width: 50,
        align: 'center',
        renderCell: (params, index) => {
          return <Field.Checkbox name={`items[${index}].roundTrip`} />
        },
      },
    ],
    [items],
  )
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <Typography variant="h4">Tuyến đường cố định</Typography>
        <Box></Box>
      </Box>
      <DataTable
        rows={items}
        columns={getColumns}
        hideSetting
        hideFooter
        striped={false}
        isTableResizable={true}
        enableResizable={false}
      />
    </>
  )
}

export default ItemSettingTable
