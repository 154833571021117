import { call, put, takeLatest } from 'redux-saga/effects'

import {
  searchWarehouseImportReceiptFailed,
  searchWarehouseImportReceiptSuccess,
  SEARCH_WAREHOUSE_IMPORT_RECEIPT_START,
} from '~/modules/wmsx/redux/actions/warehouse-import-receipt'
import { api } from '~/services/api'
import { format } from 'date-fns'

export const exportMonitorOrders = async (params) => {
  const fileName = `Phieu_giam_sat_xe_${format(new Date(), 'yyyyMMddHHmmss')}`
  const uri = `/monitor-orders/export`
  const res = await api.get(uri, params, {
    responseType: 'blob',
  })

  try {
    const a = document.createElement('a')
    a.style.display = 'none'
    document.body.appendChild(a)

    const blobFile = new Blob([res], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const url = window.URL.createObjectURL(blobFile)
    a.href = url
    a.download = fileName
    a.click()
    window.URL.revokeObjectURL(url)
  } catch (error) {
    console.log('error', error)
  }
}

export const searchWarehouseImportReceiptApi = (params) => {
  const uri = `/monitor-orders`
  return api.get(uri, params)
}
export const searchContactApi = (params) => {
  const uri = `/contracts`
  return api.get(uri, params)
}
function* doSearchWarehouseImportReceipt(action) {
  try {
    const response = yield call(
      searchWarehouseImportReceiptApi,
      action?.payload,
    )

    if (response?.statusCode === 200) {
      const payload = {
        list: response.data.items,
        total: response.data.meta.total,
      }
      yield put(searchWarehouseImportReceiptSuccess(payload))

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess(payload)
      }
    } else {
      throw new Error(response?.message)
    }
  } catch (error) {
    yield put(searchWarehouseImportReceiptFailed())
    // Call callback action if provided
    if (action.onError) {
      yield action.onError()
    }
  }
}

export default function* watchSearchWarehouseImportReceipt() {
  yield takeLatest(
    SEARCH_WAREHOUSE_IMPORT_RECEIPT_START,
    doSearchWarehouseImportReceipt,
  )
}
