import { all } from 'redux-saga/effects'

import watchConfirmGroupMonitorOrder from './group-monitor-order/confirm'
import watchCreateGroupMonitorOrder from './group-monitor-order/create'
import watchDeleteGroupMonitorOrder from './group-monitor-order/delete'
import watchGetGroupMonitorOrderDetails from './group-monitor-order/get-details'
import watchSearchGroupMonitorOrder from './group-monitor-order/search'
import watchToSignGroupMonitorOrder from './group-monitor-order/to-sign'
import watchUpdateGroupMonitorOrder from './group-monitor-order/update'
import watchCompleteMonitorSheet from './monitor-sheet/complete'
import watchGetMonitorSheetDetails from './monitor-sheet/get-details'
import watchSubmitSignatureMonitorSheet from './monitor-sheet/submit-sign'
import watchSubmitStartTime from './monitor-sheet/submit-start-time'
import watchConfirmRouteManagement from './route-management/confirm'
import watchCreateRouteManagement from './route-management/create'
import watchDeleteRouteManagement from './route-management/delete'
import watchGetRouteManagementDetails from './route-management/get-details'
import watchSearchRouteManagement from './route-management/search'
import watchUpdateRouteManagement from './route-management/update'
import watchCancelWarehouseImportEBS from './warehouse-import-receipt/cancelEBS'
import watchcompletedOrder from './warehouse-import-receipt/complete-order'
import watchConfirmWarehouseImportReceipt from './warehouse-import-receipt/confirm'
import watchConfirmWarehouseImportEBS from './warehouse-import-receipt/confirmEBS'
import watchCreateWarehouseImportReceipt from './warehouse-import-receipt/create'
import watchDeleteWarehouseImportReceipt from './warehouse-import-receipt/delete'
import watchGetAttribuiteBusinessTypeDetails from './warehouse-import-receipt/get-attribute-business-type'
import watchGetWarehouseImportReceiptDetails from './warehouse-import-receipt/get-details'
import watchImportWarehouse from './warehouse-import-receipt/import-warehouse'
import watchReceiveWarehouse from './warehouse-import-receipt/receive'
import watchRejectWarehouseImportReceipt from './warehouse-import-receipt/reject'
import watchResetPassword from './warehouse-import-receipt/reset-password'
import watchRetry from './warehouse-import-receipt/retry'
import watchReturnWarehouseImportReceipt from './warehouse-import-receipt/return'
import watchSearchWarehouseImportReceipt from './warehouse-import-receipt/search'
import watchSearchRoute from './warehouse-import-receipt/search-route'
import watchSeenToDriver from './warehouse-import-receipt/seenToDriver'
import watchSignCustomer from './warehouse-import-receipt/sign-customer'
import watchSignDriver from './warehouse-import-receipt/sign-driver'
import watchStoredWarehouse from './warehouse-import-receipt/stored'
import watchsuggestLocators from './warehouse-import-receipt/suggesr-locator'
import watchUpdateWarehouseImportReceipt from './warehouse-import-receipt/update'
import watchUpdateHeaderWarehouseImportReceipt from './warehouse-import-receipt/update-header'

/**
 * Root saga
 */
export default function* sagas() {
  yield all([
    //warehouse-import-receipt
    watchSearchWarehouseImportReceipt(),
    watchCreateWarehouseImportReceipt(),
    watchUpdateWarehouseImportReceipt(),
    watchGetWarehouseImportReceiptDetails(),
    watchDeleteWarehouseImportReceipt(),
    watchConfirmWarehouseImportReceipt(),
    watchRejectWarehouseImportReceipt(),
    watchGetAttribuiteBusinessTypeDetails(),
    watchImportWarehouse(),
    watchConfirmWarehouseImportEBS(),
    watchCancelWarehouseImportEBS(),
    watchReturnWarehouseImportReceipt(),
    watchUpdateHeaderWarehouseImportReceipt(),
    watchReceiveWarehouse(),
    watchStoredWarehouse(),
    watchsuggestLocators(),
    watchSeenToDriver(),
    watchRetry(),
    watchSignCustomer(),
    watchSignDriver(),
    watchcompletedOrder(),
    watchResetPassword(),
    watchSearchRoute(),
    //route
    watchConfirmRouteManagement(),
    watchCreateRouteManagement(),
    watchDeleteRouteManagement(),
    watchGetRouteManagementDetails(),
    watchSearchRouteManagement(),
    watchUpdateRouteManagement(),
    ///
    watchSearchGroupMonitorOrder(),
    watchCreateGroupMonitorOrder(),
    watchUpdateGroupMonitorOrder(),
    watchGetGroupMonitorOrderDetails(),
    watchConfirmGroupMonitorOrder(),
    watchDeleteGroupMonitorOrder(),
    watchToSignGroupMonitorOrder(),
    //
    watchGetMonitorSheetDetails(),
    watchSubmitStartTime(),
    watchSubmitSignatureMonitorSheet(),
    watchCompleteMonitorSheet(),
  ])
}
