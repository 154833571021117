import React, { useMemo, useState } from 'react'

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { Box, Typography } from '@mui/material'
import * as copyToClipboard from 'copy-to-clipboard'
import { useTranslation } from 'react-i18next'

import { NOTIFICATION_TYPE } from '~/common/constants'
import Button from '~/components/Button'
import DataTable from '~/components/DataTable'
import Dialog from '~/components/Dialog'
import Icon from '~/components/Icon'
import IconButton from '~/components/IconButton'
import {
  GROUP_MONITOR_ORDER_STATUS,
  ROUTE_MANAGEMENT_STATUS,
} from '~/modules/wmsx/constants'
import useGroupMonitorOrder from '~/modules/wmsx/redux/hooks/useGroupMonitorOrder'
import { convertUtcDateToLocalTz } from '~/utils'
import addNotification from '~/utils/toast'

const ItemSettingTable = (props) => {
  const { items, id } = props
  const { t } = useTranslation('wmsx')
  const [modal, setModal] = useState({
    tempItem: null,
    isOpenSeenModal: false,
    isOpenRetryModal: false,
    isOpenCopyModal: false,
  })
  const {
    data: { groupMonitorOrderDetails },
    actions,
  } = useGroupMonitorOrder()
  const getColumns = useMemo(
    () => [
      {
        field: '#',
        headerName: 'STT',
        width: 50,
        align: 'center',
        renderCell: (_, index) => {
          return index + 1
        },
      },
      // {
      //   field: 'time',
      //   headerName: 'Thời gian',
      //   width: 100,
      //   align: 'center',
      //   renderCell: (params) => {
      //     return convertUtcDateTimeToLocalTz(params?.row?.time)
      //   },
      // },
      {
        field: 'date',
        headerName: 'Ngày tháng',
        width: 100,
        align: 'center',
        renderCell: (params) => {
          const days = [
            'Chủ nhật',
            'Thứ hai',
            'Thứ ba',
            'Thứ tư',
            'Thứ năm',
            'Thứ sáu',
            'Thứ bảy',
          ]
          var day = new Date(params?.row?.date)

          return (
            <>
              {days[day.getDay()]}
              <br />
              {convertUtcDateToLocalTz(params?.row?.date)}
            </>
          )
        },
      },
      {
        field: 'lineId',
        headerName: 'Tuyến đường',
        width: 250,
        align: 'center',
        renderCell: (params) => {
          return params?.row?.type === 0 ? (
            <>
              <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box>{params?.row?.lineId?.fromCN}</Box>
                <ArrowRightAltIcon fontSize="medium" />
                <Box>{params?.row?.lineId?.toCN}</Box>
              </Typography>
              <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box>{params?.row?.lineId?.fromVI}</Box>
                <ArrowRightAltIcon fontSize="medium" />
                <Box>{params?.row?.lineId?.toVI}</Box>
              </Typography>
            </>
          ) : params?.row?.type === 1 ? (
            <>
              <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box>{params?.row?.lineId?.toCN}</Box>
                <ArrowRightAltIcon fontSize="medium" />
                <Box>{params?.row?.lineId?.fromCN}</Box>
              </Typography>
              <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box>{params?.row?.lineId?.toVI}</Box>
                <ArrowRightAltIcon fontSize="medium" />
                <Box>{params?.row?.lineId?.fromVI}</Box>
              </Typography>
            </>
          ) : (
            params?.row?.lineId
          )
        },
      },
      {
        field: 'vehicleType',
        headerName: 'Loại xe',
        width: 150,
        align: 'center',
        renderCell: (params) => {
          return params?.row?.vehicleType
        },
      },
      {
        field: 'vehicleNumber',
        headerName: 'Biển xe',
        width: 150,
        align: 'center',
        renderCell: (params) => {
          return params?.row?.vehicleNumber
        },
      },
      {
        field: 'clientSignature',
        headerName: 'Diện 1 ký tên',
        width: 120,
        align: 'center',
        renderCell: (params) => {
          return params?.row?.clientSignature ? (
            <img
              width={120}
              height={80}
              src={params?.row.clientSignature}
              alt="Lái xe ký tên"
            />
          ) : (
            ''
          )
        },
      },
      {
        field: 'signatureDriver1',
        headerName: 'Lái xe 1 ký ',
        width: 120,
        align: 'center',
        renderCell: (params) => {
          return params?.row?.signatureDriver1 ? (
            <img
              width={120}
              height={80}
              src={params?.row?.signatureDriver1}
              alt="Lái xe ký tên"
            />
          ) : (
            ''
          )
        },
      },
      {
        field: 'signatureDriver2',
        headerName: 'Lái xe 2 ký ',
        width: 120,
        align: 'center',
        renderCell: (params) => {
          return params?.row?.signatureDriver2 ? (
            <img
              width={120}
              height={80}
              src={params?.row?.signatureDriver2}
              alt="Lái xe ký tên"
            />
          ) : (
            ''
          )
        },
      },
      {
        field: 'note',
        headerName: 'Ghi chú',
        width: 150,
        align: 'center',
        renderCell: (params) => {
          return params?.row?.note
        },
      },
      {
        field: 'actions',
        headerName: 'Hành động',
        width: 100,
        align: 'center',
        sticky: 'right',
        resizable: false,
        hide:
          groupMonitorOrderDetails?.status === ROUTE_MANAGEMENT_STATUS.PENDING,
        renderCell: (params) => {
          const seenDriver =
            params?.row?.status === GROUP_MONITOR_ORDER_STATUS.PENDING
          const retry =
            params?.row?.status === GROUP_MONITOR_ORDER_STATUS.SIGNED
          const copy =
            params?.row?.status === GROUP_MONITOR_ORDER_STATUS.WAITTING_SIGN
          const checkDay = new Date(params?.row?.date).getDay() !== 0
          return (
            <div>
              {seenDriver && checkDay && (
                <IconButton
                  title="Gửi cho lái xe"
                  onClick={() =>
                    actions.toSignGroupMonitorOrderById(params?.row?.id, () => {
                      actions.getGroupMonitorOrderDetailsById(id)
                    })
                  }
                >
                  <Icon name="assign" />
                </IconButton>
              )}
              {retry && checkDay && (
                <IconButton
                  title="Yêu cầu ký lại"
                  onClick={() =>
                    actions.toSignGroupMonitorOrderById(params?.row?.id, () => {
                      actions.getGroupMonitorOrderDetailsById(id)
                    })
                  }
                >
                  <Icon name="retrySyncReceipt" />
                </IconButton>
              )}
              {copy && checkDay && (
                <IconButton
                  title="Sao chép link"
                  onClick={() => onClickCopy(params.row)}
                >
                  <Icon name="clone" />
                </IconButton>
              )}
            </div>
          )
        },
      },
    ],
    [items],
  )
  const onClickCopy = (tempItem) => {
    setModal({ tempItem, isOpenCopyModal: true })
  }
  const onCloseDeleteModal = () => {
    setModal({
      tempItem: null,
      // isOpenSeenModal: false,
      // isOpenRetryModal: false,
      isOpenCopyModal: false,
    })
  }
  const copy = async () => {
    const text =
      window.location.protocol +
      '//' +
      window.location.host +
      '/monitor-sheet/' +
      modal?.tempItem?.id +
      '/detail'
    copyToClipboard(text)
    addNotification('Sao chép link thành công', NOTIFICATION_TYPE.SUCCESS)
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <Typography variant="h4">Danh sách biểu ký tên đi xe ô tô</Typography>
        <Box></Box>
      </Box>
      <DataTable
        rows={items}
        columns={getColumns}
        hideSetting
        hideFooter
        striped={false}
        enableResizable={false}
        size="medium"
      />
      <Dialog
        open={modal.isOpenCopyModal}
        title="Gửi cho lái xe"
        onCancel={onCloseDeleteModal}
        cancelLabel={t('general:common.close')}
        noBorderBottom
      >
        <Box sx={{ display: 'flex' }}>
          <Typography variant="h4" id="myBox">
            {window.location.protocol +
              '//' +
              window.location.host +
              '/monitor-sheet/' +
              modal?.tempItem?.id +
              '/detail'}
          </Typography>
          <Button sx={{ ml: 2, mt: -1, borderRadius: '8px' }} onClick={copy}>
            Sao chép
          </Button>
        </Box>
      </Dialog>
    </>
  )
}

export default ItemSettingTable
