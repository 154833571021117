import {
  CREATE_ROUTE_MANAGEMENT_FAILED,
  CREATE_ROUTE_MANAGEMENT_START,
  CREATE_ROUTE_MANAGEMENT_SUCCESS,
  DELETE_ROUTE_MANAGEMENT_FAILED,
  DELETE_ROUTE_MANAGEMENT_START,
  DELETE_ROUTE_MANAGEMENT_SUCCESS,
  GET_ROUTE_MANAGEMENT_DETAILS_FAILED,
  GET_ROUTE_MANAGEMENT_DETAILS_START,
  GET_ROUTE_MANAGEMENT_DETAILS_SUCCESS,
  SEARCH_ROUTE_MANAGEMENT_FAILED,
  SEARCH_ROUTE_MANAGEMENT_START,
  SEARCH_ROUTE_MANAGEMENT_SUCCESS,
  UPDATE_ROUTE_MANAGEMENT_FAILED,
  UPDATE_ROUTE_MANAGEMENT_START,
  UPDATE_ROUTE_MANAGEMENT_SUCCESS,
  CONFIRM_ROUTE_MANAGEMENT_FAILED,
  CONFIRM_ROUTE_MANAGEMENT_START,
  CONFIRM_ROUTE_MANAGEMENT_SUCCESS,
  RESET_STATE_ROUTE_MANAGEMENT,
} from '~/modules/wmsx/redux/actions/route-management'

const initialState = {
  isLoading: false,
  routeManagementList: [],
  routeManagementDetails: {},
  total: null,
}

/**
 * Sample reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function routeManagement(state = initialState, action) {
  switch (action.type) {
    case SEARCH_ROUTE_MANAGEMENT_START:
    case CREATE_ROUTE_MANAGEMENT_START:
    case UPDATE_ROUTE_MANAGEMENT_START:
    case DELETE_ROUTE_MANAGEMENT_START:
    case CONFIRM_ROUTE_MANAGEMENT_START:
    case GET_ROUTE_MANAGEMENT_DETAILS_START:
      return {
        ...state,
        isLoading: true,
      }
    case SEARCH_ROUTE_MANAGEMENT_SUCCESS:
      return {
        ...state,
        routeManagementList: action.payload.list,
        isLoading: false,
        total: action.payload.total,
      }

    case SEARCH_ROUTE_MANAGEMENT_FAILED:
      return {
        ...state,
        isLoading: false,
      }

    case CONFIRM_ROUTE_MANAGEMENT_FAILED:
    case CONFIRM_ROUTE_MANAGEMENT_SUCCESS:
    case CREATE_ROUTE_MANAGEMENT_SUCCESS:
    case CREATE_ROUTE_MANAGEMENT_FAILED:
    case UPDATE_ROUTE_MANAGEMENT_SUCCESS:
    case UPDATE_ROUTE_MANAGEMENT_FAILED:
    case DELETE_ROUTE_MANAGEMENT_SUCCESS:
    case DELETE_ROUTE_MANAGEMENT_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case GET_ROUTE_MANAGEMENT_DETAILS_SUCCESS:
      return {
        ...state,
        routeManagementDetails: action.payload,
        isLoading: false,
      }
    case GET_ROUTE_MANAGEMENT_DETAILS_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case RESET_STATE_ROUTE_MANAGEMENT:
      return {
        ...state,
        routeManagementDetails: {},
      }
    default:
      return state
  }
}
