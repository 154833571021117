import React, { useMemo } from 'react'

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { Box, Typography } from '@mui/material'

import DataTable from '~/components/DataTable'
import { Field } from '~/components/Formik'
import { convertUtcDateToLocalTz } from '~/utils'

const ItemSettingTable = (props) => {
  const { items } = props
  const getColumns = useMemo(
    () => [
      {
        field: '#',
        headerName: 'STT',
        width: 50,
        align: 'center',
        renderCell: (_, index) => {
          return index + 1
        },
      },
      // {
      //   field: 'time',
      //   headerName: 'Thời gian',
      //   width: 100,
      //   align: 'left',
      //   renderCell: (params) => {
      //     return params?.row?.time
      //   },
      // },
      {
        field: 'date',
        headerName: 'Ngày tháng',
        width: 100,
        align: 'center',
        renderCell: (params) => {
          const days = [
            'Chủ nhật',
            'Thứ hai',
            'Thứ ba',
            'Thứ tư',
            'Thứ năm',
            'Thứ sáu',
            'Thứ bảy',
          ]
          var day = new Date(params?.row?.date)

          return (
            <>
              {days[day.getDay()]}
              <br />
              {convertUtcDateToLocalTz(params?.row?.date)}
            </>
          )
        },
      },
      {
        field: 'lineId',
        headerName: 'Tuyến đường',
        width: 250,
        align: 'center',
        renderCell: (params) => {
          return params?.row?.type === 0 ? (
            <>
              <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box>{params?.row?.lineId?.fromCN}</Box>
                <ArrowRightAltIcon fontSize="medium" />
                <Box>{params?.row?.lineId?.toCN}</Box>
              </Typography>
              <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box>{params?.row?.lineId?.fromVI}</Box>
                <ArrowRightAltIcon fontSize="medium" />
                <Box>{params?.row?.lineId?.toVI}</Box>
              </Typography>
            </>
          ) : params?.row?.type === 1 ? (
            <>
              <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box>{params?.row?.lineId?.toCN}</Box>
                <ArrowRightAltIcon fontSize="medium" />
                <Box>{params?.row?.lineId?.fromCN}</Box>
              </Typography>
              <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box>{params?.row?.lineId?.toVI}</Box>
                <ArrowRightAltIcon fontSize="medium" />
                <Box>{params?.row?.lineId?.fromVI}</Box>
              </Typography>
            </>
          ) : (
            params?.row?.lineId
          )
        },
      },
      {
        field: 'vehicleType',
        headerName: 'Loại xe',
        width: 150,
        align: 'center',
        renderCell: (params, index) => {
          return params?.row?.type === 'CN' ? (
            ''
          ) : (
            <Field.TextField name={`items[${index}].vehicleType`} />
          )
        },
      },
      {
        field: 'vehicleNumber',
        headerName: 'Biển xe',
        width: 150,
        align: 'center',
        renderCell: (params, index) => {
          return params?.row?.type === 'CN' ? (
            ''
          ) : (
            <Field.TextField name={`items[${index}].vehicleNumber`} />
          )
        },
      },
      {
        field: 'clientSignature',
        headerName: 'Diện 1 ký tên',
        width: 150,
        align: 'center',
        renderCell: (params) => {
          return params?.row?.type === 'CN' ? '' : params?.row?.clientSignature
        },
      },
      {
        field: 'signatureDriver1',
        headerName: 'Lái xe 1 ký ',
        width: 150,
        align: 'center',
        renderCell: (params) => {
          return params?.row?.type === 'CN' ? '' : params?.row?.signatureDriver1
        },
      },
      {
        field: 'signatureDriver1',
        headerName: 'Lái xe 2 ký ',
        width: 150,
        align: 'center',
        renderCell: (params) => {
          return params?.row?.type === 'CN' ? '' : params?.row?.signatureDriver1
        },
      },
      {
        field: 'note',
        headerName: 'Ghi chú',
        width: 150,
        align: 'center',
        renderCell: (params, index) => {
          return params?.row?.type === 'CN' ? (
            ''
          ) : (
            <Field.TextField name={`items[${index}].note`} />
          )
        },
      },
    ],
    [items],
  )
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <Typography variant="h4">Danh sách biểu ký tên đi xe ô tô</Typography>
        <Box></Box>
      </Box>
      <DataTable
        rows={items}
        columns={getColumns}
        hideSetting
        hideFooter
        striped={false}
        enableResizable={false}
      />
    </>
  )
}

export default ItemSettingTable
