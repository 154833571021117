import React from 'react'

import DoneIcon from '@mui/icons-material/Done'
import { Box, Typography } from '@mui/material'
const CompletedPage = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        pt: '10%',
      }}
    >
      {<DoneIcon color="success" sx={{ fontSize: '100px' }} />}

      <Typography sx={{ fontSize: 20, fontWeight: 'bold', mt: 2 }}>
        Phiếu giám sát đã được hoàn thành
      </Typography>
    </Box>
  )
}

export default CompletedPage
