import { useEffect, useMemo } from 'react'

import { Box, Grid, Typography } from '@mui/material'
import { FieldArray, Form, Formik } from 'formik'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'

import {
  ASYNC_SEARCH_LIMIT,
  MODAL_MODE,
  TEXTFIELD_REQUIRED_LENGTH,
} from '~/common/constants'
import ActionBar from '~/components/ActionBar'
import { Field } from '~/components/Formik'
import LV from '~/components/LabelValue'
import Page from '~/components/Page'
import Status from '~/components/Status'
import {
  ROUTE_MANAGEMENT_OPTIONS,
  ROUTE_MANAGEMENT_STATUS,
} from '~/modules/wmsx/constants'
import useGroupMonitorOrder from '~/modules/wmsx/redux/hooks/useGroupMonitorOrder'
import { searchRouteManagementApi } from '~/modules/wmsx/redux/sagas/route-management/search'
import { ROUTE } from '~/modules/wmsx/routes/config'

import ItemSettingTable from './item-seting-table'
import { validationSchema } from './schema'
function GroupMonitorOrderForm() {
  const { t } = useTranslation(['wmsx'])
  const history = useHistory()
  const { id } = useParams()
  const routeMatch = useRouteMatch()
  const MODE_MAP = {
    [ROUTE.GROUP_MONITOR_ORDER.CREATE.PATH]: MODAL_MODE.CREATE,
    [ROUTE.GROUP_MONITOR_ORDER.EDIT.PATH]: MODAL_MODE.UPDATE,
  }
  const {
    data: { groupMonitorOrderDetails, isLoading },
    actions,
  } = useGroupMonitorOrder()
  useEffect(() => {
    if (isUpdate) {
      actions.getGroupMonitorOrderDetailsById(id)
    }
    return () => {
      actions.resetGroupMonitorOrderState()
    }
  }, [id])
  const mode = MODE_MAP[routeMatch.path]
  const isUpdate = mode === MODAL_MODE.UPDATE
  const getBreadcrumb = () => {
    const breadcrumb = [
      {
        route: ROUTE.GROUP_MONITOR_ORDER.LIST.PATH,
        title: ROUTE.GROUP_MONITOR_ORDER.LIST.TITLE,
      },
    ]
    switch (mode) {
      case MODAL_MODE.CREATE:
        breadcrumb.push({
          route: ROUTE.GROUP_MONITOR_ORDER.CREATE.PATH,
          title: ROUTE.GROUP_MONITOR_ORDER.CREATE.TITLE,
        })
        break
      case MODAL_MODE.UPDATE:
        breadcrumb.push({
          route: ROUTE.GROUP_MONITOR_ORDER.EDIT.PATH,
          title: ROUTE.GROUP_MONITOR_ORDER.EDIT.TITLE,
        })
        break
      default:
        break
    }
    return breadcrumb
  }

  const renderActionBar = (handleReset) => {
    switch (mode) {
      case MODAL_MODE.CREATE:
        return (
          <ActionBar
            onBack={backToList}
            onCancel={handleReset}
            mode={MODAL_MODE.CREATE}
          />
        )
      case MODAL_MODE.UPDATE:
        return (
          <ActionBar
            onBack={backToList}
            onCancel={handleReset}
            mode={MODAL_MODE.UPDATE}
          />
        )
      default:
        break
    }
  }

  const initialValues = useMemo(
    () => ({
      companyCN: groupMonitorOrderDetails?.companyCN || '',
      companyVI: groupMonitorOrderDetails?.companyVI || '',
      lineId: groupMonitorOrderDetails?.line || null,
      toCN: groupMonitorOrderDetails?.toCN || '',
      dateMonth: !isEmpty(groupMonitorOrderDetails)
        ? `${groupMonitorOrderDetails?.year}-${groupMonitorOrderDetails?.month}`
        : `${new Date().getFullYear()}-${new Date().getMonth() + 1}`,
      items: groupMonitorOrderDetails?.monitorSheets?.map((item, index) => ({
        id: item?.id,
        date: new Date(item?.date),
        index: index,
        time: item?.start,
        lineId: item?.line || '周日',
        type: item?.type,
        vehicleType: item?.vehicleType,
        vehicleNumber: item?.vehicleNumber,
        note: item?.note,
      })) || [{}],
    }),
    [groupMonitorOrderDetails],
  )
  const onSubmit = (values) => {
    const day = new Date(values?.dateMonth)
    const params = {
      companyVI: values?.companyVI || null,
      companyCN: values?.companyCN || null,
      lineId: values?.lineId?.id || null,
      month: day.getMonth() + 1 || null,
      year: day.getFullYear() || null,
      monitorSheets:
        values?.items?.map((item) => ({
          id: item?.id,
          date: new Date(item?.date).toISOString(),
          lineId: item?.lineId?.id || null,
          type: item?.type,
          vehicleType: item?.vehicleType,
          vehicleNumber: item?.vehicleNumber,
          note: item?.note,
        })) || null,
    }
    if (mode === MODAL_MODE.CREATE) {
      actions.createGroupMonitorOrder(params, backToList)
    } else {
      actions.updateGroupMonitorOrder({ ...params, id: id }, backToList)
    }
  }
  const getTitle = () => {
    switch (mode) {
      case MODAL_MODE.CREATE:
        return ROUTE.GROUP_MONITOR_ORDER.CREATE.TITLE
      case MODAL_MODE.UPDATE:
        return ROUTE.GROUP_MONITOR_ORDER.EDIT.TITLE
      default:
        break
    }
  }

  const backToList = () => {
    history.push(ROUTE.GROUP_MONITOR_ORDER.LIST.PATH)
  }
  function getDaysInMonth(year, month) {
    const date = new Date(year, month, 1)
    const days = []
    while (date.getMonth() === month) {
      days.push(new Date(date))
      if (new Date(date).getDay() !== 0) {
        days.push(new Date(date))
      }
      date.setDate(date.getDate() + 1)
    }
    return days
  }
  const hanldeChangeDate = (val, values, setFieldValue) => {
    if (new Date(val).toJSON() !== new Date(values?.dateMonth).toJSON()) {
      if (!isEmpty(values?.lineId)) {
        setFieldValue('items', [])
        const day = new Date(val)
        const year = day.getFullYear()
        const month = day.getMonth()
        const totalDay = getDaysInMonth(year, month)
        const items = []
        let check = 0
        totalDay?.forEach((item, index) => {
          if (new Date(item).getDay() === 0) {
            items.push({
              date: new Date(item),
              index: index,
              time: '',
              lineId: '周日',
              type: 3,
              vehicleType: '',
              vehicleNumber: '',
              note: '',
            })
            check++
          } else {
            items.push({
              date: new Date(item),
              index: index,
              time: '',
              lineId: val,
              type: (check + index) % 2 === 0 ? 1 : 0,
              vehicleType: '',
              vehicleNumber: '',
              note: '',
            })
          }
        })
        setFieldValue('items', items)
      }
    }
  }
  const handleChangeLine = (val, values, setFieldValue) => {
    if (values?.dateMonth) {
      setFieldValue('items', [])
      const day = new Date(values?.dateMonth)
      const year = day.getFullYear()
      const month = day.getMonth()
      const totalDay = getDaysInMonth(year, month)
      const items = []
      let check = 0
      totalDay?.forEach((item, index) => {
        if (new Date(item).getDay() === 0) {
          items.push({
            date: new Date(item),
            index: index,
            time: '',
            lineId: '周日',
            type: 3,
            vehicleType: '',
            vehicleNumber: '',
            note: '',
          })
          check++
        } else {
          items.push({
            date: new Date(item),
            index: index,
            time: '',
            lineId: val,
            type: (check + index) % 2 === 0 ? 1 : 0,
            vehicleType: '',
            vehicleNumber: '',
            note: '',
          })
        }
      })
      setFieldValue('items', items)
    }
  }
  return (
    <Page
      breadcrumbs={getBreadcrumb()}
      title={getTitle()}
      onBack={backToList}
      loading={isLoading}
    >
      <Grid container justifyContent="center">
        <Grid item xl={11} xs={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema(t, mode)}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ handleReset, values, setFieldValue }) => {
              return (
                <Form>
                  <Grid container justifyContent="center">
                    <Grid item xl={11} xs={12}>
                      <Grid
                        container
                        rowSpacing={4 / 3}
                        columnSpacing={{ xl: 8, xs: 4 }}
                      >
                        {isUpdate && (
                          <Grid item xs={12}>
                            <LV
                              label={<Typography>Trạng thái</Typography>}
                              value={
                                <Status
                                  options={ROUTE_MANAGEMENT_OPTIONS}
                                  value={groupMonitorOrderDetails?.status}
                                />
                              }
                            />
                          </Grid>
                        )}
                        <Grid item lg={6} xs={12}>
                          <Field.TextField
                            label="Công ty"
                            name="companyVI"
                            placeholder="Công ty"
                            inputProps={{
                              maxLength: TEXTFIELD_REQUIRED_LENGTH.CODE_50.MAX,
                            }}
                            disabled={
                              groupMonitorOrderDetails?.status ===
                              ROUTE_MANAGEMENT_STATUS.CONFIRMED
                            }
                            required
                          />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                          <Field.TextField
                            label="公司"
                            name="companyCN"
                            placeholder="公司"
                            inputProps={{
                              maxLength: TEXTFIELD_REQUIRED_LENGTH.CODE_50.MAX,
                            }}
                            disabled={
                              groupMonitorOrderDetails?.status ===
                              ROUTE_MANAGEMENT_STATUS.CONFIRMED
                            }
                            required
                          />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                          <Field.Autocomplete
                            name="lineId"
                            label="Tuyến đường"
                            placeholder="Tuyến đường"
                            asyncRequest={(s) =>
                              searchRouteManagementApi({
                                keyword: s,
                                limit: ASYNC_SEARCH_LIMIT,
                                status: 1,
                              })
                            }
                            asyncRequestHelper={(res) => res?.data?.items}
                            getOptionLabel={(opt) =>
                              `${opt?.fromVI} - ${opt?.toVI}`
                            }
                            getOptionSubLabel={(opt) =>
                              `${opt?.fromCN} - ${opt?.toCN}`
                            }
                            isOptionEqualToValue={(opt, val) =>
                              opt?.id === val?.id
                            }
                            onChange={(val) =>
                              handleChangeLine(val, values, setFieldValue)
                            }
                            disabled={
                              groupMonitorOrderDetails?.status ===
                              ROUTE_MANAGEMENT_STATUS.CONFIRMED
                            }
                            required
                          />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                          <Field.DatePicker
                            views={['month', 'year']}
                            name="dateMonth"
                            label="Ngày tháng"
                            onChange={(val) =>
                              hanldeChangeDate(val, values, setFieldValue)
                            }
                            required
                            disabled={
                              groupMonitorOrderDetails?.status ===
                              ROUTE_MANAGEMENT_STATUS.CONFIRMED
                            }
                          />
                        </Grid>
                      </Grid>
                      {values?.lineId && values?.dateMonth && (
                        <Box sx={{ mt: 3 }}>
                          <FieldArray
                            name="items"
                            render={() => (
                              <ItemSettingTable items={values?.items || []} />
                            )}
                          />
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                  {renderActionBar(handleReset)}
                </Form>
              )
            }}
          </Formik>
        </Grid>
      </Grid>
    </Page>
  )
}
export default GroupMonitorOrderForm
