import { call, put, takeLatest } from 'redux-saga/effects'

import { NOTIFICATION_TYPE } from '~/common/constants'
import {
  toSignGroupMonitorOrderByIdFailed,
  toSignGroupMonitorOrderByIdSuccess,
  TO_SIGN_GROUP_MONITOR_ORDER_START,
} from '~/modules/wmsx/redux/actions/group-monitor-order'
import { api } from '~/services/api'
import addNotification from '~/utils/toast'

const toSignGroupMonitorOrderApi = (params) => {
  const uri = `/monitor-sheets/${params}/to-sign`
  return api.put(uri)
}

function* doToSignGroupMonitorOrder(action) {
  try {
    const response = yield call(toSignGroupMonitorOrderApi, action?.payload)

    if (response?.statusCode === 200) {
      yield put(toSignGroupMonitorOrderByIdSuccess(response.payload))

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess()
      }

      addNotification(response?.message, NOTIFICATION_TYPE.SUCCESS)
    } else {
      addNotification(
        response?.message || response?.statusText,
        NOTIFICATION_TYPE.ERROR,
      )

      throw new Error(response?.message)
    }
  } catch (error) {
    yield put(toSignGroupMonitorOrderByIdFailed())
    // Call callback action if provided
    if (action.onError) {
      yield action.onError()
    }
  }
}

export default function* watchToSignGroupMonitorOrder() {
  yield takeLatest(TO_SIGN_GROUP_MONITOR_ORDER_START, doToSignGroupMonitorOrder)
}
