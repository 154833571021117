import React, { useMemo } from 'react'

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { Box, Typography } from '@mui/material'

import DataTable from '~/components/DataTable'
import { convertUtcDateToLocalTz } from '~/utils'

const ItemSettingTable = (props) => {
  const { items } = props

  const getColumns = useMemo(
    () => [
      {
        field: '#',
        headerName: 'STT',
        width: 10,
        align: 'center',
        renderCell: (_, index) => {
          return index + 1
        },
      },
      // {
      //   field: 'time',
      //   headerName: 'Thời gian',
      //   width: 80,
      //   align: 'center',
      //   renderCell: (params) => {
      //     return convertUtcDateTimeToLocalTz(params?.row?.time)
      //   },
      // },
      {
        field: 'date',
        headerName: 'Ngày tháng',
        width: 80,
        align: 'center',
        renderCell: (params) => {
          const days = [
            'Chủ nhật',
            'Thứ hai',
            'Thứ ba',
            'Thứ tư',
            'Thứ năm',
            'Thứ sáu',
            'Thứ bảy',
          ]
          var day = new Date(params?.row?.date)

          return (
            <>
              {days[day.getDay()]}
              <br />
              {convertUtcDateToLocalTz(params?.row?.date)}
            </>
          )
        },
      },
      {
        field: 'lineId',
        headerName: 'Tuyến đường',
        width: 250,
        align: 'center',
        renderCell: (params) => {
          return params?.row?.type === 0 ? (
            <>
              <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box>{params?.row?.lineId?.fromCN}</Box>
                <ArrowRightAltIcon fontSize="medium" />
                <Box>{params?.row?.lineId?.toCN}</Box>
              </Typography>
              <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box>{params?.row?.lineId?.fromVI}</Box>
                <ArrowRightAltIcon fontSize="medium" />
                <Box>{params?.row?.lineId?.toVI}</Box>
              </Typography>
            </>
          ) : params?.row?.type === 1 ? (
            <>
              <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box>{params?.row?.lineId?.toCN}</Box>
                <ArrowRightAltIcon fontSize="medium" />
                <Box>{params?.row?.lineId?.fromCN}</Box>
              </Typography>
              <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box>{params?.row?.lineId?.toVI}</Box>
                <ArrowRightAltIcon fontSize="medium" />
                <Box>{params?.row?.lineId?.fromVI}</Box>
              </Typography>
            </>
          ) : (
            params?.row?.lineId
          )
        },
      },
      {
        field: 'vehicleType',
        headerName: 'Loại xe',
        width: 100,
        align: 'center',
        renderCell: (params) => {
          return params?.row?.vehicleType
        },
      },
      {
        field: 'vehicleNumber',
        headerName: 'Biển xe',
        width: 100,
        align: 'center',
        renderCell: (params) => {
          return params?.row?.vehicleNumber
        },
      },
      {
        field: 'clientSignature',
        headerName: 'Đại diện 1 ký tên',
        width: 120,
        align: 'center',
        renderCell: (params) => {
          return params?.row?.clientSignature ? (
            <img
              width={120}
              height={80}
              src={params?.row.clientSignature}
              alt="Lái xe ký tên"
            />
          ) : (
            ''
          )
        },
      },
      {
        field: 'signatureDriver1',
        headerName: 'Lái xe 1 ký ',
        width: 120,
        align: 'center',
        renderCell: (params) => {
          return params?.row?.signatureDriver1 ? (
            <img
              width={120}
              height={80}
              src={params?.row?.signatureDriver1}
              alt="Lái xe ký tên"
            />
          ) : (
            ''
          )
        },
      },
      {
        field: 'signatureDriver2',
        headerName: 'Lái xe 2 ký ',
        width: 120,
        align: 'center',
        renderCell: (params) => {
          return params?.row?.signatureDriver2 ? (
            <img
              width={120}
              height={80}
              src={params?.row?.signatureDriver2}
              alt="Lái xe ký tên"
            />
          ) : (
            ''
          )
        },
      },
      {
        field: 'note',
        headerName: 'Ghi chú',
        width: 100,
        align: 'center',
        renderCell: (params) => {
          return params?.row?.note
        },
      },
    ],
    [items],
  )

  return (
    <>
      <Typography
        variant="h3"
        sx={{ display: 'flex', justifyContent: 'center', mb: 1, mt: 1 }}
      >
        坐车签到表 Biểu ký tên đi xe ô tô
      </Typography>

      <DataTable
        rows={items}
        columns={getColumns}
        hideSetting
        hideFooter
        striped={false}
        enableResizable={false}
        height="none"
      />
    </>
  )
}

export default ItemSettingTable
