import { call, put, takeLatest } from 'redux-saga/effects'

import { NOTIFICATION_TYPE } from '~/common/constants'
import {
  resetPasswodFailed,
  resetPasswodSuccess,
  RESET_PASSWORD_USER_START,
} from '~/modules/wmsx/redux/actions/warehouse-import-receipt'
import { api } from '~/services/api'
import addNotification from '~/utils/toast'

const resetPasswordApi = (params) => {
  const uri = `/users/${params}/reset-password`
  return api.put(uri)
}

function* doResetPassword(action) {
  try {
    const response = yield call(resetPasswordApi, action?.payload)

    if (response?.statusCode === 200) {
      yield put(resetPasswodSuccess(response.payload))

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess()
      }

      addNotification(response?.message, NOTIFICATION_TYPE.SUCCESS)
    } else {
      addNotification(
        response?.message || response?.statusText,
        NOTIFICATION_TYPE.ERROR,
      )

      throw new Error(response?.message)
    }
  } catch (error) {
    yield put(resetPasswodFailed())
    // Call callback action if provided
    if (action.onError) {
      yield action.onError()
    }
  }
}

export default function* watchResetPassword() {
  yield takeLatest(RESET_PASSWORD_USER_START, doResetPassword)
}
