export const SEARCH_ROUTE_MANAGEMENT_START =
  'WMSX_SEARCH_ROUTE_MANAGEMENT_START'
export const SEARCH_ROUTE_MANAGEMENT_SUCCESS =
  'WMSX_SEARCH_ROUTE_MANAGEMENT_SUCCESS'
export const SEARCH_ROUTE_MANAGEMENT_FAILED =
  'WMSX_SEARCH_ROUTE_MANAGEMENT_FAILED'

export const CREATE_ROUTE_MANAGEMENT_START =
  'WMSX_CREATE_ROUTE_MANAGEMENT_START'
export const CREATE_ROUTE_MANAGEMENT_SUCCESS =
  'WMSX_CREATE_ROUTE_MANAGEMENT_SUCCESS'
export const CREATE_ROUTE_MANAGEMENT_FAILED =
  'WMSX_CREATE_ROUTE_MANAGEMENT_FAILED'

export const UPDATE_ROUTE_MANAGEMENT_START =
  'WMSX_UPDATE_ROUTE_MANAGEMENT_START'
export const UPDATE_ROUTE_MANAGEMENT_SUCCESS =
  'WMSX_UPDATE_ROUTE_MANAGEMENT_SUCCESS'
export const UPDATE_ROUTE_MANAGEMENT_FAILED =
  'WMSX_UPDATE_ROUTE_MANAGEMENT_FAILED'

export const UPDATE_HEADER_ROUTE_MANAGEMENT_START =
  'WMSX_UPDATE_HEADER_ROUTE_MANAGEMENT_START'
export const UPDATE_HEADER_ROUTE_MANAGEMENT_SUCCESS =
  'WMSX_UPDATE_HEADER_ROUTE_MANAGEMENT_SUCCESS'
export const UPDATE_HEADER_ROUTE_MANAGEMENT_FAILED =
  'WMSX_UPDATE_HEADER_ROUTE_MANAGEMENT_FAILED'

export const DELETE_ROUTE_MANAGEMENT_START =
  'WMSX_DELETE_ROUTE_MANAGEMENT_START'
export const DELETE_ROUTE_MANAGEMENT_SUCCESS =
  'WMSX_DELETE_ROUTE_MANAGEMENT_SUCCESS'
export const DELETE_ROUTE_MANAGEMENT_FAILED =
  'WMSX_DELETE_ROUTE_MANAGEMENT_FAILED'

export const GET_ROUTE_MANAGEMENT_DETAILS_START =
  'WMSX_GET_ROUTE_MANAGEMENT_DETAILS_START'
export const GET_ROUTE_MANAGEMENT_DETAILS_SUCCESS =
  'WMSX_GET_ROUTE_MANAGEMENT_DETAILS_SUCCESS'
export const GET_ROUTE_MANAGEMENT_DETAILS_FAILED =
  'WMSX_GET_ROUTE_MANAGEMENT_DETAILS_FAILED'

export const CONFIRM_ROUTE_MANAGEMENT_START =
  'WMSX_CONFIRM_ROUTE_MANAGEMENT_START'
export const CONFIRM_ROUTE_MANAGEMENT_SUCCESS =
  'WMSX_CONFIRM_ROUTE_MANAGEMENT_SUCCESS'
export const CONFIRM_ROUTE_MANAGEMENT_FAILED =
  'WMSX_CONFIRM_ROUTE_MANAGEMENT_FAILED'
export const RESET_STATE_ROUTE_MANAGEMENT = 'WMSX_RESET_STATE_ROUTE_MANAGEMENT'

export function searchRouteManagement(payload, onSuccess, onError) {
  return {
    type: SEARCH_ROUTE_MANAGEMENT_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function searchRouteManagementSuccess(payload) {
  return {
    type: SEARCH_ROUTE_MANAGEMENT_SUCCESS,
    payload: payload,
  }
}

export function searchRouteManagementFailed() {
  return {
    type: SEARCH_ROUTE_MANAGEMENT_FAILED,
  }
}
export function createRouteManagement(payload, onSuccess, onError) {
  return {
    type: CREATE_ROUTE_MANAGEMENT_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function createRouteManagementSuccess(payload) {
  return {
    type: CREATE_ROUTE_MANAGEMENT_SUCCESS,
    payload: payload,
  }
}

export function createRouteManagementFailed() {
  return {
    type: CREATE_ROUTE_MANAGEMENT_FAILED,
  }
}

export function updateRouteManagement(payload, onSuccess, onError) {
  return {
    type: UPDATE_ROUTE_MANAGEMENT_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function updateRouteManagementSuccess(payload) {
  return {
    type: UPDATE_ROUTE_MANAGEMENT_SUCCESS,
    payload: payload,
  }
}

export function updateRouteManagementFailed() {
  return {
    type: UPDATE_ROUTE_MANAGEMENT_FAILED,
  }
}
export function deleteRouteManagement(packageId, onSuccess, onError) {
  return {
    type: DELETE_ROUTE_MANAGEMENT_START,
    payload: packageId,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function deleteRouteManagementSuccess(payload) {
  return {
    type: DELETE_ROUTE_MANAGEMENT_SUCCESS,
    payload: payload,
  }
}

export function deleteRouteManagementFailed() {
  return {
    type: DELETE_ROUTE_MANAGEMENT_FAILED,
  }
}

export function getRouteManagementDetailsById(packageId, onSuccess, onError) {
  return {
    type: GET_ROUTE_MANAGEMENT_DETAILS_START,
    payload: packageId,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function getRouteManagementDetailsByIdSuccess(payload) {
  return {
    type: GET_ROUTE_MANAGEMENT_DETAILS_SUCCESS,
    payload: payload,
  }
}

export function getRouteManagementDetailsByIdFailed() {
  return {
    type: GET_ROUTE_MANAGEMENT_DETAILS_FAILED,
  }
}

export function confirmRouteManagementById(Id, onSuccess, onError) {
  return {
    type: CONFIRM_ROUTE_MANAGEMENT_START,
    payload: Id,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function confirmRouteManagementByIdSuccess(payload) {
  return {
    type: CONFIRM_ROUTE_MANAGEMENT_SUCCESS,
    payload: payload,
  }
}

export function confirmRouteManagementByIdFailed() {
  return {
    type: CONFIRM_ROUTE_MANAGEMENT_FAILED,
  }
}
export function resetStateRouteManagement() {
  return {
    type: RESET_STATE_ROUTE_MANAGEMENT,
  }
}
export default {
  searchRouteManagement,
  searchRouteManagementSuccess,
  searchRouteManagementFailed,
  createRouteManagement,
  createRouteManagementSuccess,
  createRouteManagementFailed,
  updateRouteManagement,
  updateRouteManagementSuccess,
  updateRouteManagementFailed,
  getRouteManagementDetailsById,
  getRouteManagementDetailsByIdSuccess,
  getRouteManagementDetailsByIdFailed,
  confirmRouteManagementById,
  confirmRouteManagementByIdSuccess,
  confirmRouteManagementByIdFailed,
  deleteRouteManagement,
  deleteRouteManagementSuccess,
  deleteRouteManagementFailed,
  resetStateRouteManagement,
}
