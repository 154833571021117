export const ROUTE = {
  HOME: {
    PATH: '/',
    TITLE: 'Tạo phiếu giám sát xe',
  },
  WAREHOUSE_IMPORT_RECEIPT: {
    LIST: {
      PATH: '/monitor-order',
      TITLE: 'Phiếu giám sát xe',
    },
    CREATE: {
      PATH: '/monitor-order/create',
      TITLE: 'Tạo phiếu giám sát xe',
    },
    EDIT: {
      PATH: '/monitor-order/:id/edit',
      TITLE: 'Sửa phiếu giám sát xe',
    },
    DETAIL: {
      PATH: '/monitor-order/:id/detail',
      TITLE: 'Chi tiết phiếu giám sát xe',
    },
    COMPLETED: {
      PATH: '/monitor-order/completed',
      TITLE: 'hihi',
    },
  },

  GROUP_MONITOR_ORDER: {
    LIST: {
      PATH: '/group-monitor-order',
      TITLE: 'Nhóm phiếu giám sát xe',
    },
    CREATE: {
      PATH: '/group-monitor-order/create',
      TITLE: 'Tạo nhóm phiếu giám sát xe',
    },
    EDIT: {
      PATH: '/group-monitor-order/:id/edit',
      TITLE: 'Sửa nhóm phiếu giám sát xe',
    },
    PRINT: {
      PATH: '/group-monitor-order/:id/print',
      TITLE: 'In nhóm phiếu giám sát xe',
    },
    DETAIL: {
      PATH: '/group-monitor-order/:id/detail',
      TITLE: 'Chi tiết nhóm phiếu giám sát xe',
    },
  },

  MONITOR_SHEET: {
    DETAIL: {
      PATH: '/monitor-sheet/:id/detail',
      TITLE: 'Chi tiết phiếu giám sát xe',
    },
  },
  USER_MANAGEMENT: {
    LIST: {
      PATH: '/user-management',
      TITLE: 'Quản lý người dùng',
    },
    CREATE: {
      PATH: '/user-management/create',
      TITLE: 'Tạo người dùng',
    },
    DETAIL: {
      PATH: '/user-management/:id/detail',
      TITLE: 'Thông tin người dùng',
    },
    EDIT: {
      PATH: '/user-management/:id/edit',
      TITLE: 'Sửa thông tin người dùng',
    },
  },
  ACCOUNT: {
    DETAIL: {
      PATH: '/account/user-info',
      TITLE: 'Thông tin tài khoản',
    },
    CHANGE_PASSWORD: {
      PATH: '/account/change-password',
      TITLE: 'Đổi mật khẩu',
    },
  },
  ROUTE_MANAGEMENT: {
    LIST: {
      PATH: '/route-management',
      TITLE: 'Quản lý tuyến đường',
    },
    CREATE: {
      PATH: '/route-management/create',
      TITLE: 'Tạo tuyến đường',
    },
    DETAIL: {
      PATH: '/route-management/:id/detail',
      TITLE: 'Chi tiết tuyến đường',
    },
    EDIT: {
      PATH: '/route-management/:id/edit',
      TITLE: 'Sửa tuyến đường',
    },
  },
}
