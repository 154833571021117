export const ROUTE = {
  REDIRECT: {
    PATH: '/redirect',
    TITLE: '',
  },
  LICENSE: {
    PATH: '/license',
    TITLE: 'license',
  },
}
