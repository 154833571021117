// import React from 'react'

// import { Box } from '@mui/material'
// import PropTypes from 'prop-types'

// const PublicLayout = ({ children }) => (
//   <Box sx={{ height: '100%', overflow: 'auto' }}>{children}</Box>
// )

// PublicLayout.defaultProps = {
//   children: null,
// }

// PublicLayout.propTypes = {
//   children: PropTypes.node,
// }

// export default PublicLayout
import { useEffect, useState } from 'react'

import { Box } from '@mui/material'
import { isEmpty } from 'lodash'
import { Route, Redirect, useParams, useLocation } from 'react-router-dom'

import Sidebar from '~/components/Sidebar'
import { WAREHOUSE_IMPORT_RECEIPT_STATUS } from '~/modules/wmsx/constants'
import { getWarehouseImportReceiptDetailsApi } from '~/modules/wmsx/redux/sagas/warehouse-import-receipt/get-details'
import { isAuth } from '~/utils'
import { getMonitorSheetDetailsApi } from '~/modules/wmsx/redux/sagas/monitor-sheet/get-details'

function PublicRoute({ children, ...rest }) {
  const { id } = useParams()
  const { pathname } = useLocation()
  const [checkPublic, setCheckPublic] = useState(true)
  useEffect(async () => {
    if (pathname.startsWith('/monitor-order')) {
      const response = await getWarehouseImportReceiptDetailsApi(id)
      if (!isEmpty(response)) {
        if (
          response?.data?.status ===
          WAREHOUSE_IMPORT_RECEIPT_STATUS.WAITTING_SIGN
        ) {
          setCheckPublic(true)
        } else {
          setCheckPublic(false)
        }
      } else {
        setCheckPublic(false)
      }
    } else if (pathname.startsWith('/monitor-sheet')) {
      const response = await getMonitorSheetDetailsApi(id)
      if (!isEmpty(response)) {
        if (response?.data?.status === 1) {
          setCheckPublic(true)
        } else {
          setCheckPublic(false)
        }
      }
    }
  }, [id])

  return (
    <Box
      sx={{
        display: 'flex',
        overflow: 'hidden',
        height: '100%',
      }}
    >
      {isAuth() && <Sidebar />}
      <Box sx={{ flex: 1, overflow: 'hidden' }}>
        {
          <Route
            {...rest}
            render={({ location }) =>
              isAuth() ||
              pathname === '/monitor-order/completed' ||
              checkPublic ? (
                children
              ) : (
                <Redirect
                  to={{
                    pathname: '/login',
                    state: { from: location },
                  }}
                />
              )
            }
          />
        }
      </Box>
    </Box>
  )
}

export default PublicRoute
