import React, { useEffect, useRef, useState } from 'react'

import '~/css/custom-table.css'
import CloseIcon from '@mui/icons-material/Close'
import { Checkbox, IconButton, Typography } from '@mui/material'
import DialogMui from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useParams, useHistory } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'

import background from '~/assets/images/backgroud.jpg'
import { NOTIFICATION_TYPE } from '~/common/constants'
import Button from '~/components/Button'
import Dialog from '~/components/Dialog'
import Icon from '~/components/Icon'
import NumberFormatText from '~/components/NumberFormat'
import Page from '~/components/Page'
import { ROLE, WAREHOUSE_IMPORT_RECEIPT_STATUS } from '~/modules/wmsx/constants'
import useWarehouseImportReceipt from '~/modules/wmsx/redux/hooks/useWarehouseImportReceipt'
import { ROUTE } from '~/modules/wmsx/routes/config'
import {
  convertUtcDateToLocalTz,
  getLocalItem,
  isAuth,
  readNumber,
} from '~/utils'
import addNotification from '~/utils/toast'

import Sign from './sign'
import { useWindowDimensions } from '../../get-size-sreen'

const breadcrumbs = [
  {
    route: ROUTE.WAREHOUSE_IMPORT_RECEIPT.LIST.PATH,
    title: ROUTE.WAREHOUSE_IMPORT_RECEIPT.LIST.TITLE,
  },
  {
    route: ROUTE.WAREHOUSE_IMPORT_RECEIPT.DETAIL.PATH,
    title: ROUTE.WAREHOUSE_IMPORT_RECEIPT.DETAIL.TITLE,
  },
]

function WarehouseImportReceiptDetail() {
  const componentRef = useRef()
  const { id } = useParams()
  const history = useHistory()
  const { t } = useTranslation('wmsx')
  const canvasRef = React.createRef(null)
  const [saveData, setSaveData] = React.useState('')
  const [loading, setLoading] = React.useState(true)
  const [modal, setModal] = useState({
    tempItem: null,
    isOpenDeleteModal: false,
    isOpenConfirmModal: false,
    isOpenSeenModal: false,
    isOpenRetryModal: false,
    isOpenDriverSingn: false,
    isOpenCustomerSign: false,
    isOpenCustomerSingn: false,
    isOpenCompletedMonitorOrder: false,
  })
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  const sizeScreen = useWindowDimensions()

  const {
    data: { warehouseImportReceiptDetails, isLoading },
    actions,
  } = useWarehouseImportReceipt()

  useEffect(() => {
    actions.getWarehouseImportReceiptDetailsById(id)
    return () => {
      actions.resetWarehouseImportReceiptState()
    }
  }, [id])
  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    })
  }, [1500])
  const userInfo = getLocalItem('userInfo')

  const renderHeaderRight = () => {
    const isDelete =
      warehouseImportReceiptDetails.status ===
        WAREHOUSE_IMPORT_RECEIPT_STATUS.PENDING &&
      userInfo?.role !== ROLE.DRIVER
    const isSeenToDriver =
      warehouseImportReceiptDetails.status ===
        WAREHOUSE_IMPORT_RECEIPT_STATUS.CONFIRMED &&
      userInfo?.role !== ROLE.DRIVER
    const isRetry =
      warehouseImportReceiptDetails.status ===
        WAREHOUSE_IMPORT_RECEIPT_STATUS.SIGNED && userInfo?.role !== ROLE.DRIVER
    return (
      <>
        {isDelete && (
          <Button
            onClick={() => onClickDelete()}
            sx={{
              ml: 4 / 3,
            }}
            color="error"
          >
            Xoá
          </Button>
        )}
        {isDelete && (
          <Button onClick={() => onClickConfirm()} sx={{ ml: 4 / 3 }}>
            Xác nhận
          </Button>
        )}
        {isDelete && (
          <Button
            sx={{ ml: 4 / 3 }}
            onClick={() =>
              history.push(
                ROUTE.WAREHOUSE_IMPORT_RECEIPT.EDIT.PATH.replace(
                  ':id',
                  `${id}`,
                ),
              )
            }
          >
            Sửa phiếu
          </Button>
        )}
        {isSeenToDriver && (
          <Button onClick={() => onClickSeenDriver()} sx={{ ml: 4 / 3 }}>
            Gửi Phiếu
          </Button>
        )}
        {isRetry && (
          <Button onClick={() => onClickRetry()} sx={{ ml: 4 / 3 }}>
            Yêu cầu ký lại
          </Button>
        )}
        {warehouseImportReceiptDetails.status !==
          WAREHOUSE_IMPORT_RECEIPT_STATUS.PENDING &&
          userInfo?.role !== ROLE.DRIVER && (
            <Button
              onClick={() => handlePrint()}
              sx={{ ml: 4 / 3 }}
              icon="print"
            >
              In phiếu
            </Button>
          )}
        {!isAuth() &&
          warehouseImportReceiptDetails.status ===
            WAREHOUSE_IMPORT_RECEIPT_STATUS.WAITTING_SIGN &&
          warehouseImportReceiptDetails?.contract?.name === 'HeT' &&
          sizeScreen?.width > 500 && (
            <>
              <Button
                sx={{ ml: 4 / 3 }}
                icon="edit"
                onClick={() => onClickDriverSign()}
              >
                L.Xe ký tên
              </Button>
            </>
          )}
        {!isAuth() &&
          warehouseImportReceiptDetails.status ===
            WAREHOUSE_IMPORT_RECEIPT_STATUS.WAITTING_SIGN &&
          sizeScreen?.width > 500 && (
            <>
              <Button
                sx={{ ml: 4 / 3 }}
                icon="edit"
                onClick={() => setModal({ isOpenCustomerSingn: true })}
              >
                KH ký tên (用车人签字)
              </Button>
            </>
          )}
        {!isAuth() &&
          warehouseImportReceiptDetails.status ===
            WAREHOUSE_IMPORT_RECEIPT_STATUS.WAITTING_SIGN &&
          sizeScreen?.width > 500 &&
          warehouseImportReceiptDetails?.clientSignature && (
            <Button
              sx={{ ml: 2 }}
              onClick={() => setModal({ isOpenCompletedMonitorOrder: true })}
            >
              Hoàn thành
            </Button>
          )}
        {!isAuth() &&
          warehouseImportReceiptDetails.status ===
            WAREHOUSE_IMPORT_RECEIPT_STATUS.WAITTING_SIGN &&
          warehouseImportReceiptDetails?.contract?.name === 'HeT' &&
          sizeScreen?.width <= 500 && (
            <>
              <Button icon="edit" onClick={() => onClickDriverSign()}>
                L.Xe ký tên
              </Button>
            </>
          )}
        {!isAuth() &&
          warehouseImportReceiptDetails.status ===
            WAREHOUSE_IMPORT_RECEIPT_STATUS.WAITTING_SIGN &&
          sizeScreen?.width <= 500 && (
            <>
              <Button
                sx={{ mt: 1 }}
                icon="edit"
                onClick={() => setModal({ isOpenCustomerSingn: true })}
              >
                KH ký tên (用车人签字)
              </Button>
            </>
          )}
        {!isAuth() &&
          warehouseImportReceiptDetails.status ===
            WAREHOUSE_IMPORT_RECEIPT_STATUS.WAITTING_SIGN &&
          sizeScreen?.width <= 500 &&
          warehouseImportReceiptDetails?.clientSignature && (
            <Button
              sx={{ mt: 1 }}
              onClick={() => setModal({ isOpenCompletedMonitorOrder: true })}
            >
              Hoàn thành
            </Button>
          )}
      </>
    )
  }

  useEffect(() => {
    actions.getWarehouseImportReceiptDetailsById(id)
  }, [sizeScreen.height, sizeScreen.width])
  const backToList = () => {
    history.push(ROUTE.WAREHOUSE_IMPORT_RECEIPT.LIST.PATH)
  }

  const onClickDelete = () => {
    setModal({ isOpenDeleteModal: true })
  }
  const onClickConfirm = () => {
    setModal({ isOpenConfirmModal: true })
  }
  const onClickSeenDriver = () => {
    setModal({ isOpenSeenModal: true })
  }
  const onClickRetry = () => {
    setModal({ isOpenRetryModal: true })
  }
  const onClickDriverSign = () => {
    setModal({ isOpenDriverSingn: true })
  }
  const onSubmitDelete = () => {
    actions.deleteWarehouseImportReceipt(id, () => {
      onCloseDeleteModal()
      actions.getWarehouseImportReceiptDetailsById(id)
    })
  }
  const onSubmitConfirm = () => {
    actions.confirmWarehouseImportReceiptById(id, () => {
      onCloseDeleteModal()
      actions.getWarehouseImportReceiptDetailsById(id)
    })
  }
  const onSubmitSeen = () => {
    actions.seenToDriver(id, () => {
      onCloseDeleteModal()
      actions.getWarehouseImportReceiptDetailsById(id)
    })
  }
  const onSubmitRetry = () => {
    actions.retry(id, () => {
      onCloseDeleteModal()
      actions.getWarehouseImportReceiptDetailsById(id)
    })
  }
  const onCloseDeleteModal = () => {
    setModal({
      isOpenDeleteModal: false,
      tempItem: null,
      isOpenConfirmModal: false,
      isOpenSeenModal: false,
      isOpenRetryModal: false,
      isOpenDriverSingn: false,
      isOpenCustomerSingn: false,
      isOpenCompletedMonitorOrder: false,
      isOpenShare: false,
    })
  }
  const onSubmitCompletedMonitorOrder = () => {
    actions.completedMonitorOrder(id, () => {
      onCloseDeleteModal()
      // window.open('/monitor-order/completed')
      let winOpen = window.open('', '_top')
      winOpen.location = '/monitor-order/completed'
    })
  }
  const handleClickSignCustomer = () => {
    if (!saveData) {
      addNotification('vui lòng ký tên', NOTIFICATION_TYPE.ERROR)
    } else {
      actions.signCustomer({ id: +id, signature: saveData }, () => {
        actions.getWarehouseImportReceiptDetailsById(id)
        setModal({ isOpenCustomerSingn: false })
      })
    }
  }
  const handleClickSignDriver = () => {
    if (!saveData) {
      addNotification('vui lòng ký tên', NOTIFICATION_TYPE.ERROR)
    } else {
      actions.signDriver({ id: +id, signature: saveData }, () => {
        actions.getWarehouseImportReceiptDetailsById(id)
        setModal({ isOpenCustomerSingn: false })
      })
    }
  }

  const renderRoute = (index) => {
    return (
      <>
        <td>
          {!isEmpty(warehouseImportReceiptDetails?.items) &&
            warehouseImportReceiptDetails?.items[index]?.nameCN}
          <br />
          {!isEmpty(warehouseImportReceiptDetails?.items) &&
            warehouseImportReceiptDetails?.items[index]?.nameVI}
        </td>
        <td>
          <Checkbox
            disabled
            checked={
              !isEmpty(warehouseImportReceiptDetails?.items) &&
              warehouseImportReceiptDetails.items[index].isOneWay
            }
          />
        </td>
        <td>
          <Checkbox
            disabled
            checked={
              !isEmpty(warehouseImportReceiptDetails?.items) &&
              warehouseImportReceiptDetails.items[index].isRoundTrip
            }
          />
        </td>
      </>
    )
  }

  const renderOrder = () => {
    if (sizeScreen.height < sizeScreen.width) {
      switch (warehouseImportReceiptDetails?.contract?.name) {
        case 'HeT':
          return (
            <div
              ref={componentRef}
              style={{
                width: '100%',
                height: '90%',
              }}
            >
              <Typography
                style={{
                  width: '95%',
                  margin: '0 auto',
                }}
              >
                Mã phiếu: #{id}
              </Typography>
              <table
                style={{
                  width: '95%',
                  height: '100%',
                  margin: '0 auto',
                }}
              >
                <tbody>
                  <tr
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    <td colSpan={7}>
                      和而泰智能控制（越南）有限公司 <br />
                      <b>CÔNG TY TNHH H&T INTELLIGENT CONTROL （VIET NAM ）</b>
                    </td>
                  </tr>
                  <tr
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    <td colSpan={7}>
                      租车使用一览表 <b>BIỂU GIÁM SÁT SỬ DỤNG XE</b>
                    </td>
                  </tr>
                  <tr
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    <td>明细信息</td>
                    <td>固定路线</td>
                    <td>单程</td>
                    <td>往返</td>
                    <td>固定路线</td>
                    <td>单程</td>
                    <td>往返</td>
                  </tr>
                  <tr>
                    <td rowSpan={6}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-around',
                          height: '100%',
                          fontSize: 24,
                        }}
                      >
                        <div>
                          日期 :<br />
                          Ngày tháng:{' '}
                          <b>
                            {convertUtcDateToLocalTz(
                              warehouseImportReceiptDetails.date,
                            )}
                          </b>
                        </div>
                        <div>
                          车牌号:
                          <br />
                          Số xe:{' '}
                          <b>{warehouseImportReceiptDetails.vehicleNumber}</b>
                        </div>
                        <div>
                          车型: <br />
                          Loại xe:{' '}
                          <b>{warehouseImportReceiptDetails.vehicleType}</b>
                        </div>
                        <div>
                          公里:
                          <br />
                          Số km: <b>{warehouseImportReceiptDetails.km} km</b>
                        </div>
                      </div>
                    </td>
                    {renderRoute(0)}
                    {renderRoute(7)}
                  </tr>
                  <tr>
                    {renderRoute(1)}
                    {renderRoute(8)}
                  </tr>
                  <tr>
                    {renderRoute(2)}
                    {renderRoute(9)}
                  </tr>
                  <tr>
                    {renderRoute(3)}
                    {renderRoute(10)}
                  </tr>
                  <tr>
                    {renderRoute(4)}
                    {renderRoute(11)}
                  </tr>
                  <tr>
                    {renderRoute(5)}
                    {renderRoute(12)}
                  </tr>
                  <tr>
                    <td></td>
                    {renderRoute(6)}
                    {renderRoute(13)}
                  </tr>
                  <tr>
                    <td>
                      其它路线:
                      <br />
                      Tuyến đường khác:
                    </td>
                    <td
                      style={{
                        fontSize: 24,
                        fontWeight: 'bold',
                      }}
                      colSpan={6}
                    >
                      {warehouseImportReceiptDetails.otherRoute}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      租车费用:
                      <br />
                      Số tiền:{' '}
                      <b>
                        <NumberFormatText
                          value={warehouseImportReceiptDetails.money}
                        />{' '}
                        {warehouseImportReceiptDetails.money && 'VNĐ'}
                      </b>
                    </td>
                    <td colSpan={6}>
                      数字： <br />
                      Số tiền bằng chữ :{' '}
                      <b>
                        {readNumber(warehouseImportReceiptDetails.money)}{' '}
                        {warehouseImportReceiptDetails.money && 'đồng'}
                      </b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      司机签字:
                      <br />
                      Lái xe ký tên:{' '}
                      {warehouseImportReceiptDetails.signature ? (
                        <img
                          width={120}
                          height={80}
                          src={warehouseImportReceiptDetails.signature}
                          alt="Lái xe ký tên"
                        />
                      ) : (
                        <></>
                      )}
                    </td>
                    <td colSpan={3}>
                      用车人签字:
                      <br />
                      Người đi ký tên:
                      {warehouseImportReceiptDetails.clientSignature ? (
                        <img
                          width={120}
                          height={80}
                          src={warehouseImportReceiptDetails.clientSignature}
                          alt="Lái xe ký tên"
                        />
                      ) : (
                        <></>
                      )}
                    </td>
                    <td colSpan={3}>
                      工号：
                      <br />
                      Mã số nhân viên:{' '}
                      {warehouseImportReceiptDetails.createdByUser?.code}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )
        case 'TONGWEI':
        case 'TESXUN':
          return (
            <div
              ref={componentRef}
              style={{
                width: '100%',
                height: '90%',
                fontSize: 24,
              }}
            >
              <table
                style={{
                  width: '95%',
                  height: '50%',
                  margin: '10px auto',
                }}
              >
                <tbody>
                  <tr
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    <td style={{ width: '210px', height: '150px' }}>
                      <img
                        src={background}
                        alt="logo"
                        width="100%"
                        height="100%"
                      />
                    </td>
                    <td>
                      <b>CÔNG TY CỔ PHẦN DỊCH VỤ DU LỊCH FLIX VINA</b>
                      <br />
                      <b>飞越的士服务股份公司 - SĐT: 0981635796</b>
                      <br />
                      <div>PHIẾU XÁC NHẬN DÙNG XE - 用车确认单</div>
                      <div style={{ textAlign: 'right', paddingRight: '20px' }}>
                        No: {id}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} style={{ padding: '8px' }}>
                      Khách hàng (乘客):{' '}
                      <b>{warehouseImportReceiptDetails?.client}</b>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} style={{ padding: '8px' }}>
                      Tuyến đường (路程):{' '}
                      <b>{warehouseImportReceiptDetails?.otherRoute}</b>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} style={{ padding: '8px' }}>
                      Số chiều (程次):{' '}
                      <b>{warehouseImportReceiptDetails?.directionNumber}</b>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} style={{ padding: '8px' }}>
                      Số Km (Km数):{' '}
                      <b>
                        {
                          <NumberFormatText
                            value={warehouseImportReceiptDetails?.km}
                            formatter="quantity"
                          />
                        }{' '}
                        km
                      </b>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} style={{ padding: '8px' }}>
                      Số xe (车牌):{' '}
                      <b> {warehouseImportReceiptDetails?.vehicleNumber}</b>
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan={2}
                      style={{
                        textAlign: 'right',
                        paddingRight: '20px',
                        padding: '8px',
                      }}
                    >
                      <p>
                        Ngày{' '}
                        <b>
                          {dayjs(warehouseImportReceiptDetails?.date).format(
                            'DD',
                          )}
                        </b>
                        日 Tháng{' '}
                        <b>
                          {dayjs(warehouseImportReceiptDetails?.date).format(
                            'MM',
                          )}
                        </b>{' '}
                        月Năm{' '}
                        <b>
                          {dayjs(warehouseImportReceiptDetails?.date).format(
                            'YYYY',
                          )}
                        </b>
                        年
                      </p>
                      {warehouseImportReceiptDetails.clientSignature ? (
                        <img
                          width={120}
                          height={80}
                          src={warehouseImportReceiptDetails.clientSignature}
                          alt="Khách hàng ký tên"
                        />
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )

        case 'HeT v2':
          return (
            <div
              ref={componentRef}
              style={{
                width: '100%',
                height: '90%',
              }}
            >
              <Typography
                style={{
                  width: '95%',
                  margin: '0 auto',
                }}
              >
                Mã phiếu: #{id}
              </Typography>
              <table
                style={{
                  width: '95%',
                  height: '100%',
                  margin: '0 auto',
                }}
              >
                <tbody>
                  <tr
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    <td colSpan={7}>
                      和而泰智能控制（越南）有限公司 <br />
                      <b>CÔNG TY TNHH H&T INTELLIGENT CONTROL （VIET NAM ）</b>
                    </td>
                  </tr>
                  <tr
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    <td colSpan={7}>
                      租车使用一览表 <b>BIỂU GIÁM SÁT SỬ DỤNG XE</b>
                    </td>
                  </tr>
                  <tr
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    <td>明细信息</td>
                    <td>固定路线</td>
                    <td>单程</td>
                    <td>往返</td>
                    <td>固定路线</td>
                    <td>单程</td>
                    <td>往返</td>
                  </tr>
                  <tr>
                    <td rowSpan={6}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-around',
                          height: '100%',
                          fontSize: 24,
                        }}
                      >
                        <div>
                          日期 :<br />
                          Ngày tháng:{' '}
                          <b>
                            {convertUtcDateToLocalTz(
                              warehouseImportReceiptDetails.date,
                            )}
                          </b>
                        </div>
                        <div>
                          车牌号:
                          <br />
                          Số xe:{' '}
                          <b>{warehouseImportReceiptDetails.vehicleNumber}</b>
                        </div>
                        <div>
                          要求车型: <br />
                          Loại xe yêu cầu:{' '}
                          <b>{warehouseImportReceiptDetails.vehicleType}</b>
                        </div>
                        <div>
                          实际车型: <br />
                          Loại xe thực tế:{' '}
                          <b>{warehouseImportReceiptDetails.vehicleTypeReal}</b>
                        </div>
                        <div>
                          公里:
                          <br />
                          Số km: <b>{warehouseImportReceiptDetails.km} km</b>
                        </div>
                      </div>
                    </td>
                    {renderRoute(0)}
                    {renderRoute(7)}
                  </tr>
                  <tr>
                    {renderRoute(1)}
                    {renderRoute(8)}
                  </tr>
                  <tr>
                    {renderRoute(2)}
                    {renderRoute(9)}
                  </tr>
                  <tr>
                    {renderRoute(3)}
                    {renderRoute(10)}
                  </tr>
                  <tr>
                    {renderRoute(4)}
                    {renderRoute(11)}
                  </tr>
                  <tr>
                    {renderRoute(5)}
                    {renderRoute(12)}
                  </tr>
                  <tr>
                    <td></td>
                    {renderRoute(6)}
                    {renderRoute(13)}
                  </tr>
                  <tr>
                    <td>
                      其它路线:
                      <br />
                      Tuyến đường khác:
                    </td>
                    <td
                      style={{
                        fontSize: 24,
                        fontWeight: 'bold',
                      }}
                      colSpan={6}
                    >
                      {warehouseImportReceiptDetails.otherRoute}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      租车费用:
                      <br />
                      Số tiền:{' '}
                      <b>
                        <NumberFormatText
                          value={warehouseImportReceiptDetails.money}
                        />{' '}
                        {warehouseImportReceiptDetails.money && 'VNĐ'}
                      </b>
                    </td>
                    <td colSpan={6}>
                      数字： <br />
                      Số tiền bằng chữ :{' '}
                      <b>
                        {readNumber(warehouseImportReceiptDetails.money)}{' '}
                        {warehouseImportReceiptDetails.money && 'đồng'}
                      </b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      司机签字:
                      <br />
                      Lái xe ký tên:{' '}
                      {warehouseImportReceiptDetails.signature ? (
                        <img
                          width={120}
                          height={80}
                          src={warehouseImportReceiptDetails.signature}
                          alt="Lái xe ký tên"
                        />
                      ) : (
                        <></>
                      )}
                    </td>
                    <td colSpan={3}>
                      用车人签字:
                      <br />
                      Người đi ký tên:
                      {warehouseImportReceiptDetails.clientSignature ? (
                        <img
                          width={120}
                          height={80}
                          src={warehouseImportReceiptDetails.clientSignature}
                          alt="Lái xe ký tên"
                        />
                      ) : (
                        <></>
                      )}
                    </td>
                    <td colSpan={3}>
                      工号：
                      <br />
                      Mã số nhân viên:{' '}
                      {warehouseImportReceiptDetails.createdByUser?.code}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )

        case 'Kstar':
          return (
            <div
              ref={componentRef}
              style={{
                width: '100%',
                height: '90%',
                fontSize: 24,
              }}
            >
              <table
                style={{
                  width: '95%',
                  height: '50%',
                  margin: '10px auto',
                }}
              >
                <tbody>
                  <tr
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    <td style={{ width: '210px', height: '150px' }}>
                      <img
                        src={background}
                        alt="logo"
                        width="100%"
                        height="100%"
                      />
                    </td>
                    <td>
                      <b>CÔNG TY CỔ PHẦN DỊCH VỤ DU LỊCH FLIX VINA</b>
                      <br />
                      <b>飞越的士服务股份公司 - SĐT: 0981635796</b>
                      <br />
                      <div>PHIẾU XÁC NHẬN DÙNG XE - 用车确认单</div>
                      <div style={{ textAlign: 'right', paddingRight: '20px' }}>
                        No: {id}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} style={{ padding: '8px' }}>
                      <div
                        style={{
                          display: 'flex',
                        }}
                      >
                        <div
                          style={{
                            width: '50%',
                          }}
                        >
                          Khách hàng (乘客):{' '}
                          <b>{warehouseImportReceiptDetails?.client}</b>
                        </div>
                        <div
                          style={{
                            width: '50%',
                          }}
                        >
                          Tuyến đường (路程):{' '}
                          <b>{warehouseImportReceiptDetails?.otherRoute}</b>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} style={{ padding: '8px' }}>
                      <div
                        style={{
                          display: 'flex',
                        }}
                      >
                        <div
                          style={{
                            width: '50%',
                          }}
                        >
                          Số xe (车牌):{' '}
                          <b> {warehouseImportReceiptDetails?.vehicleNumber}</b>
                        </div>
                        <div
                          style={{
                            width: '50%',
                          }}
                        >
                          Loại xe(车类):{' '}
                          <b>{warehouseImportReceiptDetails?.vehicleType}</b>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} style={{ padding: '8px' }}>
                      <div
                        style={{
                          display: 'flex',
                        }}
                      >
                        <div
                          style={{
                            width: '50%',
                          }}
                        >
                          Tổng số Km (Km数):{' '}
                          <b>
                            {
                              <NumberFormatText
                                value={warehouseImportReceiptDetails?.km}
                                formatter="quantity"
                              />
                            }{' '}
                            km
                          </b>
                        </div>
                        <div
                          style={{
                            width: '50%',
                          }}
                        >
                          Vượt KM (超过公里):{' '}
                          <b>
                            {
                              <NumberFormatText
                                value={warehouseImportReceiptDetails?.overKm}
                                formatter="quantity"
                              />
                            }{' '}
                            km
                          </b>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} style={{ padding: '8px' }}>
                      <div
                        style={{
                          display: 'flex',
                        }}
                      >
                        <div
                          style={{
                            width: '50%',
                          }}
                        >
                          Giờ đi (出发时间):{' '}
                          <b> {warehouseImportReceiptDetails?.startTime}</b>
                        </div>
                        <div
                          style={{
                            width: '50%',
                          }}
                        >
                          Giờ về (回来时间):{' '}
                          <b> {warehouseImportReceiptDetails?.backTime}</b>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} style={{ padding: '8px' }}>
                      <div
                        style={{
                          display: 'flex',
                        }}
                      >
                        <div
                          style={{
                            width: '50%',
                          }}
                        >
                          Vượt giờ (时间超过):{' '}
                          <b> {warehouseImportReceiptDetails?.overHour}</b>
                        </div>
                        <div
                          style={{
                            width: '50%',
                          }}
                        >
                          Số tiếng chờ (等待时间):{' '}
                          <b> {warehouseImportReceiptDetails?.waitingHour}</b>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} style={{ padding: '8px' }}>
                      Tiền vé, cầu bến bãi (VETC费，票):{' '}
                      <b>
                        <NumberFormatText
                          value={warehouseImportReceiptDetails.fee}
                        />{' '}
                        {warehouseImportReceiptDetails.fee && 'VNĐ'}
                      </b>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} style={{ padding: '8px' }}>
                      Tổng số tiền (总金额):{' '}
                      <b>
                        <NumberFormatText
                          value={warehouseImportReceiptDetails.money}
                        />{' '}
                        {warehouseImportReceiptDetails.money && 'VNĐ'}
                      </b>
                    </td>
                  </tr>

                  <tr>
                    <td
                      colSpan={2}
                      style={{
                        textAlign: 'right',
                        paddingRight: '20px',
                        // padding: '8px',
                      }}
                    >
                      <p>
                        Ngày{' '}
                        <b>
                          {dayjs(warehouseImportReceiptDetails?.date).format(
                            'DD',
                          )}
                        </b>
                        日 Tháng{' '}
                        <b>
                          {dayjs(warehouseImportReceiptDetails?.date).format(
                            'MM',
                          )}
                        </b>{' '}
                        月Năm{' '}
                        <b>
                          {dayjs(warehouseImportReceiptDetails?.date).format(
                            'YYYY',
                          )}
                        </b>
                        年
                      </p>
                      {warehouseImportReceiptDetails.clientSignature ? (
                        <img
                          width={120}
                          height={80}
                          src={warehouseImportReceiptDetails.clientSignature}
                          alt="Khách hàng ký tên"
                        />
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )
        default:
          break
      }
    } else {
      return (
        <div>
          <Typography>
            <b>Mã phiếu:</b> #{id}
          </Typography>
          <Typography color={'red'} textAlign={'center'}>
            Vui lòng quay ngang màn hình để xem chi tiết phiếu
          </Typography>
          {warehouseImportReceiptDetails?.contract?.name === 'HeT' ? (
            <div>
              <div>
                <b>Ngày tháng (日期):</b>{' '}
                {convertUtcDateToLocalTz(warehouseImportReceiptDetails.date)}
              </div>
              <div>
                <b>Số xe(车牌号):</b>{' '}
                {warehouseImportReceiptDetails.vehicleNumber}
              </div>
              <div>
                <b>Loại xe(车型):</b>{' '}
                {warehouseImportReceiptDetails.vehicleType}
              </div>
              <div>
                <b>Số km(公里):</b> {warehouseImportReceiptDetails.km} km
              </div>
              <div>
                <b>Tuyến đường khác(其它路线):</b>{' '}
                {warehouseImportReceiptDetails.otherRoute}
              </div>
              <div>
                <b>Số tiền(租车费用):</b>{' '}
                <NumberFormatText value={warehouseImportReceiptDetails.money} />{' '}
                VNĐ({readNumber(warehouseImportReceiptDetails.money)} đồng)
              </div>
              <div>
                <b>Mã số nhân viên(工号):</b>{' '}
                {warehouseImportReceiptDetails.createdByUser?.code}
              </div>
            </div>
          ) : (
            <div>
              <div>
                <b>Ngày tháng (日期):</b>{' '}
                {convertUtcDateToLocalTz(warehouseImportReceiptDetails.date)}
              </div>
              <div>
                <b>Khách hàng (乘客):</b> {warehouseImportReceiptDetails.client}
              </div>
              <div>
                <b>Số xe(车牌):</b>{' '}
                {warehouseImportReceiptDetails.vehicleNumber}
              </div>
              <div>
                <b>Số km(Km数):</b> {warehouseImportReceiptDetails.km} km
              </div>
              <div>
                <b>Tuyến đường (路程):</b>{' '}
                {warehouseImportReceiptDetails.otherRoute}
              </div>
              <div>
                <b>Số chiều (程次):</b>{' '}
                {warehouseImportReceiptDetails.directionNumber}
              </div>
            </div>
          )}
        </div>
      )
    }
  }

  return isEmpty(userInfo) && loading ? (
    <Page loading={loading}></Page>
  ) : (
    <Page
      breadcrumbs={isAuth() ? breadcrumbs : null}
      title={`Chi tiết phiếu giám sát xe`}
      onBack={isAuth() && backToList}
      renderHeaderRight={renderHeaderRight}
      loading={isLoading}
    >
      {renderOrder()}
      {/* <ActionBar onBack={backToList} /> */}
      <Dialog
        open={modal.isOpenDeleteModal}
        title={t('warehouseImportReceipt.deleteTitlePopup')}
        onCancel={onCloseDeleteModal}
        cancelLabel={t('general:common.no')}
        onSubmit={onSubmitDelete}
        submitLabel={t('general:common.yes')}
        submitProps={{
          color: 'error',
        }}
        noBorderBottom
      >
        {t('warehouseImportReceipt.deleteConfirm')}
      </Dialog>
      <Dialog
        open={modal.isOpenConfirmModal}
        title={t('warehouseImportReceipt.confirmTitlePopup')}
        onCancel={onCloseDeleteModal}
        cancelLabel={t('general:common.no')}
        onSubmit={onSubmitConfirm}
        submitLabel={t('general:common.yes')}
        noBorderBottom
      >
        {t('warehouseImportReceipt.Confirm')}
      </Dialog>
      <Dialog
        open={modal.isOpenSeenModal}
        title="Gửi phiếu giám sát sử dụng cho lái xe"
        onCancel={onCloseDeleteModal}
        cancelLabel={t('general:common.no')}
        onSubmit={onSubmitSeen}
        submitLabel={t('general:common.yes')}
        noBorderBottom
      >
        Bạn có chắc chắn muốn gửi không?
      </Dialog>
      <Dialog
        open={modal.isOpenRetryModal}
        title="Gửi yêu cầu ký tên lại cho lái xe"
        onCancel={onCloseDeleteModal}
        cancelLabel={t('general:common.no')}
        onSubmit={onSubmitRetry}
        submitLabel={t('general:common.yes')}
        noBorderBottom
      >
        Bạn có chắc chắn muốn gửi yêu cầu không?
      </Dialog>
      <Dialog
        open={modal.isOpenCompletedMonitorOrder}
        title="Xác nhận hoàn thành phiếu giám sát xe"
        onCancel={onCloseDeleteModal}
        cancelLabel={t('general:common.no')}
        onSubmit={onSubmitCompletedMonitorOrder}
        submitLabel={t('general:common.yes')}
        noBorderBottom
      >
        Bạn có chắc chắn muốn hoàn thành phiếu giám sát xe không?
      </Dialog>
      <DialogMui
        fullScreen
        open={modal.isOpenDriverSingn}
        title="Lái xe vui lòng ký tên"
        onClose={onCloseDeleteModal}
      >
        <DialogTitle id="alert-dialog-title">
          {'Lái xe vui lòng ký tên'}
          {onCloseDeleteModal ? (
            <IconButton
              aria-label="close"
              onClick={onCloseDeleteModal}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>

        <Sign
          canvasRef={canvasRef}
          saveData={saveData}
          setSaveData={setSaveData}
        />

        <DialogActions sx={{ ml: 2 }}>
          <Button onClick={() => canvasRef.current.undo()} variant="outlined">
            {' '}
            <Icon name={'back'} />
          </Button>
          <Button
            onClick={() => {
              canvasRef.current.clear()
              setSaveData('')
            }}
            variant="outlined"
          >
            {' '}
            <Icon name="delete" />
          </Button>
          <Button
            autoFocus
            onClick={() => handleClickSignDriver()}
            variant="outlined"
          >
            {' '}
            <Icon name="tick" />
          </Button>
        </DialogActions>
      </DialogMui>
      <DialogMui
        open={modal.isOpenCustomerSingn}
        title="Khách hàng vui lòng ký tên"
        onClose={onCloseDeleteModal}
        fullScreen
      >
        <DialogTitle id="alert-dialog-title">
          {'Khách hàng vui lòng ký tên'}
          {onCloseDeleteModal ? (
            <IconButton
              aria-label="close"
              onClick={onCloseDeleteModal}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>

        <Sign
          canvasRef={canvasRef}
          saveData={saveData}
          setSaveData={setSaveData}
        />

        <DialogActions sx={{ ml: 2 }}>
          <Button onClick={() => canvasRef.current.undo()} variant="outlined">
            {' '}
            <Icon name={'back'} />
          </Button>
          <Button
            onClick={() => {
              canvasRef.current.clear()
              setSaveData('')
            }}
            variant="outlined"
          >
            {' '}
            <Icon name="delete" />
          </Button>
          <Button
            autoFocus
            onClick={() => handleClickSignCustomer()}
            variant="outlined"
          >
            {' '}
            <Icon name="tick" />
          </Button>
        </DialogActions>
      </DialogMui>
    </Page>
  )
}

export default WarehouseImportReceiptDetail
