import { call, put, takeLatest } from 'redux-saga/effects'

import { NOTIFICATION_TYPE } from '~/common/constants'
import {
  confirmGroupMonitorOrderByIdFailed,
  confirmGroupMonitorOrderByIdSuccess,
  CONFIRM_GROUP_MONITOR_ORDER_START,
} from '~/modules/wmsx/redux/actions/group-monitor-order'
import { api } from '~/services/api'
import addNotification from '~/utils/toast'

const confirmGroupMonitorOrderApi = (params) => {
  const uri = `/monitor-sheet-groups/${params}/confirm`
  return api.put(uri)
}

function* doConfirmGroupMonitorOrder(action) {
  try {
    const response = yield call(confirmGroupMonitorOrderApi, action?.payload)

    if (response?.statusCode === 200) {
      yield put(confirmGroupMonitorOrderByIdSuccess(response.payload))

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess()
      }

      addNotification(response?.message, NOTIFICATION_TYPE.SUCCESS)
    } else {
      addNotification(
        response?.message || response?.statusText,
        NOTIFICATION_TYPE.ERROR,
      )

      throw new Error(response?.message)
    }
  } catch (error) {
    yield put(confirmGroupMonitorOrderByIdFailed())
    // Call callback action if provided
    if (action.onError) {
      yield action.onError()
    }
  }
}

export default function* watchConfirmGroupMonitorOrder() {
  yield takeLatest(
    CONFIRM_GROUP_MONITOR_ORDER_START,
    doConfirmGroupMonitorOrder,
  )
}
