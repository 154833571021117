import { call, put, takeLatest } from 'redux-saga/effects'

import { NOTIFICATION_TYPE } from '~/common/constants'
import {
  createGroupMonitorOrderFailed,
  createGroupMonitorOrderSuccess,
  CREATE_GROUP_MONITOR_ORDER_START,
} from '~/modules/wmsx/redux/actions/group-monitor-order'
import { api } from '~/services/api'
import addNotification from '~/utils/toast'

const createGroupMonitorOrderApi = (params) => {
  const uri = `/monitor-sheet-groups`
  return api.post(uri, params)
}

function* doCreateGroupMonitorOrder(action) {
  try {
    const response = yield call(createGroupMonitorOrderApi, action?.payload)

    if (response?.statusCode === 200) {
      yield put(createGroupMonitorOrderSuccess(response.data))

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess()
      }

      addNotification(
        response?.message || response?.payload?.message,
        NOTIFICATION_TYPE.SUCCESS,
      )
    } else {
      addNotification(
        response?.message || response?.payload?.message,
        NOTIFICATION_TYPE.ERROR,
      )
      throw new Error(response?.message || response?.payload?.message)
    }
  } catch (error) {
    yield put(createGroupMonitorOrderFailed())
    // Call callback action if provided
    if (action.onError) {
      yield action.onError()
    }
  }
}

export default function* watchCreateGroupMonitorOrder() {
  yield takeLatest(CREATE_GROUP_MONITOR_ORDER_START, doCreateGroupMonitorOrder)
}
