import React, { useEffect, useMemo } from 'react'

import { Box, Grid, Typography } from '@mui/material'
import { Formik, Form, FieldArray } from 'formik'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'

import {
  ASYNC_SEARCH_LIMIT,
  MODAL_MODE,
  TEXTFIELD_REQUIRED_LENGTH,
} from '~/common/constants'
import ActionBar from '~/components/ActionBar'
import { Field } from '~/components/Formik'
import LV from '~/components/LabelValue'
import Page from '~/components/Page'
import Status from '~/components/Status'
import { WAREHOUSE_IMPORT_RECEIPT_OPTIONS } from '~/modules/wmsx/constants'
import useWarehouseImportReceipt from '~/modules/wmsx/redux/hooks/useWarehouseImportReceipt'
import { searchContactApi } from '~/modules/wmsx/redux/sagas/warehouse-import-receipt/search'
import { ROUTE } from '~/modules/wmsx/routes/config'

import ItemSettingTable from './item-setting-table'

function WarehouseImportReceiptForm() {
  const { t } = useTranslation(['wmsx'])
  const history = useHistory()
  const { id } = useParams()
  const routeMatch = useRouteMatch()
  const {
    data: { warehouseImportReceiptDetails, isLoading, routeList },
    actions,
  } = useWarehouseImportReceipt()
  const MODE_MAP = {
    [ROUTE.WAREHOUSE_IMPORT_RECEIPT.CREATE.PATH]: MODAL_MODE.CREATE,
    [ROUTE.WAREHOUSE_IMPORT_RECEIPT.EDIT.PATH]: MODAL_MODE.UPDATE,
  }
  const mode = MODE_MAP[routeMatch.path]
  const isUpdate = mode === MODAL_MODE.UPDATE
  useEffect(() => {
    if (!isUpdate) {
      actions.searchRoute({
        isGetAll: 1,
        contractId: 3,
      })
    }
  }, [])
  const initialValues = useMemo(
    () => ({
      contractId: warehouseImportReceiptDetails?.contract || null,
      receiptDate: warehouseImportReceiptDetails?.date || '',
      vehicleNumber: warehouseImportReceiptDetails?.vehicleNumber || '',
      vehicleType: warehouseImportReceiptDetails?.vehicleType || '',
      vehicleTypeReal: warehouseImportReceiptDetails?.vehicleTypeReal || '',
      km: warehouseImportReceiptDetails?.km || '',
      money: warehouseImportReceiptDetails?.money || '',
      otherRoute: warehouseImportReceiptDetails?.otherRoute || '',
      client: warehouseImportReceiptDetails?.client || '',
      directionNumber: warehouseImportReceiptDetails?.directionNumber || '',
      route: warehouseImportReceiptDetails?.route || '',
      waitingHour: warehouseImportReceiptDetails?.waitingHour || '',
      startTime: warehouseImportReceiptDetails?.startTime || '',
      backTime: warehouseImportReceiptDetails?.backTime || '',
      items:
        warehouseImportReceiptDetails?.items?.map((item) => ({
          id: item?.id,
          route: {
            id: item?.id,
            nameCN: item?.nameCN,
            nameVI: item?.nameVI,
          },
          oneWay: item?.isOneWay,
          roundTrip: item?.isRoundTrip,
        })) ||
        routeList?.map((item) => ({
          id: item?.id,
          route: {
            id: item?.id,
            nameCN: item?.nameCN,
            nameVI: item?.nameVI,
          },
          oneWay: false,
          roundTrip: false,
        })),
    }),
    [warehouseImportReceiptDetails, routeList],
  )

  const getBreadcrumb = () => {
    const breadcrumbs = [
      {
        route: ROUTE.WAREHOUSE_IMPORT_RECEIPT.LIST.PATH,
        title: ROUTE.WAREHOUSE_IMPORT_RECEIPT.LIST.TITLE,
      },
    ]
    switch (mode) {
      case MODAL_MODE.CREATE:
        breadcrumbs.push({
          route: ROUTE.WAREHOUSE_IMPORT_RECEIPT.CREATE.PATH,
          title: ROUTE.WAREHOUSE_IMPORT_RECEIPT.CREATE.TITLE,
        })
        break
      case MODAL_MODE.UPDATE:
        breadcrumbs.push({
          route: ROUTE.WAREHOUSE_IMPORT_RECEIPT.EDIT.PATH,
          title: ROUTE.WAREHOUSE_IMPORT_RECEIPT.EDIT.TITLE,
        })
        break
      default:
        break
    }
    return breadcrumbs
  }

  useEffect(async () => {
    if (isUpdate) {
      actions.getWarehouseImportReceiptDetailsById(id)
    }
  }, [id])
  useEffect(() => {
    return () => {
      actions.resetWarehouseImportReceiptState()
    }
  }, [])
  const getTitle = () => {
    switch (mode) {
      case MODAL_MODE.CREATE:
        return ROUTE.WAREHOUSE_IMPORT_RECEIPT.CREATE.TITLE
      case MODAL_MODE.UPDATE:
        return ROUTE.WAREHOUSE_IMPORT_RECEIPT.EDIT.TITLE
      case MODAL_MODE.UPDATE_HEADER:
        return ROUTE.WAREHOUSE_IMPORT_RECEIPT.EDIT.TITLE
      default:
        break
    }
  }

  const backToList = () => {
    history.push(ROUTE.WAREHOUSE_IMPORT_RECEIPT.LIST.PATH)
  }

  const onSubmit = (values) => {
    const params = {
      contractId: values?.contractId?.id,
      contractName: values?.contractId?.name || '',
      date: values?.receiptDate || null,
      vehicleNumber: values?.vehicleNumber || null,
      vehicleType: values?.vehicleType || null,
      vehicleTypeReal: values?.vehicleTypeReal || null,
      km: +values?.km || null,
      // startKm: +values?.startKm || null,
      // endKm: +values?.endKm || null,
      overKm: +values?.overKm || null,
      money: +values?.money || null,
      otherRoute: values?.otherRoute || null,
      directionNumber: values?.directionNumber || null,
      client: values?.client || null,
      waitingHour: values?.waitingHour || null,
      overHour: values?.overHour || null,
      startTime: values?.startTime || null,
      backTime: values?.backTime || null,
      fee: values?.fee || null,
      totalMoney: values?.totalMoney || null,
      items: ['HeT', 'HeT v2'].includes(values?.contractId?.name)
        ? values?.items?.map((item) => ({
            routeId: item?.route?.id,
            isOneWay: item?.oneWay,
            isRoundTrip: item?.roundTrip,
          }))
        : null,
    }
    if (mode === MODAL_MODE.CREATE) {
      actions.createWarehouseImportReceipt(params, backToList)
    } else {
      actions.updateWarehouseImportReceipt({ ...params, id: id }, backToList)
    }
  }
  const renderActionBar = (handleReset) => {
    switch (mode) {
      case MODAL_MODE.CREATE:
        return (
          <ActionBar
            onBack={backToList}
            onCancel={handleReset}
            mode={MODAL_MODE.UPDATE}
          />
        )
      case MODAL_MODE.UPDATE:
        return (
          <ActionBar
            onBack={backToList}
            onCancel={handleReset}
            mode={MODAL_MODE.UPDATE}
          />
        )
      default:
        break
    }
  }

  return (
    <Page
      breadcrumbs={getBreadcrumb()}
      title={getTitle()}
      onBack={backToList}
      loading={isLoading}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ handleReset, values }) => {
          return (
            <Form>
              <Grid container justifyContent="center">
                <Grid item xl={11} xs={12}>
                  <Grid
                    container
                    columnSpacing={{ xl: 8, xs: 4 }}
                    rowSpacing={4 / 3}
                  >
                    {isUpdate && (
                      <Grid item xs={12}>
                        <LV
                          label={
                            <Typography>
                              {t('constructionManagement.status')}
                            </Typography>
                          }
                          value={
                            <Status
                              options={WAREHOUSE_IMPORT_RECEIPT_OPTIONS}
                              value={warehouseImportReceiptDetails?.status}
                            />
                          }
                        />
                      </Grid>
                    )}
                    <Grid item lg={6} xs={12}>
                      <Field.Autocomplete
                        name="contractId"
                        label="Hợp đồng"
                        placeholder="Hợp đồng"
                        asyncRequest={(s) =>
                          searchContactApi({
                            keyword: s,
                            limit: ASYNC_SEARCH_LIMIT,
                          })
                        }
                        asyncRequestHelper={(res) => res?.data?.items}
                        getOptionLabel={(opt) => opt?.name}
                        isOptionEqualToValue={(opt, val) => opt?.id === val?.id}
                        required
                      />
                    </Grid>
                    {!isEmpty(values?.contractId) &&
                      values?.contractId?.name === 'HeT' && (
                        <>
                          <Grid item lg={6} xs={12}>
                            <Field.DatePicker
                              name="receiptDate"
                              label="Ngày tháng"
                              placeholder="Ngày tháng"
                              validate={(val) => {
                                if (!val) {
                                  return t('general:form.required')
                                }
                              }}
                              required
                            />
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <Field.TextField
                              name="vehicleNumber"
                              label="Số xe"
                              placeholder="Số xe"
                              inputProps={{
                                maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                              }}
                              validate={(val) => {
                                if (!val) {
                                  return t('general:form.required')
                                }
                              }}
                              required
                            />
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <Field.TextField
                              name="vehicleType"
                              label="Loại xe"
                              placeholder="Loại xe"
                              inputProps={{
                                maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                              }}
                              validate={(val) => {
                                if (!val) {
                                  return t('general:form.required')
                                }
                              }}
                              required
                            />
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <Field.TextField
                              name="km"
                              label="Số km"
                              placeholder="Số km"
                              formatter="quantity"
                              inputProps={{
                                maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                              }}
                              validate={(val) => {
                                if (!val) {
                                  return t('general:form.required')
                                }
                              }}
                              required
                            />
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <Field.TextField
                              name="money"
                              label="Số tiền"
                              placeholder="Số tiền"
                              formatter="price"
                              inputProps={{
                                maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                              }}
                            />
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <Field.TextField
                              name="otherRoute"
                              label="Tuyến đường khác"
                              placeholder="Tuyến đường khác"
                              inputProps={{
                                maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                              }}
                            />
                          </Grid>
                        </>
                      )}
                    {!isEmpty(values?.contractId) &&
                      ['TONGWEI', 'TESXUN'].includes(
                        values?.contractId?.name,
                      ) && (
                        <>
                          <Grid item lg={6} xs={12}>
                            <Field.DatePicker
                              name="receiptDate"
                              label="Ngày tháng"
                              placeholder="Ngày tháng"
                              validate={(val) => {
                                if (!val) {
                                  return t('general:form.required')
                                }
                              }}
                              required
                            />
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <Field.TextField
                              name="client"
                              label="Khách hàng"
                              placeholder="Khách hàng"
                              inputProps={{
                                maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                              }}
                              validate={(val) => {
                                if (!val) {
                                  return t('general:form.required')
                                }
                              }}
                              required
                            />
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <Field.TextField
                              name="otherRoute"
                              label="Tuyến đường"
                              placeholder="Tuyến đường"
                              inputProps={{
                                maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                              }}
                              validate={(val) => {
                                if (!val) {
                                  return t('general:form.required')
                                }
                              }}
                              required
                            />
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <Field.TextField
                              name="directionNumber"
                              label="Số chiều"
                              placeholder="Số chiều"
                              inputProps={{
                                maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                              }}
                              validate={(val) => {
                                if (!val) {
                                  return t('general:form.required')
                                }
                              }}
                              required
                            />
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <Field.TextField
                              name="km"
                              label="Số Km"
                              placeholder="Số Km"
                              inputProps={{
                                maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                              }}
                              validate={(val) => {
                                if (!val) {
                                  return t('general:form.required')
                                }
                              }}
                              required
                            />
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <Field.TextField
                              name="vehicleNumber"
                              label="Số xe"
                              placeholder="Số xe"
                              inputProps={{
                                maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                              }}
                              validate={(val) => {
                                if (!val) {
                                  return t('general:form.required')
                                }
                              }}
                              required
                            />
                          </Grid>
                        </>
                      )}

                    {!isEmpty(values?.contractId) &&
                      ['Kstar'].includes(values?.contractId?.name) && (
                        <>
                          <Grid item lg={6} xs={12}>
                            <Field.DatePicker
                              name="receiptDate"
                              label="Ngày tháng"
                              placeholder="Ngày tháng"
                              validate={(val) => {
                                if (!val) {
                                  return t('general:form.required')
                                }
                              }}
                              required
                            />
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <Field.TextField
                              name="client"
                              label="Khách hàng"
                              placeholder="Khách hàng"
                              inputProps={{
                                maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                              }}
                              validate={(val) => {
                                if (!val) {
                                  return t('general:form.required')
                                }
                              }}
                              required
                            />
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <Field.TextField
                              name="vehicleNumber"
                              label="Số xe"
                              placeholder="Số xe"
                              inputProps={{
                                maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                              }}
                              validate={(val) => {
                                if (!val) {
                                  return t('general:form.required')
                                }
                              }}
                              required
                            />
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <Field.TextField
                              name="vehicleType"
                              label="Loại xe"
                              placeholder="Loại xe"
                              inputProps={{
                                maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                              }}
                              // validate={(val) => {
                              //   if (!val) {
                              //     return t('general:form.required')
                              //   }
                              // }}
                              // required
                            />
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <Field.TextField
                              name="otherRoute"
                              label="Tuyến đường"
                              placeholder="Tuyến đường"
                              inputProps={{
                                maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                              }}
                              validate={(val) => {
                                if (!val) {
                                  return t('general:form.required')
                                }
                              }}
                              required
                            />
                          </Grid>
                          {/* <Grid item lg={6} xs={12}>
                            <Field.TextField
                              name="directionNumber"
                              label="Số chiều"
                              placeholder="Số chiều"
                              inputProps={{
                                maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                              }}
                              validate={(val) => {
                                if (!val) {
                                  return t('general:form.required')
                                }
                              }}
                              required
                            />
                          </Grid> */}
                          {/* <Grid item lg={6} xs={12}>
                            <Field.TextField
                              name="startKm"
                              label="Km bắt đầu"
                              placeholder="Km bắt đầu"
                              type="number"
                              inputProps={{
                                maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                              }}
                              // validate={(val) => {
                              //   if (!val) {
                              //     return t('general:form.required')
                              //   }
                              // }}
                            />
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <Field.TextField
                              name="endKm"
                              label="Km kết thúc"
                              placeholder="Km kết thúc"
                              type="number"
                              inputProps={{
                                maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                              }}
                              // validate={(val) => {
                              //   if (!val) {
                              //     return t('general:form.required')
                              //   }
                              // }}
                            />
                          </Grid> */}
                          <Grid item lg={6} xs={12}>
                            <Field.TextField
                              name="km"
                              label="Số Km"
                              placeholder="Số Km"
                              type="number"
                              inputProps={{
                                maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                              }}
                              // validate={(val) => {
                              //   if (!val) {
                              //     return t('general:form.required')
                              //   }
                              // }}
                            />
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <Field.TextField
                              name="overKm"
                              label="Vượt Km"
                              placeholder="Vượt Km"
                              type="number"
                              inputProps={{
                                maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                              }}
                              // validate={(val) => {
                              //   if (!val) {
                              //     return t('general:form.required')
                              //   }
                              // }}
                            />
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <Field.TimePicker
                              // views={['hours', 'minutes']}
                              name="startTime"
                              label="Giờ đi"
                              placeholder="Giờ đi"
                              validate={(val) => {
                                if (val === '--:--') {
                                  return t('general:form.required')
                                }
                              }}
                              required
                            />
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <Field.TimePicker
                              // views={['hours', 'minutes']}
                              name="backTime"
                              label="Giờ về"
                              placeholder="Giờ về"
                              // validate={(val) => {
                              //   if (!val) {
                              //     return t('general:form.required')
                              //   }
                              // }}
                              // required
                            />
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <Field.TextField
                              name="waitingHour"
                              label="Số tiếng chờ"
                              placeholder="Số tiếng chờ"
                              inputProps={{
                                maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                              }}
                              type="number"
                              // validate={(val) => {
                              //   if (!val) {
                              //     return t('general:form.required')
                              //   }
                              // }}
                            />
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <Field.TextField
                              name="overHour"
                              label="Vượt giờ"
                              placeholder="Vượt giờ"
                              type="number"
                              inputProps={{
                                maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                              }}
                              // validate={(val) => {
                              //   if (!val) {
                              //     return t('general:form.required')
                              //   }
                              // }}
                            />
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <Field.TextField
                              name="fee"
                              label="Tiền vé, cầu bến bãi"
                              placeholder="Tiền vé, cầu bến bãi"
                              inputProps={{
                                maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                              }}
                              formatter="price"
                              // validate={(val) => {
                              //   if (!val) {
                              //     return t('general:form.required')
                              //   }
                              // }}
                            />
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <Field.TextField
                              name="money"
                              label="Tổng số tiền"
                              placeholder="Tổng số tiền"
                              formatter="price"
                              inputProps={{
                                maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                              }}
                            />
                          </Grid>
                        </>
                      )}

                    {!isEmpty(values?.contractId) &&
                      values?.contractId?.name === 'HeT v2' && (
                        <>
                          <Grid item lg={6} xs={12}>
                            <Field.DatePicker
                              name="receiptDate"
                              label="Ngày tháng"
                              placeholder="Ngày tháng"
                              validate={(val) => {
                                if (!val) {
                                  return t('general:form.required')
                                }
                              }}
                              required
                            />
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <Field.TextField
                              name="vehicleNumber"
                              label="Số xe"
                              placeholder="Số xe"
                              inputProps={{
                                maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                              }}
                              validate={(val) => {
                                if (!val) {
                                  return t('general:form.required')
                                }
                              }}
                              required
                            />
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <Field.TextField
                              name="vehicleType"
                              label="Loại xe yêu cầu"
                              placeholder="Loại xe yêu cầu"
                              inputProps={{
                                maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                              }}
                              validate={(val) => {
                                if (!val) {
                                  return t('general:form.required')
                                }
                              }}
                              required
                            />
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <Field.TextField
                              name="vehicleTypeReal"
                              label="Loại xe thực tế"
                              placeholder="Loại xe thực tế"
                              inputProps={{
                                maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                              }}
                              validate={(val) => {
                                if (!val) {
                                  return t('general:form.required')
                                }
                              }}
                              required
                            />
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <Field.TextField
                              name="km"
                              label="Số km"
                              placeholder="Số km"
                              formatter="quantity"
                              inputProps={{
                                maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                              }}
                              validate={(val) => {
                                if (!val) {
                                  return t('general:form.required')
                                }
                              }}
                              required
                            />
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <Field.TextField
                              name="money"
                              label="Số tiền"
                              placeholder="Số tiền"
                              formatter="price"
                              inputProps={{
                                maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                              }}
                            />
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <Field.TextField
                              name="otherRoute"
                              label="Tuyến đường khác"
                              placeholder="Tuyến đường khác"
                              inputProps={{
                                maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                              }}
                            />
                          </Grid>
                        </>
                      )}
                  </Grid>
                  {!isEmpty(values?.contractId) &&
                    (values?.contractId?.name === 'HeT' ||
                      values?.contractId?.name === 'HeT v2') && (
                      <Box sx={{ mt: 3 }}>
                        <FieldArray
                          name="items"
                          render={() => (
                            <ItemSettingTable items={values?.items || []} />
                          )}
                        />
                      </Box>
                    )}
                </Grid>
              </Grid>
              {renderActionBar(handleReset)}
            </Form>
          )
        }}
      </Formik>
    </Page>
  )
}

export default WarehouseImportReceiptForm
