import React from 'react'

import classNames from 'classnames'
import CanvasDraw from 'react-canvas-draw'

const defaultProps = {
  loadTimeOffset: 5,
  lazyRadius: 0,
  brushRadius: 2,
  catenaryColor: '#0a0302',
  gridColor: 'rgba(150,150,150,0.17)',
  hideGrid: true,
  canvasWidth: 600,
  canvasHeight: 300,
  disabled: false,
  imgSrc: '',
  saveData: '',
  immediateLoading: false,
  hideInterface: false,
}

export default function Sign({ canvasRef, setSaveData }) {
  const getImg = () => canvasRef.current.canvasContainer.children[1].toDataURL()
  const handleCanvasChange = () => {
    const saveData = getImg()
    setSaveData(saveData)
  }

  const props = {
    ...defaultProps,
    className: classNames('canvas'),
    onChange: handleCanvasChange,
    ref: canvasRef,
    brushColor: '#000000',
    catenaryColor: '#fff',
  }

  return (
    <div
      style={{
        height: 300,
        width: 600,
        border: '1px',
        borderStyle: 'solid',
        margin: '0 auto',
      }}
    >
      <CanvasDraw {...props} />
    </div>
  )
}
