import React from 'react'

import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ASYNC_SEARCH_LIMIT } from '~/common/constants'
import { Field } from '~/components/Formik'
import { searchUsersApi } from '~/modules/mesx/redux/sagas/user-management/search-users'
import { WAREHOUSE_IMPORT_RECEIPT_OPTIONS } from '~/modules/wmsx/constants'
import { searchContactApi } from '~/modules/wmsx/redux/sagas/warehouse-import-receipt/search'

const FilterForm = () => {
  const { t } = useTranslation('wmsx')

  return (
    <Grid container rowSpacing={4 / 3}>
      <Grid item xs={12}>
        <Field.Autocomplete
          name="createdByUser"
          label="Người tạo"
          placeholder="Người tạo"
          asyncRequest={(s) =>
            searchUsersApi({
              keyword: s,
              limit: ASYNC_SEARCH_LIMIT,
              isActive: 1,
            })
          }
          asyncRequestHelper={(res) => res?.data?.items}
          getOptionLabel={(opt) => opt?.username}
          getOptionSubLabel={(opt) => opt?.fullName}
        />
      </Grid>
      <Grid item xs={12}>
        <Field.Autocomplete
          name="contractId"
          label="Hợp đồng"
          placeholder="Hợp đồng"
          asyncRequest={(s) =>
            searchContactApi({
              keyword: s,
              limit: ASYNC_SEARCH_LIMIT,
            })
          }
          asyncRequestHelper={(res) => res?.data?.items}
          getOptionLabel={(opt) => opt?.name}
        />
      </Grid>

      <Grid item xs={12}>
        <Field.Autocomplete
          name="status"
          label="Trạng thái"
          placeholder="Trạng thái"
          options={WAREHOUSE_IMPORT_RECEIPT_OPTIONS}
          getOptionLabel={(opt) => t(opt?.text)}
          getOptionValue={(opt) => opt?.id?.toString()}
        />
      </Grid>
      <Grid item xs={12}>
        <Field.DateRangePicker name="date" label={'Ngày tháng'} />
      </Grid>
    </Grid>
  )
}

export default FilterForm
